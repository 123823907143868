import { Link } from 'react-router-dom'
import './index.scss'

import { SingleQuestionInterface } from '../../utils/types'
import VoteTopicEdit from '../../component/VoteTopicAndEdit/VoteTopicEdit'

export const voteQuestionSingle: SingleQuestionInterface = {
  questionTitle: ' asdfsadf adsfasdf',
  answerKey: 'A',
  answerOptions: {
    A: 'A asdfasdf',
    B: 'B asdasdfas',
    C: 'C asdfasdfas',
    D: 'D asdfasdfasd',
  },
}

export default function EditQuestion() {
  return (
    <>
      <div>
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Edit Vote Topic</li>
            </ol>
          </div>
          <div className="row">
            <div className="row">
              <div className="col-md-12"></div>
            </div>
            <div className="col-md-6 ms-auto me-auto pt-5 common-box-shadow ">
              <h1 style={{ textAlign: 'center' }}>Edit Vote Topic</h1>
              <VoteTopicEdit />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
