import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import '../../App'
import { ResetPasswordRequestFormValues } from '../../utils/types'
import { postResetPassword } from '../../api'
import { useForm } from 'react-hook-form'
import { FormErrorMessage } from '../../component'

const ResetPasswordRequestPage: React.FC = () => {
  const imageUrl = 'images/4858794.jpg' // Path to your image

  const divStyle = {
    backgroundImage: `url(${imageUrl})`,
    // Adjust width and height as needed
    // Add other styles if needed
  }
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const [msg, setMsg] = useState('')
  const [auth, setAuth] = useState<string>('')
  const [isSubmited, setIsSubmited] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [serverError, setServerError] = useState('')

  const initialValues: ResetPasswordRequestFormValues = {
    email: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string().email().required('Email required'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ResetPasswordRequestFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (values: ResetPasswordRequestFormValues) => {
    setMsg('')
    setServerError('')
    setIsLoading(true)

    const email = values.email
    setIsLoading(true)
    postResetPassword(email)
      .then((res) => {
        setIsSubmited(true)
        setIsLoading(false)
        setMsg(res.data.message)
        setMsg('')
      })
      .catch((err: any) => {
        console.log(err)
        setIsLoading(false)
        setServerError(err.response?.data.message)
      })
  }

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen)
  }
  const email = watch('email')
  return (
    <>
      <div id="page">
        <nav
          className="dash-nav navbar navbar-expand-lg navbar-dark  fixed-top align-items-center justify-content-between"
          id="mainNav"
        >
          <div className="d-flex align-items-center align-items-lg-end">
            <div className="dropdown me-3">
              {auth ? (
                <button
                  className="p-0 btn dropdown-toggle quan-toggle shadow-none"
                  type="button"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded={isDropdownOpen}
                  onClick={handleDropdownToggle}
                >
                  <i className="ti-menu"></i>
                </button>
              ) : (
                ''
              )}
              <ul
                className={`dropdown-menu quan-menu ${isDropdownOpen ? 'show' : ''}`}
                aria-labelledby="dropdownMenuButton2"
              >
                <li>
                  <Link to="/" className="dropdown-item">
                    Dashboard
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Quantum Mandate
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Buy Units
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    About Us
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div className="dropdown p-0 d-block d-lg-none ms-sm-5 ms-4">
              <button
                className="p-0 btn dropdown-toggle quan-toggle"
                type="button"
                id="dropdownMenuButton2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti-user mobile-user"></i>
              </button>
              <ul
                className="dropdown-menu quan-menu"
                aria-labelledby="dropdownMenuButton2"
              >
                <li>
                  <a className="dropdown-item" href="log-in.html">
                    LOG-IN
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="register.html">
                    REGISTER
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    LOG-OUT
                  </a>
                </li>
              </ul>
            </div>
            {auth ? (
              <ul className="navbar-nav align-items-end d-none d-lg-flex">
                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link ms-3"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    LOG-OUT
                  </a>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav align-items-end d-none d-lg-flex ">
                <li className="nav-item ">
                  <Link to="/login" className="nav-link ms-3">
                    LOG-IN
                  </Link>
                </li>
                {/* <li className="nav-item">
                    <Link to='/register' className="nav-link ms-3">
                      REGISTER </Link>
                  </li> */}
              </ul>
            )}
          </div>
          <a
            className="navbar-brand ms-auto p-0 d-none d-lg-block"
            href="index.html"
          >
            <img src="images/quantum-logo.png" alt="" height="36" />
          </a>
          <div className="d-lg-none d-block dash-mobile-logo">
            <a href="index.html">
              <img src="images/quantum-logo.png" alt="" />
            </a>
          </div>
        </nav>

        <main className="">
          <section className="sec-img" style={divStyle}>
            <div
              className="container margin_60"
              style={{ paddingTop: '150px' }}
            >
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-8">
                  <div className="login-from-q">
                    {isSubmited ? (
                      <div className="banner-text">
                        A reset link has been sent to your email {email} .
                        <b>You have 12 hours to reset your password. </b>
                        It can take up to 15 min to receive our email.
                      </div>
                    ) : (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <p>
                          We will send you a reset link on the following email:
                        </p>
                        <div className="form-group mb-3">
                          <label htmlFor="email">Email</label>
                          <input
                            {...register('email')}
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                          />
                          {errors.email && (
                            <FormErrorMessage>
                              {errors.email.message}
                            </FormErrorMessage>
                          )}
                        </div>
                        {serverError && (
                          <FormErrorMessage>{serverError}</FormErrorMessage>
                        )}

                        <h4 style={{ color: 'red !important' }}>{msg}</h4>

                        <button
                          type="submit"
                          className="btn_1 rounded full-width color"
                        >
                          Reset Password
                        </button>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  )
}

export default ResetPasswordRequestPage
