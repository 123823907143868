import { forwardRef, useCallback, useMemo, useRef, useState } from "react";
import { addImage } from "../../api";
import LoadingBlock from "./smallComponents";
import ReactQuill from "react-quill-new";
import "react-quill/dist/quill.snow.css";
import { FormGroup, Input } from "reactstrap";
import { EmailTemplateFullData, EmailTemplateFullDataType } from "../../utils/email-misc/frontend-email-template-specific-data";



export const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'color',
  'clean',
];

const CustomToolbar = ({ emailTemplateName, onTagSelect, UiTags, SpecificTags }: {emailTemplateName : EmailTemplateFullDataType, UiTags: string[], SpecificTags: string[], onTagSelect: (tag: string) => void }) => {
  return (
    <div id="toolbar" className="mb-3">
      {/* <UncontrolledDropdown >
        <DropdownToggle  color="primary">
          Specific Email Template Tags
        </DropdownToggle>
        
        
        <DropdownMenu>
          <DropdownItem header>
            Header
          </DropdownItem>
          {SpecificTags.map((tag) => (
            <DropdownItem key={tag} value={tag} onClick={() => onTagSelect(tag)}>
              {tag}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown  className="ms-2">
        <DropdownToggle  color="primary">
          UI Tags
        </DropdownToggle>
        <DropdownToggle
          
          color="primary"
        />
        <DropdownMenu>
          <DropdownItem header>
            Header
          </DropdownItem>

          {UiTags.map((tag) => (
            <DropdownItem key={tag} value={tag} onClick={() => onTagSelect(tag)}>
              {tag}
            </DropdownItem>
          ))}


        </DropdownMenu>
      </UncontrolledDropdown> */}
      <FormGroup className="d-inline-block me-5">
        {/* <Label for="exampleSelect">
          Specific Email Template Tags
        </Label> */}
        <Input
          onChange={(e) => onTagSelect(e.target.value)}
          id="SpecificTags"
          name="select"
          type="select"
          value=""
        >
          <option value={""}>Available Tags</option>
          {SpecificTags.map((tag) => (
            <option key={tag} value={tag}>
              {tag}
            </option>
          ))}



        </Input>
      </FormGroup>
      <FormGroup className="d-inline-block mr-2">
        {/* <Label for="exampleSelect">
          UI Tags
        </Label> */}
        <Input
          onChange={(e) => onTagSelect(e.target.value)}
          id="UiTags"
          name="select"
          type="select"
          value=""
        >
          <option value={""}>Select UI Tags</option>
          {UiTags.map((tag) => (
            <option key={tag} value={tag}>
              {tag}
            </option>
          ))}
        </Input>
      </FormGroup>
      {/* <select className="ql-dynamicTags" onChange={(e) => onTagSelect(e.target.value)}>
        <option value="">@dynamicTags</option>
        <option value="@name">@name</option>
        <option value="@email">@email</option>
        <option value="@date">@date</option>
      </select>
      <select className="ql-dynamicTags" onChange={(e) => onTagSelect(e.target.value)}>
        <option value="">@dynamicTags</option>
        <option value="@name">@name</option>
        <option value="@email">@email</option>
        <option value="@date">@date</option>
      </select> */}
    </div>
  );
};

export const CustomReactQuillEmailTemplateEditor = forwardRef<ReactQuill, any>((props, _ref) => {
  const { emailTemplateName } = props;
  const quillRef = useRef<ReactQuill>(null);
  const [imageLoading, setImageLoading] = useState(false);

  const handleImageUpload = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files?.[0];
      if (file) {
        const formData = new FormData();
        formData.append('image', file);

        try {
          setImageLoading(true);
          const response = await addImage(formData);
          //@ts-ignore
          const imageUrl = response.data.fileUrl;

          console.log('quill.current:', quillRef);
          // @ts-ignore
          const quill = quillRef.current?.getEditor();
          const range = quill?.getSelection()?.index;

          if (range !== undefined && range >= 0) {
            quill?.insertEmbed(range, 'image', imageUrl);
          }
          setImageLoading(false);
        } catch (error) {
          setImageLoading(false);
          console.error('Error uploading image:', error);

          alert('Error uploading image');
        }
      }
    };
  }, []);

  // Handle tag insertion
  const handleTagInsert = (tag: string) => {
    if (!tag) return;
    const quill = quillRef.current?.getEditor();
    if (quill) {
      const range = quill.getSelection();
      if (range) {
        quill.insertText(range.index, ` ${tag} `);
      }
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ['bold', 'italic', 'underline', 'blockquote'],
          [{ color: [] }],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        handlers: {
          // image: imageHandler,
          image: handleImageUpload,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    // [imageHandler],
    [handleImageUpload],
  );

  return (
    <div className="">
      {imageLoading && <LoadingBlock />}
      <CustomToolbar onTagSelect={handleTagInsert}
      emailTemplateName={emailTemplateName}
        SpecificTags={Object.values(EmailTemplateFullData[emailTemplateName].tags.specificEmailTags)}
        UiTags={Object.values(EmailTemplateFullData[emailTemplateName].tags.commonEmailUITags)}

      />
      <ReactQuill
        ref={quillRef}
        style={{
          height: 500,
          marginBottom: 60,
        }}
        modules={modules}
        formats={formats}
        {...props}
      />
    </div>
  );
});