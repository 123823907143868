import axios from 'axios'
import {
  OrderStatus,
  ProjectColorSatus,
  SingleQuestionInterface,
  UnitType,
} from '../utils/types'
import { ONE_AUD_TO_CENTS } from './utilities'

export const voteQuestionSingle: SingleQuestionInterface = {
  questionTitle: '',
  answerKey: 'A',
  answerOptions: {
    A: '',
    B: '',
    C: '',
    D: '',
  },
}

export const testAxios = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URI}`,
  withCredentials: true,
})

export const projectStatusObject: {
  [prop: string]: {
    key: ProjectColorSatus
    color: string
    label: string
  }
} = {
  red: { key: 'red', label: 'Red', color: 'red' },
  yellow: { key: 'yellow', label: 'Yellow', color: 'yellow' },
  green: { key: 'green', label: 'Green', color: 'green' },
}

export const urlBasedOnEnv = {
  development: 'http://localhost:3000',
  dev: 'https://dev.user.quantum2.io',
  beta: 'https://beta.user.quantum2.io',
  prod: 'https://quantum2.io',
}

export const ONE_USD_TO_CENTS = 100

const multiplyRateByLandUnitBaseGBP = {
  [UnitType.LAND_POUND]: { multiply: 1, CurrencyDisplaySymbol: 'GBP' },
  [UnitType.LAND_AUD]: { multiply: 1, CurrencyDisplaySymbol: 'AUD' },
  [UnitType.LAND_EUR]: { multiply: 0.6, CurrencyDisplaySymbol: 'EUR' },
  [UnitType.LAND_USD]: { multiply: 0.62, CurrencyDisplaySymbol: 'USD' },
}

export const transactionOrderStatusColorMap: {
  [key in OrderStatus]: string
} = {
  // [OrderStatus.Initiated]: "#cccccc",
  // [OrderStatus.InProcess]: "#ffcc00",
  // [OrderStatus.Failed]: "#ff0000",
  // [OrderStatus.Cancelled]: "#cccccc",
  // // [OrderStatus.PaymentSent]: "#00ccff",
  // [OrderStatus.PaymentSent]: "#ccc",
  // // [OrderStatus.PaymentSent_FromUserCrypto]: "#0099ff",
  // [OrderStatus.Approved]: "#00ff00",
  // [OrderStatus.Completed]: "#0acf37",
  // [OrderStatus.PartialComplete]: "#0acf37",
  // [OrderStatus.AllUnitSold]: "#3399ff",
  // [OrderStatus.FundsWaitingApproval]: "#ff6600",
  // [OrderStatus.AUDReadyToSendBetweenAccounts]: "#0acf37",
  // // [OrderStatus.Expired]: "#999999",
  // [OrderStatus.Locked]: "#999900",

  [OrderStatus.Initiated]: '#cccccc', // light gray
  [OrderStatus.InProcess]: '#ffcc00', // gold
  [OrderStatus.Failed]: '#ff0000', // red
  [OrderStatus.Cancelled]: '#cccccc', // light gray
  [OrderStatus.PaymentSent]: '#c8a2c8', // lilac
  [OrderStatus.Approved]: '#00ff00', // lime
  [OrderStatus.Completed]: '#0acf37', // green
  [OrderStatus.PartialComplete]: '#ffff00', // yellow
  [OrderStatus.AllUnitSold]: '#3399ff', // royal blue
  [OrderStatus.FundsWaitingApproval]: '#ff6600', // orange
  [OrderStatus.AUDReadyToSendBetweenAccounts]: '#d2b48c', // light brown
  [OrderStatus.Locked]: '#999900', // dark khaki
}
export const golbalDataLandingPages = [
  '/',
  '/dashboard',
  '/hnwi',
  '/landing',
  '/rocket1',
  '/home-old1',
]

export const TARGET_PURCHASE_PRICE_IN_CENTS_FOR_LU2X = 2500 * ONE_AUD_TO_CENTS



export const defaultDataConfig = {
  transaction: {
      BUY: {
          defaultUnitType: UnitType.LAND_USD,
      },
      SELL: {
          defaultUnitType: UnitType.LAND_USD,
      },
      TRANSFER: {
          defaultUnitType: UnitType.LAND_USD,
      },
      CONVERT: {
          defaultUnitType_from: UnitType.LAND_USD,
          defaultUnitType_to: UnitType.LAND_AUD,
      },
  }
}