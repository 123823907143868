/* eslint-disable no-lone-blocks */
import { useState, useEffect } from 'react'
//@ts-ignore
import { Link } from 'react-router-dom'
import {
  ONE_AUD_TO_CENTS,
  formatNumberWithCommas,
  formatNumberWithCommasUptoTwoDecimalWithCeil,
  getDate,
} from '../../utils/utilities'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {
  UnitType,
  UnitDisplayNames,
  CurrencyDisplaySymbol,
  CurrencyType,
  SortDirection,
  SortFieldsEnumForTransactionHistoryQuery,
} from '../../utils/types'
import {
  getUserbuyOrderPaymentSentWithBank,
  postApproveBuyBankOrder,
  postDisApproveBuyOrder,
} from '../../api'
import {
  SelectComponentValue,
  TableTopFiltersNewUIForBankPurchase,
  TRInfo,
} from '../../component/misc/smallComponents'
import { toast } from 'react-toastify'
import { SvgIcons } from '../../component/misc/SvgIcons'

const BuyBankPurchase = () => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)
  const [state, setState] = useState<any>({
    sortDirection: SortDirection.desc,
    isExportDownloadLoading: false,
    dataList: [],
    currentPage: 1,
    pageSize: 50,
    userType: '',
    searchTerm: '',
    searchType: 'name',
    unitType: '',
    numberOfUnits: '',
    fromDate: '',
    toDate: '',
    sortBy: SortFieldsEnumForTransactionHistoryQuery.CreatedAt,
  })

  const [approveORdisApproveLoading, setapproveORdisApproveLoading] =
    useState<boolean>(false)
  const [orderId, setOrderId] = useState<any>('')

  const getData = async () => {
    const {
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
      userType,
      unitType,
      sortBy,
      sortDirection,
    } = state
    getUserbuyOrderPaymentSentWithBank(
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
      userType,
      unitType,
      sortBy,
      sortDirection,
    )
      .then((res) => {
        // console.log('res.data', res.data.orders)
        setState((prevState: any) => ({
          ...prevState,
          dataList: res.data.orders,
        }))
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data.message)
      })
  }

  const handleApprove = () => {
    setapproveORdisApproveLoading(true)
    const id = toast.loading('Please wait...')

    const data = {
      orderId: orderId,
    }

    postApproveBuyBankOrder(data)
      .then((res: any) => {
        getData()
        setapproveORdisApproveLoading(false)
        toast.update(id, {
          render: res.data.message,
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        })
      })
      .catch((error) => {
        console.log(error)
        // toast.erroror(error.response.data.message)
        toast.update(id, {
          render: error.response.data.message,
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        })
        setapproveORdisApproveLoading(false)
      })
  }

  const handleDisApprove = () => {
    const id = toast.loading('Please wait...')
    setapproveORdisApproveLoading(true)
    const data = {
      orderId: orderId,
    }

    postDisApproveBuyOrder(data)
      .then((res: any) => {
        getData()
        toast.update(id, {
          render: res.data.message,
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        })
        setapproveORdisApproveLoading(false)
      })
      .catch((err) => {
        console.log(err)
        // toast.error(err.response.data.message)
        toast.update(id, {
          render: err.response.data.message,
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        })
        setapproveORdisApproveLoading(false)
      })
  }
  useEffect(() => {
    getData()
  }, [
    state.currentPage,
    state.fromDate,
    state.toDate,
    state.searchType,
    state.searchTerm,
    state.userType,
    state.unitType,
    state.sortBy,
    state.sortDirection,
  ])

  const handleExportOrders = async () => {
    setState((prevState: any) => ({
      ...prevState,
      isExportDownloadLoading: true,
    }))
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        userType,
        unitType,
      } = state
      const response = await getUserbuyOrderPaymentSentWithBank(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        userType,
        unitType,
        sortBy,
        sortDirection,
        true,
      )

      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'orders.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    }
  }

  const resetAllFiltersAndSort = () => {
    setState({
      sortDirection: SortDirection.desc,
      dataList: [],
      searchTerm: '',
      searchType: 'name',
      unitType: '',
      numberOfUnits: '',
      paymentMethod: '',
      fromDate: '',
      toDate: '',
      sortBy: SortFieldsEnumForTransactionHistoryQuery.CreatedAt,
    })
    getData()
  }

  const handleSortTableHead = (property: string) => {
    setState((prevState: any) => {
      return {
        ...prevState,
        sortBy: property,
        // sortDirection: newSortDirection
      }
    })
  }
  const { sortBy, sortDirection } = state
  return (
    <>
      <div className="card-ui-pages-content-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ol
                className="breadcrumb"
                style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
              >
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  Bank Orders for approval
                </li>
              </ol>

              <div className="">
                <div className="">
                  <TableTopFiltersNewUIForBankPurchase
                    state={state}
                    setState={setState}
                    getData={getData}
                    handleExportOrders={handleExportOrders}
                    sortInputs={
                      <>
                        <div className="col-lg-3 mb-3">
                          {/* <Label className="fz14 fw-semibold mb-0">Sort Order</Label> */}
                          <SelectComponentValue
                            value={sortDirection}
                            placeholder="Sort Order"
                            onChange={(sortDirection) =>
                              // setFilterPaymentMethod(value)
                              // @ts-ignore
                              setState((prev) => ({ ...prev, sortDirection }))
                            }
                            options={[
                              { value: 'asc', label: 'Ascending' },
                              { value: 'desc', label: 'Descending' },
                            ]}
                          />
                        </div>
                        <div className="col-lg-3 mb-3">
                          {/* <Label className="fz14 fw-semibold mb-0">Sory By</Label> */}
                          <SelectComponentValue
                            value={sortBy}
                            placeholder="Sort By"
                            onChange={(sortBy) => {
                              // @ts-ignore
                              handleSortTableHead(sortBy)
                            }}
                            options={[
                              {
                                label: 'Number of Units',
                                value: 'currencyUnit.numberOfUnits',
                              },
                              {
                                label: 'Value',
                                value: 'paymentDetails.paymentAmount',
                              },
                              { label: 'Date', value: 'createdAt' },
                            ]}
                          />
                        </div>
                      </>
                    }
                  />
                  <div className="tr-card-list">
                    {state?.dataList &&
                      state.dataList.map((item: any, index: number) => (
                        <div className="tr-card">
                          <div className="info-icon-wrap">
                            {/* <div className="ic-label-wrap">
                          <div className="ic-label withbg">
                            {SvgIcons.MoneyCash}
                            <span>{camelCaseToSpaced(item.orderStatus)}</span>
                          </div>
                        </div> */}
                            <div className="ic-label-wrap">
                              <div className="ic-label withbg">
                                {SvgIcons.CheckCircle}
                                <span>
                                  {
                                    UnitDisplayNames[
                                      item.currencyUnit?.unitType as UnitType
                                    ]
                                  }{' '}
                                  {/* ({item.currencyUnit.numberOfUnits}) -{' '}
                                  {item.paymentDetails?.paymentAmount /
                                    ONE_AUD_TO_CENTS}{' '}
                                  {
                                    CurrencyDisplaySymbol[
                                    item.paymentDetails
                                      ?.currency as CurrencyType
                                    ]
                                  } */}
                                </span>
                              </div>
                            </div>
                            {/* <div className="ic-label-wrap">
                          <div className={item.orderType !== "BUY" ? "ic-label up" : "ic-label"}>
                            {SvgIcons.ArrowDown}
                            <span>{item.orderType}</span>
                          </div>
                        </div> */}

                            <div className="tr-info-wrap">
                              <TRInfo
                                value={formatNumberWithCommas(
                                  item.currencyUnit.numberOfUnits,
                                )}
                                label={'Number of Units'}
                              />
                            </div>

                            <div className="tr-info-wrap">
                              <TRInfo
                                value={
                                  <>
                                    {
                                      CurrencyDisplaySymbol[
                                        item.paymentDetails
                                          ?.currency as CurrencyType
                                      ]
                                    }{' '}
                                    {formatNumberWithCommasUptoTwoDecimalWithCeil(
                                      item.paymentDetails?.paymentAmount /
                                        ONE_AUD_TO_CENTS,
                                    )}
                                  </>
                                }
                                label={'Value'}
                              />
                            </div>
                          </div>
                          <div className="info-text">
                            <div className="info-text-col-wrap">
                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={
                                      item.user?.firstName +
                                      ' ' +
                                      item.user?.lastName
                                    }
                                    label={'Name'}
                                  />
                                </div>

                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={
                                      item.buy?.paymentId
                                        ? item.buy.paymentId
                                        : '--'
                                    }
                                    label={'Payment Reference'}
                                  />
                                </div>
                              </div>
                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={item.user?.email}
                                    label={'Email'}
                                  />
                                </div>

                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={getDate(
                                      item.createdAt?.toLocaleString(),
                                    )}
                                    label={'Created Date'}
                                  />
                                </div>
                              </div>

                              <div className="info-col">
                                <div
                                  className="text-start pt-4 pe-4"
                                  onClick={() => setOrderId(item.orderId)}
                                >
                                  <QButton
                                    size="sm"
                                    onClick={toggle}
                                    disabled={approveORdisApproveLoading}
                                  >
                                    Action
                                  </QButton>
                                </div>
                              </div>
                            </div>

                            {item.buy?.comments && (
                              <p className="comments">
                                <b> Note:</b> {item.buy?.comments}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    className="modal-dialog-centered"
                  >
                    <ModalHeader toggle={toggle}>
                      Approve/Dis-Approve Bank Purchase Order
                    </ModalHeader>
                    <ModalBody>
                      <div className="d-flex justify-content-between">
                        <Button
                          color="success"
                          onClick={() => {
                            handleApprove()
                            toggle()
                          }}
                        >
                          Approve
                        </Button>

                        <Button
                          color="primary"
                          onClick={() => {
                            handleDisApprove()
                            toggle()
                          }}
                        >
                          Dis-Approve
                        </Button>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle}>
                        Close
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BuyBankPurchase
