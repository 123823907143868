import React, {
} from 'react'
import Header from '../../component/Sidbar'
import Footer from '../../component/Footer'
import { Link } from 'react-router-dom'
import './index.scss'
import NewsCategoryAdd from '../../component/NewsCategoryAddEdit/NewsCategoryAdd'

export default function AddNewsCategory() {
  return (
    <>
      <div>
        

        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Add News Category</li>
            </ol>
          </div>
          <div className="row">
            <div className="col-md-8 ms-auto me-auto pt-5 common-box-shadow ">
              <h1 style={{ textAlign: 'center' }}>Add News Category</h1>
              <NewsCategoryAdd />
            </div>
          </div>
        </div>

        
      </div>
    </>
  )
}
