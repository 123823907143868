import React, { useEffect, useState } from 'react'
import Header from '../../component/Sidbar'
import Footer from '../../component/Footer'
import { Link, useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import axios from 'axios'
import moment from 'moment'
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Button,
  ModalFooter,
} from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { toast } from 'react-toastify'
import { deletePromotionEmail, getAllPromotionEmailByDayTypes, sendInstantPromotionEmail } from '../../api'
import { testAxios } from '../../utils/constants'
import { EmailDeliveryTypeEnum, PromotionEmailInterface, } from '../../utils/types'
import { capitalizeFirstLetter } from '../../utils/utilities'
export default function ViewPromotionEmails({
  emailDeliveryType
}: {
  emailDeliveryType: EmailDeliveryTypeEnum
}) {
  const [manualEmailSendModal, setmanualEmailSendModal] = useState(false)
  const [manualEmailSendLoading, setmanualEmailSendLoading] = useState(false)
  const togglemanualEmailSendModal = () => {
    setmanualEmailSendModal(!manualEmailSendModal)
  }
  const [deleteModal, setdeleteModal] = useState(false)
  const [deleteLoading, setdeleteLoading] = useState(false)
  const [viewModal, setviewModal] = useState(false)

  const toggleDeleteModal = () => {
    setdeleteModal(!deleteModal)
  }
  const toggleViewModal = () => {
    setviewModal(!viewModal)
  }
  const toggleEditModal = () => {
    setdeleteModal(!deleteModal)
  }
  const [data, setData] = useState<PromotionEmailInterface[]>([])


  const [activeData, setactiveData] =
    useState<PromotionEmailInterface | null>(null)

  const navigate = useNavigate()

  const DeletepromotionEmail = async () => {
    try {
      if (activeData?._id) {
        setdeleteLoading(true)
        const response = await deletePromotionEmail(activeData!._id)
        setdeleteLoading(false)
        toggleDeleteModal()
        toast.success(response.data.message, { position: 'bottom-center' })
        getAllData()
      }
    } catch (e) {
      console.log('e:', e)
      setdeleteLoading(false)
    }
  }

  const getAllData = async () => {
    getAllPromotionEmailByDayTypes(emailDeliveryType).then((res) => {
      //@ts-ignore
      setData([...(res.data as PromotionEmailInterface[])]) // <--
    })
  }
  useEffect(() => {
    getAllData()
  }, [])

  const sendManualEmail = async () => {

    if (activeData) {
      try {
        setmanualEmailSendLoading(true);
        toast.success("Manual Promotion email is being sent", {
          position: 'bottom-center',
        })
        setmanualEmailSendLoading(false);
        setmanualEmailSendModal(false)

        const response = await sendInstantPromotionEmail({
          templateName: activeData.templateName,
          isActive: activeData.isActive,
          dayType: activeData.dayType,
          recipientsType: activeData.recipientsType,
          subject: activeData.subject,
          content: activeData.content,
          templateConfig: activeData.templateConfig,
          lastSentDate: activeData.lastSentDate,
        });
        if (response) {

          // toast.success(response.data.message, {
          //   position: 'bottom-center',
          // })
          
          getAllData();
          // toast.success("All email is sent", {
          //   position: 'bottom-center',
          // })
        }
      } catch (error) {
        console.log('error:', error)
        setmanualEmailSendLoading(false);

      }

    }

  }

  return (
    <>
      {/* <div>{showChildComponent && <EditQuestions data={id} />}</div> */}
      <div className="container">
        <ol
          className="breadcrumb"
          style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
        >
          <li className="breadcrumb-item">
            <Link to="/">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">{emailDeliveryType} - Promotion Email List</li>
          <li>
            <QButton
              onClick={() => navigate('/add-promotion-email')}
              round
              color='success'
              size="sm"
              className="ms-3"
            >
              Add Promotion Email
            </QButton>
          </li>
        </ol>
        <table
          className="table table-bordered table-striped table-hover "
          
          width="100%"
          cellSpacing="0"
        >
          <thead className='thead-dark'>
            <tr>

              <th style={{ width: 35 }}>S.No.</th>
              <th style={{ width: 205 }}>Template Name</th>
              <th style={{ width: 205 }}>Subject</th>
              <th>Is Active</th>
              <th>Schedule Type</th>
              <th>Recipients Type</th>
              <th>Last Sent</th>
              <th
                style={{ width: 280 }}
              >Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map(
              (promotionEmail: PromotionEmailInterface, index: number) => {
                return (
                  <tr key={index}>
                    <td>
                      <b>{index + 1}.</b>
                    </td>
                    <td>
                      <b>{promotionEmail.templateName}.</b>
                    </td>
                    <td >
                      <b>{promotionEmail.subject}.</b>
                    </td>
                    <td>
                      <b>{promotionEmail.isActive ? <span style={{ color: "black" }}>Yes</span> : <span style={{ color: "red" }}>No</span>}</b>
                    </td>
                    <td>
                      <b>{promotionEmail.dayType === 0 ? "Any Time" : `Auto at day ${promotionEmail.dayType}`} </b>
                    </td>
                    <td>
                      <b>{capitalizeFirstLetter(promotionEmail.recipientsType)} user</b>
                    </td>
                    <td>
                        <b>{promotionEmail.lastSentDate ?  moment(promotionEmail.lastSentDate).format("DD/MM/YY hh:mm A"): "Not sent yet"}</b>
                    </td>

                    {/* <td>{promotipromotionEmailNameonEmail.landpromotionEmailPrimaryImage}</td> */}

                    <td>
                      <QButton
                        size="sm"
                        onClick={() => {
                          // navigate(`/editQuestion?id=${promotionEmail._id}`)
                          // navigate('/editpromotionEmail/' + promotionEmail._id)
                          // navigate(`/feemanagement`)
                          if (promotionEmail._id) {
                            setactiveData(promotionEmail)
                            toggleViewModal()
                          }
                        }}
                        round
                        className="me-2"
                      >
                        View
                      </QButton>
                      <QButton
                        size="sm"
                        onClick={() => {
                          navigate('/edit-promotion-email/' + promotionEmail._id)

                        }}
                        round
                        className="me-2"
                      >
                        Edit
                      </QButton>
                      <QButton
                        size="sm"
                        onClick={() => {
                          if (promotionEmail._id) {
                            setactiveData(promotionEmail)
                            toggleDeleteModal()
                          }
                        }}
                        round
                      >
                        Delete
                      </QButton>
                      {/* <EditIcon /> */}
                      {promotionEmail.dayType === 0 &&
                        <QButton
                          className="me-2 mt-2"
                          color='success'
                          size="sm"
                          round
                          disabled={!promotionEmail.isActive}
                          onClick={() => {
                            setactiveData(promotionEmail)
                            setmanualEmailSendModal(true)
                          }}
                        >Send Email Now</QButton>}
                    </td>
                    {/* <td
                    
                      >
                        <DeleteOutlineIcon />
                      </td> */}
                  </tr>
                )
              },
            )}
          </tbody>
        </table>


        <Modal
          isOpen={viewModal}
          toggle={toggleViewModal}
          className="modal-dialog-centered "
          scrollable
          size="lg"
          unmountOnClose={true}
        >
          <ModalHeader>Email Content Preview</ModalHeader>
          <ModalBody>
            {activeData && (
              <div className="details-in-modal">
                <div className="static-text-content-page">


                  <div className="data-block-list">



                    <div className="data-block">

                      <div
                        className="dd"
                        dangerouslySetInnerHTML={{
                          __html: activeData.content,
                        }}
                      ></div>


                    </div>
                  </div>
                </div>
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            <QButton round className="me-3" onClick={toggleViewModal}>
              Close
            </QButton>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={deleteModal}
          toggle={toggleDeleteModal}
          className="modal-dialog-centered "
          scrollable
          // size="lg"
          unmountOnClose={true}
        >
          <ModalHeader>Confirm.</ModalHeader>
          <ModalBody>Do you want to delete this promotion Email ?</ModalBody>

          <ModalFooter>
            <QButton round className="me-3" onClick={() => toggleDeleteModal()}>
              Close
            </QButton>
            <QButton round loading={deleteLoading} onClick={DeletepromotionEmail}>

              Delete
            </QButton>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={manualEmailSendModal}
          toggle={togglemanualEmailSendModal}
          className="modal-dialog-centered "
          scrollable
          // size="lg"
          unmountOnClose={true}
        >
          <ModalHeader>Confirm.</ModalHeader>
          <ModalBody>Do you want to Send this promotion Email ?</ModalBody>

          <ModalFooter>
            <QButton round className="me-3" onClick={togglemanualEmailSendModal}>
              Close
            </QButton>
            <QButton round loading={deleteLoading} onClick={sendManualEmail}>
              Send Now
            </QButton>
          </ModalFooter>
        </Modal>
      </div>

      

    </>
  )
}
