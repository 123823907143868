import { TARGET_PURCHASE_PRICE_IN_CENTS_FOR_LU2X } from '../constants'
import { CurrencyDisplaySymbol, CurrencyType, Discount } from '../types'
import {
  formatNumberWithCommasUptoTwoDecimalWithCeil,
  ONE_AUD_TO_CENTS,
  formatUpToCeil,
} from '../utilities'

export const validateDiscount = (
  purchasePrice: number,
  pricePerUnit: number,
  discount: Discount,
  currency: CurrencyType,
) => {
  const { isPercent, amount } = discount
  let isDoubleLand = false
  if (isPercent) {
    if (amount > 100) {
      return {
        error: "This code having percentage more than 100 can't applied",
        discountAmount: 0,
        isDoubleLand,
      }
    } else if (amount >= 50) {
      // Calculate the required purchase price before discount to meet the target after discount
      // const requiredPurchasePrice =
      //     targetPurchasePrice / (1 - amount / 100);

      // // Calculate the number of units required
      // const requiredNumberOfUnits = Math.ceil(
      //     (requiredPurchasePrice / pricePerUnit) * (1 - amount / 100)
      // );

      const targetPurchasePrice = TARGET_PURCHASE_PRICE_IN_CENTS_FOR_LU2X
      if (purchasePrice < targetPurchasePrice) {
        // setDiscountPaymentError(
        //     `To apply a ${amount}% discount, you must order at least ${requiredNumberOfUnits} units so that the purchase amount after discount is at least ${targetPurchasePrice / ONE_AUD_TO_CENTS} AUD.`
        // );
        return {
          error: `To use this offer, you must buy a minimum of ${formatNumberWithCommasUptoTwoDecimalWithCeil(targetPurchasePrice / ONE_AUD_TO_CENTS)} ${CurrencyDisplaySymbol[currency]}.`,
          discountAmount: 0,
          isDoubleLand,
        }
      }
      return { error: '', discountAmount: 0, isDoubleLand: true }
    }
    return {
      error: '',
      discountAmount: (purchasePrice * amount) / 100,
      isDoubleLand,
    }
  } else {
    const discountAmount = amount
    if (discountAmount > 0.2 * purchasePrice) {
      return {
        error: `Discount cannot exceed 20% of the purchase amount. You must order at least ${formatUpToCeil((discountAmount * 5) / pricePerUnit)} units.`,
        discountAmount: 0,
        isDoubleLand,
      }
    }
    return { error: '', discountAmount, isDoubleLand }
  }
}
