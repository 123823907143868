import { dynamicEmailTemplateInitialTexts } from "./frontend-dynamicEmailTemplateTextRepository";




export const EmailDynamicPlaceholders = {
  PaymentSum: "@PaymentSum",
  PaymentReference: "@PaymentReference",
  TransactionFee: "@TransactionFee",
  name: "@name",
  LandUnitCountAndType: "@LandUnitCountAndType",
  SaleLandUnitCountAndType: "@SaleLandUnitCountAndType",
  BuyLandUnitCountAndType: "@BuyLandUnitCountAndType",
  orderId: "@orderId",
  AmountWithCurrencySymbol: "@AmountWithCurrencySymbol",
  PayoutAmountWithCurrencySymbol: "@PayoutAmountWithCurrencySymbol",
  newsTitle: "@newsTitle",
  newsLink: "@newsLink",
  recipientEmail: "@recipientEmail",
  recipientName: "@recipientName",

} as const;


export type EmailReplacements = {
  [key in keyof typeof EmailDynamicPlaceholders]?: string;
}

export type EmailTemplateFullDataType = keyof typeof EmailTemplateFullData;
export type EmailTemplateFullDataTypeValues = typeof EmailTemplateFullData[EmailTemplateFullDataType];

export interface DynamicEmailRequestObjectInterface {
  emailTemplateName: keyof typeof EmailTemplateFullData;
  DynamicText?: string;
}


export interface EmailTemplateDataInterface {
  emailTemplateName: EmailTemplateFullDataType,
  isFromUser: true,
  title: string,
}

export interface DynamicEmailRequestObjectInterface {
  emailTemplateName: keyof typeof EmailTemplateFullData;
  DynamicText?: string;
}


export type EmailTemplateRenderedData = {
  text: string,
  html: string
}

export const commonEmailUITags: string[] = [
  "@Add-Gray-Block-Section-Start",
  "@Add-Gray-Block-Section-End",
];




export type EmailTemplateTags = {
  commonEmailUITags: string[];
  specificEmailTags: EmailReplacements;
};

export type EmailTemplateType = {
  emailTemplateName: string;
  isFromUser: boolean;
  title: string;
  tags: EmailTemplateTags;
  DynamicText: string;
  Component: React.ComponentType<{ DynamicText?: string }> | null;
};


export const createEmailTemplateTags = (specificTags: EmailReplacements): EmailTemplateTags => ({
  commonEmailUITags,
  specificEmailTags: specificTags,
});



export const createEmailTemplateObject = (Component: React.ComponentType<{
  DynamicText?: string
}> | null = null,
  title: string,
  emailTemplateName: EmailTemplateFullDataType,
  isFromUser: boolean,
  specificEmailTags: EmailReplacements): EmailTemplateType => ({
    emailTemplateName,
    isFromUser,
    title: title,
    tags: createEmailTemplateTags(specificEmailTags),
    DynamicText: dynamicEmailTemplateInitialTexts[emailTemplateName],
    Component,

  });


  export const EmailTemplateFullData_From_Land_User: Record<string, EmailTemplateType> = {
    CreateSellOrderEmail: createEmailTemplateObject(
      null,
      "Sell Order Email",
      "CreateSellOrderEmail",
      true,
      {
        name: EmailDynamicPlaceholders.name,
        orderId: EmailDynamicPlaceholders.orderId,
        SaleLandUnitCountAndType: EmailDynamicPlaceholders.SaleLandUnitCountAndType,
        PayoutAmountWithCurrencySymbol: EmailDynamicPlaceholders.PayoutAmountWithCurrencySymbol,
        TransactionFee: EmailDynamicPlaceholders.TransactionFee
      },
    ),
  
  
    CreateTransferOrderEmail: createEmailTemplateObject(
      null,
      "Transfer Order Email", 
      "CreateTransferOrderEmail", 
      true,
      {
        name: EmailDynamicPlaceholders.name,
        LandUnitCountAndType: EmailDynamicPlaceholders.LandUnitCountAndType,
        recipientEmail: EmailDynamicPlaceholders.recipientEmail,
        recipientName: EmailDynamicPlaceholders.recipientName,
      },
    ),
  };

export const EmailTemplateFullData_From_Land_Admin: Record<string, EmailTemplateType> = {
  CreateNewsEmail: createEmailTemplateObject(null,
    "Latest News from Quantum2",
    "CreateNewsEmail",
    false,
    {
      newsTitle: EmailDynamicPlaceholders.newsTitle,
      newsLink: EmailDynamicPlaceholders.newsLink
    },),
};



export const EmailTemplateFullData: Record<string, EmailTemplateType> = {
  ...EmailTemplateFullData_From_Land_User,
  ...EmailTemplateFullData_From_Land_Admin,
};


