import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { debounce, getDate } from '../../utils/utilities'
import {
  getVerifiedCommunityAgentUsers,
  putDisapproveUserCommunityAgent,
} from '../../api'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from 'reactstrap'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { User } from '../../utils/types'
export default function ViewApprovedCommunityAgent() {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [users, setUsers] = useState<User[]>([])
  const [email, setEmail] = useState<any>([])
  const [searchType, setSearchType] = useState('name') // Default search type is name
  const [searchTerm, setSearchTerm] = useState('')
  const [disApprovalReason, setDisApprovalReason] = useState('')
  const [saveMessage, setSaveMessage] = useState('')
  const [isDisApproveLoading, setIsDisApproveLoading] = useState(false)
  const [isExportDownloadLoading, setIsExportDownloadLoading] = useState(false)

  const getVerifiedUsersCommunityAgent = async () => {
    getVerifiedCommunityAgentUsers(searchType, searchTerm)
      .then((res) => {
        const users = res.data.users
        // @ts-ignore
        setUsers(users)
      })
      .catch((err) => {
        console.log('error=============', err)
      })
  }

  // Debounced version of getProjectByLandName for 500 mili seconds
  const debouncedGetAllVerifiedUsersCommunityAgent = debounce(
    getVerifiedUsersCommunityAgent,
    500,
  )

  useEffect(() => {
    debouncedGetAllVerifiedUsersCommunityAgent()
  }, [])

  const handleExportCommunityAgent = async () => {
    setIsExportDownloadLoading(true)
    try {
      const response = await getVerifiedCommunityAgentUsers(
        searchType,
        searchTerm,
        true,
      )

      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'community-agent.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setIsExportDownloadLoading(false)
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setIsExportDownloadLoading(false)
    }
  }

  const handleDisApproveCommunityAgent = async () => {
    setIsDisApproveLoading(true)
    await putDisapproveUserCommunityAgent(email, disApprovalReason)
      .then((res) => {
        setSaveMessage(res.data.message)
        setTimeout(() => {
          setSaveMessage('')
          getVerifiedUsersCommunityAgent()
          setEmail(null)
          toggle()
        }, 500)
        setIsDisApproveLoading(false)
      })
      .catch((error: any) => {
        toast.error(error.response.data.message)
        setIsDisApproveLoading(false)
      })
  }

  return (
    <>
      <div>
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                View Approved Community Agents
              </li>
            </ol>

            <div className="card mb-3">
              {/* <div className="card-header">
                                <i className="fa fa-list-ul me-2"></i> List of All Transactions
                            </div> */}
              <div className="card-body">
                <div className="table-responsive">
                  <div>
                    {/* <select className="me-sm-1 mb-sm-2" value={searchType} onChange={(e) => setSearchType(e.target.value)}>
											<option value="name">Search User by Name</option>
											<option value="email">Search User by Email</option>
										</select>
										<input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /> */}
                    <FormGroup className="search-by-wrap">
                      <select
                        className="form-control"
                        value={searchType}
                        onChange={(e) => setSearchType(e.target.value)}
                      >
                        <option value="name">Search User by Name</option>
                        <option value="email">Search User by Email</option>
                      </select>

                      <SearchInput
                        onClick={getVerifiedUsersCommunityAgent}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        ButtonText="Search"
                        placeholder={'Search'}
                        // loading={userLoading}
                      />
                    </FormGroup>
                    <QButton
                    className='ms-2'
                      loading={isExportDownloadLoading}
                      onClick={handleExportCommunityAgent}
                      round
                      size="sm"
                    >
                      Download Agent List
                    </QButton>
                  </div>
                  <table
                    className="table table-bordered"
                    
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Approved By</th>
                        <th>Disapprove</th>
                      </tr>
                    </thead>
                    {users.map((user: User, index: number) => (
                      <tr key={index}>
                        <td>
                          <b>{index + 1}.</b>
                        </td>
                        <td>
                          {user?.firstName} {user?.lastName}
                        </td>
                        <td>{user?.email}</td>
                        <td>
                          <p>
                            {[
                              user.ratings.communityAgent.approvedBy
                                ?.bySuperAdmin &&
                                user.ratings.communityAgent.approvedBy
                                  .bySuperAdmin.firstName +
                                  ' ' +
                                  user.ratings.communityAgent.approvedBy
                                    .bySuperAdmin.lastName,
                              user.ratings.communityAgent.approvedBy
                                ?.byUserAdmin &&
                                user.ratings.communityAgent.approvedBy
                                  .byUserAdmin.firstName +
                                  ' ' +
                                  user.ratings.communityAgent.approvedBy
                                    .byUserAdmin.lastName,
                            ]
                              .filter(Boolean)
                              .join(', ') || ''}
                          </p>
                          <p>
                            {[
                              user.approvedBy?.bySuperAdmin?.email,
                              user.approvedBy?.byUserAdmin?.email,
                            ]
                              .filter(Boolean)
                              .join(', ') || ''}
                          </p>
                        </td>

                        <td>
                          <QButton
                            onClick={() => {
                              setEmail(user?.email)
                              toggle()
                            }}
                            round
                            size="sm"
                          >
                            Disapprove
                          </QButton>
                        </td>
                      </tr>
                    ))}
                  </table>

                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    className="modal-dialog-centered"
                  >
                    {/* <ModalHeader toggle={toggle}>Disapprove User</ModalHeader> */}
                    <ModalBody>
                      {saveMessage && (
                        <div className="save-message">{saveMessage}</div>
                      )}
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <label htmlFor="disApprovalReason">
                            Reason for disapproval (Max 300 characters)
                          </label>
                          <textarea
                            id="disApprovalReason"
                            className="form-control"
                            onChange={(e) =>
                              setDisApprovalReason(e.target.value)
                            }
                            maxLength={300}
                          />
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <QButton color="primary" round onClick={toggle}>
                        Close
                      </QButton>{' '}
                      <QButton
                        loading={isDisApproveLoading}
                        round
                        color="secondary"
                        onClick={() => {
                          handleDisApproveCommunityAgent()
                        }}
                      >
                        Disapprove
                      </QButton>{' '}
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
