import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import '../../App.css'
import { Link } from 'react-router-dom'
import { News, NewsCategoryInterface, NewsStatus } from '../../utils/types'
import UpdateIcon from '@mui/icons-material/Update'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  addImage,
  deleteNews,
  getAllNewsCategory,
  getNewsByTitle,
  getViewOrDownloadFileFromAwsS3ForNews,
  putNews,
  sentNewsTo,
} from '../../api'
import { FormGroup } from 'reactstrap'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
// import ModalViewFile from '../../component/ModalViewFile'
import {
  createFormDataForAddingNews,
  getEnteredByName,
} from '../../utils/utilities'
import ReactQuill from 'react-quill-new'
import { CustomReactQuill, formats } from '../addNews'
import { urlBasedOnEnv } from '../../utils/constants'
import { init_revalidate_next_pages, stringToSlug } from '../../utils/common'
import LoadingBlock from '../../component/misc/smallComponents'

const DeleteUpdateViewNews: React.FC = () => {
  const [categoryList, setcategoryList] = useState<NewsCategoryInterface[]>([]);
  const quillRef = useRef<ReactQuill>(null)

  const AWS_FILE_VIEW_URI = process.env.REACT_APP_AWS_FILE_VIEW_URI
  const [news, setNews] = useState<News[]>([])
  const [selectedNews, setSelectedNews] = useState<News | null>(null)
  const [updatedNews, setUpdatedNews] = useState<News | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSentToLoadingFor, setIsSentToLoadingFor] = useState({
    member: false,
    subscriber: false,
  })
  const [saveMessage, setSaveMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [searchNewsByTitle, setSearchNewsByTitle] = useState<string>('')

  const [isLoadingSave, setIsLoadingSave] = useState(false)
  const [isLoadingPublish, setIsLoadingPublish] = useState(false)
  const [viewNewsImageForView, setViewNewsImageForView] = useState(null)
  const [imageUrl, setimageUrl] = useState('')
  const [modalOpenForNewUpload, setModalOpenForNewUpload] = useState(false)

  const toggle_doc_modal = () => setdoc_Modal(!doc_modal)
  const [doc_modal, setdoc_Modal] = useState(false)

  const [modalType, setModalType] = useState<'view' | 'edit' | 'delete' | null>(
    null,
  )
  const openModalForNews = (news: News, type: 'view' | 'edit' | 'delete') => {
    handleNewsSelect(news, type)
    setModalType(type)
  }

  const closeModalForNew = () => {
    setUpdatedNews(null)
    setSelectedNews(null)
    setModalType(null)
  }

  const getAllCategory = async () => {
    getAllNewsCategory().then((res) => {
      console.log('res:', res)
      //@ts-ignore
      // setData([...(res.data as NewsCategoryInterface[])]) // <--

      setcategoryList(res.data);
      //@ts-ignore
      if (res.data.length > 0) {

        //@ts-ignore

      }
    })
  }
  useEffect(() => {
    getAllCategory()
  }, [])
  const handleUpdateForSaveOrPublishNews = async (
    status: NewsStatus,
    cb?: (p: any) => void,
  ) => {
    if (!updatedNews) return
    const statusChanged = updatedNews.status !== status
    const isNewsDataChanged =
      statusChanged ||
      JSON.stringify(selectedNews) !== JSON.stringify(updatedNews)
    if (!isNewsDataChanged) return alert('data hasnot been changed yet')

    const { newsEnteredBy, ...newsDataToSend } = updatedNews
    const newsWithFormData = createFormDataForAddingNews({
      ...newsDataToSend,
      status,
    })
    setLoadingForSaveOrPublish(status, true)
    setErrorMessage('')
    putNews(newsWithFormData)
      .then(async ({ data: { message, news } }) => {
        setSaveMessage(message)
        handleNewsSelect(news, 'edit')

        setTimeout(() => {
          setSaveMessage('')
          setLoadingForSaveOrPublish(status, false)
        }, 500)
        if (typeof cb === 'function') {
          cb(news)
        }

        init_revalidate_next_pages()

        await getNewsByTitleForView();

      })
      .catch((err) => {
        console.log('error', err)
        setLoadingForSaveOrPublish(status, false)
        if (err.response) {
          setErrorMessage(err.response.data.message)
          toast.error(err.response.data.message, { position: 'bottom-center' })
        }
      })
  }
  const handleDeleteNews = async () => {
    if (!selectedNews) return
    setIsLoading(true)
    deleteNews(selectedNews.id)
      .then(async ({ data: { message } }) => {
        setSaveMessage(message)
        setTimeout(() => {
          setSaveMessage('')
          closeModalForNew()
          setSelectedNews(null)
          setIsLoading(false)
          init_revalidate_next_pages();
        }, 500)
        await getNewsByTitleForView()
      })
      .catch((err) => {
        setIsLoading(false)
        setErrorMessage(err.response.data.message)
        toast.error(err.response.data.message, { position: 'bottom-center' })

        console.log('error', err)
      })
  }
  const getNewsByTitleForView = async () => {
    getNewsByTitle(searchNewsByTitle)
      .then((res) => {
        setNews(res.data.news)
      })
      .catch((err) => {
        console.log('apis error====', err)
        toast.error(err.response.data.message)
      })
  }

  const handleNewsSelect = (news: News, type: 'view' | 'edit' | 'delete') => {
    setSelectedNews(news)
    if (type === 'edit') {
      setUpdatedNews(news)
      if (!news.newsCategoryId) {
        //@ts-ignore
        setUpdatedNews(prev => ({ ...news, newsCategoryId: categoryList[0] ? categoryList[0]._id : "" }))

      }
    } else {
      setUpdatedNews(null)
    }
  }

  const setLoadingForSaveOrPublish = (status: NewsStatus, state: boolean) => {
    if (status === NewsStatus.Saved) {
      setIsLoadingSave(state)
    } else if (status === NewsStatus.Published) {
      setIsLoadingPublish(state)
    }
  }
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name } = event.target
    let value: string | FileList | null = ''
    if (
      event.target instanceof HTMLInputElement &&
      event.target.type === 'file'
    ) {
      value = event.target.files ? event.target.files : null
    } else {
      value = event.target.value
    }
    setUpdatedNews((prevProject) => ({
      ...prevProject!,
      [name]: value,
    }))
  }

  const sentTo = (newsId: string, isMember: boolean) => {
    setIsSentToLoadingFor((prevState) => ({
      ...prevState,
      member: isMember ? true : false,
      subscriber: isMember ? false : true,
    }))
    sentNewsTo(newsId, isMember)
      .then(async ({ data: { message } }) => {
        setSaveMessage(message)
        setIsSentToLoadingFor({ member: false, subscriber: false })
        setTimeout(() => {
          setSaveMessage('')
        }, 500)
        await getNewsByTitleForView()
      })
      .catch((err) => {
        setIsSentToLoadingFor({ member: false, subscriber: false })
        setErrorMessage(err.response.data.message)
        toast.error(err.response.data.message, { position: 'bottom-center' })

        console.log('error', err)
      })
  }

  // Debounced version of getProjectByLandName for 500 mili seconds
  //   const debouncedGetProjectByLandName = debounce(getNewsByTitleForView, 500)

  useEffect(() => {
    getNewsByTitleForView()
  }, [])
  // useEffect(() => {
  //   setErrorMessage('')
  //   if (!selectedNews) return
  //   // its for viewing file directly
  //   getViewOrDownloadFileFromAwsS3ForNews(selectedNews.id, false)
  //     .then(({ data: { url } }) => {
  //       setimageUrl(url)
  //     })
  //     .catch((err) => {
  //       console.log('error', err)
  //       setErrorMessage(err.response.data.error)
  //     })
  // }, [selectedNews])
  const handleDownload = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault() // Prevent the default behavior of following the link
    setErrorMessage('')
    if (!selectedNews) return
    getViewOrDownloadFileFromAwsS3ForNews(selectedNews?.id, true)
      .then(({ data: { url } }) => {
        // Create a temporary anchor element
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.click()
      })
      .catch((err) => {
        console.log('error', err)
        setErrorMessage(err.response.data.error)
        toast.error(err.response.data.error, { position: 'bottom-center' })
      })
  }
  const [imageLoading, setimageLoading] = useState(false);
  const handleImageUpload = useCallback(() => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file = input.files?.[0]
      if (file) {
        const formData = new FormData()
        formData.append('image', file)

        try {
          setimageLoading(true);
          const response = await addImage(formData)
          //@ts-ignore
          const imageUrl = response.data.fileUrl

          console.log('quill.current:', quillRef)
          // @ts-ignore
          const quill = quillRef.current?.getEditor()
          const range = quill?.getSelection()?.index

          if (range !== undefined && range >= 0) {
            quill?.insertEmbed(range, 'image', imageUrl)
          }
          setimageLoading(false);
        } catch (error) {
          setimageLoading(false);
          console.error('Error uploading image:', error)
          alert('Error uploading image')
        }
      }
    }
  }, [])

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ['bold', 'italic', 'underline', 'blockquote'],
          [{ color: [] }],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        handlers: {
          // image: imageHandler,
          image: handleImageUpload,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    // [imageHandler],
    [handleImageUpload],
  )
  const previewNews = (n: any) => {
    let clientPreviewUrl =
      // @ts-ignore
      urlBasedOnEnv[process.env.REACT_APP_NODE_ENV]
    window.open(`${clientPreviewUrl}/news/preview/${n.slug}`, '_blank')
  }
  const viewNews = (n: any) => {
    let clientPreviewUrl =
      // @ts-ignore
      urlBasedOnEnv[process.env.REACT_APP_NODE_ENV]
    window.open(`${clientPreviewUrl}/news/${n.slug}`, '_blank')
  }
  return (
    <>
      <div>
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '0.6rem',
                }}
              >
                <Link to="/">Dashboard</Link>
              </li>
              <li
                className="breadcrumb-item active"
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '0.6rem',
                }}
              >
                View/ Edit/ Delete News
              </li>
            </ol>

            <div className="card mb-3">
              <div className="card-body">
                <div className="table-responsive">
                  <FormGroup className="search-by-wrap">
                    <label
                      className="me-sm-2 mb-0 text-nowrap"
                      htmlFor="serachProjectName"
                    >
                      Search by Title
                    </label>
                    <SearchInput
                      onClick={getNewsByTitleForView}
                      value={searchNewsByTitle}
                      onChange={(e) => setSearchNewsByTitle(e.target.value)}
                    // loading={userLoading}
                    />
                  </FormGroup>
                  <table
                    className="table table-bordered text-center"

                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Entered by</th>
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>View</th>
                        <th>Sent Email to</th>
                      </tr>
                    </thead>
                    <tbody>
                      {news.map((fetchedNews, index: number) =>
                        fetchedNews.id ? (
                          <tr key={index}>
                            <td>
                              <b>{index + 1}.</b>
                            </td>
                            <td>{fetchedNews.title}</td>
                            <td>{fetchedNews.status}</td>
                            <td>{getEnteredByName(fetchedNews.newsEnteredBy)}</td>
                            <td
                              onClick={() => {
                                openModalForNews(fetchedNews, 'edit')
                              }}
                              data-toggle="modal"
                              data-target="#newsModalCenter"
                            >
                              <UpdateIcon style={{ padding: '0px' }} />
                            </td>

                            <td
                              onClick={() => {
                                openModalForNews(fetchedNews, 'delete')
                              }}
                              data-toggle="modal"
                              data-target="#newsModalCenter"
                            >
                              <DeleteIcon style={{ padding: '0px' }} />
                            </td>
                            <td
                              onClick={() => {
                                openModalForNews(fetchedNews, 'view')
                              }}
                              data-toggle="modal"
                              data-target="#newsModalCenter"
                            >
                              <VisibilityIcon style={{ padding: '0px' }} />
                            </td>
                            <td>
                              {fetchedNews.sentTo?.subscribers ? (
                                'Sent to Subscribers'
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  disabled={isSentToLoadingFor.subscriber}
                                  onClick={() => sentTo(fetchedNews.id, false)}
                                >
                                  Send to Subscribers
                                </button>
                              )}
                              {/* <input
                              type="checkbox"
                              name="sentToSubscribers"
                              id=""
                              disabled={fetchedNews.sentTo?.subscribers}
                              checked={fetchedNews.sentTo?.subscribers}
                              onClick={() => sentTo(fetchedNews.id, false)}
                            /> */}
                              <br />
                              <br />

                              {fetchedNews.sentTo?.members ? (
                                'Sent to Members'
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  disabled={isSentToLoadingFor.member}
                                  onClick={() => sentTo(fetchedNews.id, true)}
                                >
                                  Send to Members
                                </button>
                              )}
                              {/* <label htmlFor="sentToMembers">Members</label>
                            <input
                              type="checkbox"
                              name=""
                              id="sentToMembers"
                              disabled={fetchedNews.sentTo?.members}
                              checked={fetchedNews.sentTo?.members}
                              onClick={() => sentTo(fetchedNews.id, true)}
                            /> */}
                            </td>
                          </tr>
                        ) : null,
                      )}
                    </tbody>

                  </table>
                  {modalType && selectedNews && (
                    <Modal
                      className="modal-xl"
                      isOpen={modalType !== null}
                      toggle={closeModalForNew}
                      centered
                      scrollable
                    // onClosed={() => {
                    //   setSelectedNews(null)
                    //   setUpdatedNews(null)
                    //   setErrorMessage('')
                    // }}
                    >
                      <ModalHeader toggle={closeModalForNew}>
                        {modalType === 'edit'
                          ? 'Edit News'
                          : modalType === 'delete'
                            ? 'Delete News'
                            : 'View News'}
                      </ModalHeader>
                      <ModalBody>
                        {saveMessage && (
                          <div className="save-message">{saveMessage}</div>
                        )}
                        {modalType === 'view' && (
                          <div>
                            <p>
                              <strong>Title:</strong> {selectedNews.title}
                            </p>
                            <p>
                              <strong>Slug:</strong> {selectedNews.slug}
                            </p>
                            <p>
                              <strong>Meta Title:</strong>{' '}
                              {selectedNews.metaTitle}
                            </p>
                            <p>
                              <strong>Meta Description:</strong>{' '}
                              {selectedNews.metaDescription}
                            </p>
                            {selectedNews.img && (
                              <img alt="News"
                                // @t
                                // src={selectedNews.img} 
                                src={`${AWS_FILE_VIEW_URI}/${selectedNews?.img}`}
                                style={{ width: '100%' }} />
                            )}
                            <p>
                              <strong>Article:</strong>
                              {/* {selectedNews.article} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: selectedNews.article,
                                }}
                              />
                            </p>

                            <ModalFooter>
                              <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                  closeModalForNew()
                                  setErrorMessage('')
                                }}
                                disabled={isLoading}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => {
                                  viewNews(selectedNews)
                                }}
                              >
                                Pre-view
                              </button>

                              {/* <a
                                href={`https://dev.user.quantum2.io/news/${selectedNews.slug}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    viewNews(selectedNews)
                                  }}
                                >
                                  View News In User Portal
                                </button>
                              </a> */}
                            </ModalFooter>
                          </div>
                        )}

                        {modalType === 'edit' && updatedNews && (
                          <div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group mb-3">
                                  <label>Id</label>
                                  <p>{updatedNews.id}</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group mb-3">
                                  <label>Upload New Image</label>
                                  <input
                                    type="file"
                                    name="img"
                                    className="form-control"
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              {/* <div className="col-lg-4">
                                <div className="form-group mb-3">
                                  <label htmlFor=""></label>
                                  {updatedNews.img && (
                                    <button
                                      type="button"
                                      className="form-control btn_yellow"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        setViewNewsImageForView(
                                          // @ts-ignore
                                          updatedNews.img,
                                        )
                                        setModalOpenForNewUpload(true)
                                      }}
                                    >
                                      View News Image
                                    </button>
                                  )}
                                </div>
                                {viewNewsImageForView && (
                                  <ModalViewFile
                                    isOpen={modalOpenForNewUpload}
                                    closeModal={() =>
                                      setModalOpenForNewUpload(false)
                                    }
                                    fileUrl={URL.createObjectURL(
                                      viewNewsImageForView,
                                    )}
                                  />
                                )}
                              </div> */}

                              <div className="col-md-4">
                                <div className="form-group mb-3">
                                  <label>Uploaded Image </label>
                                  <button
                                    className="form-control"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      toggle_doc_modal()
                                    }}
                                  >
                                    View Image
                                  </button>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label>Title</label>
                                  <textarea
                                    name="title"
                                    className="form-control"
                                    value={updatedNews.title}
                                    // onChange={handleInputChange}
                                    onChange={(e) => {
                                      handleInputChange(e)
                                      setUpdatedNews((prevProject) => ({
                                        ...prevProject!,
                                        slug: stringToSlug(e.target.value),
                                      }))
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label>WebLink/Slug</label>
                                  <input
                                    type="text"
                                    name="slug"
                                    className="form-control"
                                    value={updatedNews.slug}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label>Meta Title</label>
                                  <textarea
                                    name="metaTitle"
                                    className="form-control"
                                    value={updatedNews.metaTitle}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label>Meta Description</label>
                                  <textarea
                                    name="metaDescription"
                                    className="form-control"
                                    value={updatedNews.metaDescription}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label>Category</label>
                                  <select name="newsCategoryId" id="newsCategoryId"
                                    className="form-control"
                                    //@ts-ignore
                                    onChange={handleInputChange}
                                    value={updatedNews.newsCategoryId}
                                  >
                                    {
                                      categoryList.map(c => <option value={c._id}>
                                        {c.name}
                                      </option>)
                                    }
                                  </select>


                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label>Article</label>
                                  {/* <textarea
                                    name="article"
                                    className="form-control"
                                    value={updatedNews.article}
                                    onChange={handleInputChange}
                                  /> */}
                                  <div className="text-editor-wrapper">
                                    {imageLoading && <LoadingBlock />}

                                    <CustomReactQuill
                                      ref={quillRef}
                                      className={'static-text-content-page'}
                                      style={{
                                        height: 500,
                                        marginBottom: 60,
                                      }}
                                      theme="snow"
                                      value={updatedNews.article}
                                      formats={formats}
                                      modules={modules}
                                      // onChange={(v) => field.onChange(v)}
                                      onChange={(v: any) => {
                                        setUpdatedNews((prevProject) => ({
                                          ...prevProject!,
                                          article: v,
                                        }))
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <ModalFooter>
                              <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                  closeModalForNew()

                                  setErrorMessage('')
                                }}
                                disabled={isLoading}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => {
                                  handleUpdateForSaveOrPublishNews(
                                    NewsStatus.Saved,
                                    (n) => {
                                      previewNews(n)
                                    },
                                  )
                                }}
                              >
                                Pre-view
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary"
                                disabled={isLoadingSave}
                                onClick={() => {
                                  handleUpdateForSaveOrPublishNews(
                                    NewsStatus.Saved,
                                  )
                                }}
                              >
                                Save{' '}
                                {isLoadingSave && (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                )}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary"
                                disabled={isLoadingPublish}
                                onClick={() => {
                                  handleUpdateForSaveOrPublishNews(
                                    NewsStatus.Published,
                                  )
                                }}
                              >
                                Publish{' '}
                                {isLoadingPublish && (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                )}
                              </button>
                            </ModalFooter>
                          </div>
                        )}
                        {modalType === 'delete' && (
                          <div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label>Id</label>
                                  <textarea
                                    className="form-control"
                                    value={selectedNews.id}
                                    disabled={true}
                                  />
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label>Title</label>
                                  <textarea
                                    className="form-control"
                                    value={selectedNews.title}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label>WebLink/Slug</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={selectedNews.slug}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label>Meta Title</label>
                                  <textarea
                                    className="form-control"
                                    value={selectedNews.metaTitle}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label>Meta Description</label>
                                  <textarea
                                    className="form-control"
                                    value={selectedNews.metaDescription}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label>Article</label>
                                  <textarea
                                    className="form-control"
                                    value={selectedNews.article}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <ModalFooter>
                              <button
                                className="btn btn-secondary"
                                onClick={closeModalForNew}
                              >
                                Close
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={handleDeleteNews}
                              >
                                Delete
                              </button>
                            </ModalFooter>
                          </div>
                        )}
                      </ModalBody>
                    </Modal>
                  )}

                  <Modal
                    className="modal-lg"
                    isOpen={doc_modal}
                    toggle={toggle_doc_modal}
                    centered
                  >
                    <ModalHeader toggle={toggle_doc_modal}>
                      <span
                        style={{
                          cursor: 'pointer',
                          color: '#004dda',
                        }}
                        onClick={handleDownload}
                      >
                        Download File
                      </span>
                    </ModalHeader>
                    <ModalBody>
                      <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                          className="embed-responsive"
                          src={`${AWS_FILE_VIEW_URI}/${updatedNews?.img}`}
                        />
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <QButton color="primary" onClick={toggle_doc_modal}>
                        Close
                      </QButton>{' '}
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteUpdateViewNews
