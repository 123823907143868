import {
  CurrencyType,
  CurrencyUnitStatistics,
  OrderStatus,
  OrderType,
  PaymentMethodEnum,
  UnitType,
  UserType,
} from '../types'
import * as Yup from 'yup'
import { checkLandAvailableUnits } from '../utilities'

export const defaultValidationSchema = Yup.object({}) // Empty schema as fallback

export function buyOrderValidationSchema(
  currencyUnitStatiticsByUnitType: Record<UnitType, CurrencyUnitStatistics>,
  userPurchaseLimitInCents: number,
  pendingBuyLandByUnitType: Record<UnitType, number>,
  userType: UserType,
) {
  return Yup.object({
    currencyUnit: Yup.object().shape({
      numberOfUnits: Yup.number().positive().min(1).required('Required'),
      unitType: Yup.mixed()
        .oneOf(Object.values(UnitType))
        .required('Unit type is required'),
      pricePerUnit: Yup.number().min(
        100,
        'Price per unit must be greater than or equal to 100',
      ),
      buyOrderTransactionFeeInCents: Yup.number().min(
        0,
        'Transaction fee must be non-negative',
      ),
    }),
    paymentDetails: Yup.object().shape({
      paymentMethod: Yup.mixed().when([], {
        is: () => userType === UserType.BUSINESS,
        then: Yup.mixed()
          .oneOf(
            [PaymentMethodEnum.Bank],
            'For business, the payment method must be Bank',
          )
          .required('Payment method is required'),
        otherwise: Yup.mixed()
          .notOneOf([PaymentMethodEnum.Crypto], 'Invalid payment method')
          .required('Payment method is required'),
      }),
      paymentAmount: Yup.number().min(0, 'Payment amount must be non-negative'),
      currency: Yup.string()
        .oneOf(Object.values(CurrencyType), 'Invalid currency type')
        .required('Currency is required'),
      subTotal: Yup.number()
        .min(0, 'Subtotal must be non-negative')
        .test(
          'check available units ',
          'Exceeds maximum  for buying units',
          function (value) {
            const { pricePerUnit, unitType, numberOfUnits, isDoubleLand } =
              //@ts-ignore
              this.from[1].value.currencyUnit
            const { currency } =
              //@ts-ignore
              this.from[1].value.paymentDetails
            if (value && pricePerUnit > 0 && numberOfUnits > 0) {
              const landStats =
                currencyUnitStatiticsByUnitType[unitType as UnitType]
              const pendingBuyLand =
                pendingBuyLandByUnitType[unitType as UnitType]
              const { message } = checkLandAvailableUnits(
                unitType,
                isDoubleLand,
                numberOfUnits,
                pricePerUnit,
                landStats,
                userPurchaseLimitInCents,
                pendingBuyLand,
                currency,
              )

              if (message != '') {
                return this.createError({
                  path: 'paymentDetails.subTotal',
                  message,
                })
              }
            }
            return true
          },
        ),
      transactionFee: Yup.number().min(
        0,
        'Transaction fee must be non-negative',
      ),
    }),
    referenceCode: Yup.string()
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Reference code must only contain alpha-numeric characters',
      )
      .notRequired(),
    orderType: Yup.string().oneOf(
      [OrderType.BUY],
      'Order Type must be an buy order',
    ),
    orderStatus: Yup.string().oneOf(
      [OrderStatus.Initiated],
      "Order status must be 'Initiated'",
    ),
    buy: Yup.object().shape({
      comments: Yup.string()
        .max(200, 'Comments must be 100 characters or less')
        .notRequired(),
    }),
  })
}
