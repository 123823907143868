import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import Select, { SingleValue } from 'react-select'
import { Link, useNavigate } from 'react-router-dom'
import { HasToolTipLabel } from '../../component/misc/smallComponents'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import { FormErrorMessage } from '../../component'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { useForm } from 'react-hook-form'
import {
  CurrencyDisplaySymbol,
  ExternalFees_Processors,
  OrderCreate,
  OrderType,
  PaymentMethodEnum,
  TransactionTabType,
  UnitDisplayNames,
  UnitDisplaySymbol,
  UnitType,
  User,
  UserType,
  User_Analytics,
  ValueByUnitType,
  unitTypeToCurrencyMap,
  UnitPriceDetailsByUnitType,
  Discount,
  CurrencyUnitStatisticsByUnitType,
  OrderCreated,
  Invoice_CompanyArea,
  Invoice_BankAccountOption,
  Invoice_Q2OfficeLocation,
  EmailTemplateRenderedData,
} from '../../utils/types'
import {
  DEFAULT_MINIMUM_SUBTOTAL_IN_CENTS,
  ONE_AUD_TO_CENTS,
  ONE_PERCENT,
  emptyAxiosError,
  formatNumberWithCommas,
  formatNumberWithCommasUptoTwoDecimalWithCeil,
  getInitialBuyOrSellOrTransferOrderValues,
  roundToSixDecimals,
} from '../../utils/utilities'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  buyOrderValidationSchema,
  defaultValidationSchema,
} from '../../utils/validation/order.validation'
import { AxiosError } from 'axios'
import OrderModalPreConfirmation from '../../component/orders/OrderModalPreConfirmation'
import { useServerError } from '../../hooks/useServerError'
import { validateDiscount } from '../../utils/validation/buy_discount.validation'
import {
  getReferenceCodeData,
  getUserByEmail,
  getCurrencyUnitStatisticsAndExternalFee,
  getUserOrBusinessListByNameOrEmail,
  createInvoiceOrder,
  renderedInvoice,
} from '../../api'
import { debounce } from 'lodash'
import { toast } from 'react-toastify'
import LoadingBlock from '../../component/DesignSystem/Core/Common/LoadingBlock/LoadingBlock'
type OrderCreateFormValues = OrderCreate & {
  email: string
  name: string
  userId: string
  q2Area: Invoice_CompanyArea.US
  q2BankOption: Invoice_BankAccountOption.WiseUK
  q2OfficeLocation: Invoice_Q2OfficeLocation.UK
  additionalInfo: string
}
const accountTypeOptions = [
  { value: UserType.USER, label: 'User' },
  { value: UserType.BUSINESS, label: 'Business' },
]
export default function CreateInvoice() {
  const [previewModalLoading, setPreviewModalLoading] = useState(false)
  const [previewModal, setPreviewModal] = useState(false)
  const [userData, setuserData] = useState<User | null>(null)

  const togglePreviewModal = () => {
    setPreviewModal(!previewModal)
  }
  const previewEmailInModalIframe = async () => {
    if (await triggerBuyOrder()) {
      togglePreviewModal()
      renderInvoiceTemplate()
    }
  }

  const renderInvoiceTemplate = async () => {
    const values = getValues()
    let props = {
      user: userData,
      orderId: 'N/A',
      paymentDetails: values.paymentDetails,
      currencyUnit: values.currencyUnit,

      otherInfo: {
        q2Area: values.q2Area,
        q2BankOption: values.q2BankOption,
        q2OfficeLocation: values.q2OfficeLocation,
        additionalInfo: values.additionalInfo,
      },
    }
    setPreviewModalLoading(true)
    try {
      let res = await renderedInvoice(props)
      if (res) {
        setPreviewModalLoading(false)
        setrenderedHTML(res.data)
      }
    } catch (error) {
      console.error('Error rendering dynamic email:', error)
      setPreviewModalLoading(false)
      return
    }
  }

  const [renderedHTML, setrenderedHTML] = useState<EmailTemplateRenderedData>({
    html: '',
    text: '',
  })

  const navigate = useNavigate()
  const [transactionType, settransactionType] = useState<TransactionTabType>(
    TransactionTabType.buy,
  ) // buy/sell/transfer
  const { serverError, handleServerError } = useServerError()
  const [isModalOpenBuyOrder, setIsModalOpenBuyOrder] = useState(false)
  const [isDiscountApplied, setIsDiscountApplied] = useState(false)
  const [discountError, setDiscountError] = useState('')
  const [discountPaymentError, setDiscountPaymentError] = useState('')
  const [minPurchasePaymentError, setMinPurchasePaymentError] = useState('')
  const [isLoadingBuyOrder, setIsLoadingBuyOrder] = useState(false)

  const [initialReferenceCode, setInitialReferenceCode] = useState('')
  const [user, setUser] = useState<User | null>(null)
  const [users, setUsers] = useState<User[] | null>(null)
  const [searchTermBy, setSearchTermBy] = useState<{
    name: string
    email: string
    businessName: string
  }>({
    name: '',
    email: '',
    businessName: '',
  })

  const [successMessage, setSuccessMessage] = useState('')
  const [successOrder, setSuccessOrder] = useState<OrderCreated>(
    {} as OrderCreated,
  )

  const [unitPrices, setUnitPrices] =
    useState<UnitPriceDetailsByUnitType | null>(null)
  const [externalFees_Processors, setExternalFees_Processors] =
    useState<ExternalFees_Processors | null>(null)
  const [currencyUnitStatiticsByUnitType, setCurrencyUnitStatiticsByUnitType] =
    useState<CurrencyUnitStatisticsByUnitType | null>(null)
  const [userOptions, setUserOptions] = useState<
    { value: string; name: string; email: string; businessName: string }[]
  >([])

  const [loading, setLoading] = useState(false)

  const [pendingBuyLandByUnitType, setPendingBuyLandByUnitType] =
    useState<ValueByUnitType>({} as ValueByUnitType)
  const [userAnalytics, setUserAnalytics] = useState<User_Analytics[] | null>(
    null,
  )
  const [userType, setUserType] = useState<UserType>(UserType.USER)
  const [purchaseLimitInCents, setPurchaseLimitInCents] = useState(0)

  // Dynamically get initial values when userType changes
  const buyOrderInitialValues = useMemo(
    () => getInitialBuyOrSellOrTransferOrderValues(OrderType.BUY, userType),
    [userType], // Recalculate when userType changes
  )

  const buyOrderValidation = useMemo(
    () =>
      user && currencyUnitStatiticsByUnitType
        ? buyOrderValidationSchema(
            currencyUnitStatiticsByUnitType,
            purchaseLimitInCents,
            pendingBuyLandByUnitType,
            userType,
          )
        : defaultValidationSchema,
    [
      user,
      currencyUnitStatiticsByUnitType,
      purchaseLimitInCents,
      pendingBuyLandByUnitType,
      userType,
    ],
  )

  const {
    register: registerBuyOrder,
    handleSubmit: handleSubmitBuyOrder,
    formState: { errors: errorsBuyOrder },
    watch: watchBuyOrder,
    setValue: setValueBuyOrder,
    trigger: triggerBuyOrder,
    getValues,
    reset,
  } = useForm<OrderCreateFormValues>({
    defaultValues: buyOrderInitialValues,
    resolver: yupResolver(buyOrderValidation || defaultValidationSchema), // Use fallback schema
  })

  useEffect(() => {
    reset(buyOrderInitialValues) // This updates form values when `userType` changes
  }, [userType, reset, buyOrderInitialValues])

  const toggleModal = () => setIsModalOpenBuyOrder((prev) => !prev)

  const getUnitPrice = async (
    orderType: OrderType,
    unitType: UnitType,
    paymentMethod?: PaymentMethodEnum,
  ) => {
    try {
      //const buyCurrencyPricing = await attemptGetPrices(UnitType);
      if (unitPrices && externalFees_Processors && user) {
        const { pricePerUnit, buyOrderTransactionFeeInCents } =
          unitPrices[unitType]
        const { fixedPercentageFees, fixedCentsFees } =
          externalFees_Processors[paymentMethod!]
        setValueBuyOrder(
          'paymentDetails.paymentMethodFees.fixedCentsFees',
          fixedCentsFees,
        )
        setValueBuyOrder(
          'paymentDetails.paymentMethodFees.fixedPercentageFees',
          fixedPercentageFees,
        )
        setValueBuyOrder(
          'paymentDetails.currency',
          unitTypeToCurrencyMap[unitType],
        )
        setValueBuyOrder('currencyUnit.pricePerUnit', pricePerUnit)
        setValueBuyOrder(
          'currencyUnit.buyOrderTransactionFeeInCents',
          buyOrderTransactionFeeInCents,
        )
        return true
      } else {
        // Handle the case where price case is different other than land/eAUD
        return
      }
    } catch (error) {
      handleServerError(error as AxiosError<{ message: string }, any>)
      return
    }
  }

  const {
    email,
    buy: buyOrderData,
    paymentDetails: buyOrderPaymentDetails,
    currencyUnit: buyOrderCurrencyUnit,
  } = watchBuyOrder()

  const { paymentMethod: paymentMethodBuyOrder } = buyOrderPaymentDetails

  const {
    numberOfUnits: numberOfUnitsBuyOrder,
    unitType: unitTypeBuyOrder,
    pricePerUnit: pricePerUnitBuyOrder,
  } = buyOrderCurrencyUnit

  // For setting unit prices and whole land
  useEffect(() => {
    getCurrencyUnitStatisticsAndExternalFee().then(({ data }) => {
      setCurrencyUnitStatiticsByUnitType(data.currencyUnitStats)
      setExternalFees_Processors(data.externalFees_Processors)
      setUnitPrices(data.prices)
    })
  }, [])

  // For Buying UNITS
  useEffect(() => {
    // Define an async function within useEffect
    async function fetchPriceBuyOrder() {
      const fetchedUnitPriceAndTransactionFee = await getUnitPrice(
        OrderType.BUY,
        unitTypeBuyOrder,
        paymentMethodBuyOrder,
      )

      // // calculating number of units for bussiness
      // if (user.userType === UserType.BUSINESS) {
      //     calculateUnitsForBussiness(buyBusinessValueInCents);
      // }
      const {
        currencyUnit: {
          numberOfUnits,
          pricePerUnit,
          buyOrderTransactionFeeInCents = 0,
        },
        orderType,
        paymentDetails: { paymentMethod, currency, paymentMethodFees },
      } = watchBuyOrder()
      if (orderType !== OrderType.BUY) return
      if (fetchedUnitPriceAndTransactionFee) {
        if (numberOfUnits > 0 && pricePerUnit > 0 && buyOrderData) {
          // Updated discount value with payment details
          // Now setting payment details subtract discount from subTotal
          let totalTransactionFee = 0
          const purchasePrice = numberOfUnits * pricePerUnit
          const { minPaymentAmountInCents } = buyOrderData.discount

          const { error, discountAmount, isDoubleLand } = validateDiscount(
            purchasePrice,
            pricePerUnit,
            buyOrderData.discount,
            currency,
          )
          setDiscountPaymentError(error)

          if (userType === UserType.BUSINESS) {
            const requiredMinPayment = minPaymentAmountInCents
            if (purchasePrice < requiredMinPayment) {
              setMinPurchasePaymentError(
                `You must purchase a minimum of ${requiredMinPayment / ONE_AUD_TO_CENTS} ${UnitDisplaySymbol[unitTypeBuyOrder]}`,
              )
            } else {
              setMinPurchasePaymentError('')
            }
          }

          const subtotal = purchasePrice - discountAmount

          const totalPriceWithoutExternalFees =
            subtotal + buyOrderTransactionFeeInCents

          const { fixedCentsFees, fixedPercentageFees } = paymentMethodFees
          const externalFee =
            (totalPriceWithoutExternalFees * fixedPercentageFees) /
              ONE_PERCENT +
            fixedCentsFees
          totalTransactionFee = buyOrderTransactionFeeInCents + externalFee

          setValueBuyOrder('currencyUnit.isDoubleLand', isDoubleLand)

          setValueBuyOrder('paymentDetails.transactionFee', totalTransactionFee)

          const paymentAmount = subtotal + totalTransactionFee
          setValueBuyOrder('paymentDetails.subTotal', subtotal)
          setValueBuyOrder('paymentDetails.paymentAmount', paymentAmount)
          if (isDoubleLand) {
            setValueBuyOrder('buy.discount.discountValue', subtotal)
          } else {
            setValueBuyOrder('buy.discount.discountValue', discountAmount)
          }
        } else {
          setValueBuyOrder('paymentDetails.paymentAmount', 0)
          setValueBuyOrder('paymentDetails.subTotal', 0)
          setValueBuyOrder('buy.discount.discountValue', 0)
        }
        triggerBuyOrder('paymentDetails.subTotal')
        return
      }
      setValueBuyOrder('currencyUnit.pricePerUnit', 0)
      setValueBuyOrder('paymentDetails.paymentAmount', 0)
      setValueBuyOrder('paymentDetails.subTotal', 0)
      setValueBuyOrder('buy.discount.discountValue', 0)

      setValueBuyOrder('paymentDetails.transactionFee', 0)
    }

    // Call the async function
    fetchPriceBuyOrder()
  }, [
    unitTypeBuyOrder,
    numberOfUnitsBuyOrder,
    paymentMethodBuyOrder,
    buyOrderData?.discount,
  ])

  const handleBuyOrder = async () =>
    // values: OrderCreateFormValues
    {
      const values = getValues()
      handleServerError(emptyAxiosError)

      setIsModalOpenBuyOrder(false)
      setIsLoadingBuyOrder(true)
      setSuccessMessage('')
      if (!buyOrderTotalPrice || !pricePerUnitBuyOrder) {
        setIsLoadingBuyOrder(false)
        return
      }

      const {
        currencyUnit: { isDoubleLand, numberOfUnits },
      } = values
      // Create a copy of values to avoid mutation
      const updateBuyOrderValues = {
        ...values,
        currencyUnit: {
          ...values.currencyUnit,
          // Apply multiplier once without modifying original values
          numberOfUnits: isDoubleLand ? numberOfUnits * 2 : numberOfUnits,
        },
      }
      createInvoiceOrder(updateBuyOrderValues)
        .then(({ data: { message, invoiceSucess } }) => {
          toast.success(message)
          setSuccessMessage(message)
          setSuccessOrder(invoiceSucess)
          previewEmailInModalIframe()
        })
        .catch((error: any) => {
          handleServerError(error)
        })
        .finally(() => {
          setIsLoadingBuyOrder(false)
          previewEmailInModalIframe()
        })
    }

  const removeDiscount = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    handleServerError(emptyAxiosError)
    const discount = {
      isPercent: false,
      amount: 0,
      discountValue: 0,
      minPaymentAmountInCents: 0,
    } as Discount
    setValueBuyOrder('buy.discount', discount)
    setValueBuyOrder('buy.referenceCode', '')
    setInitialReferenceCode('')
    setDiscountError('')
    setDiscountPaymentError('')
    setIsDiscountApplied(false)
  }

  const applyDiscount = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setDiscountError('')
    handleServerError(emptyAxiosError)
    if (!user) {
      alert('select user first')
      return
    }

    const { numberOfUnits, unitType } = buyOrderCurrencyUnit
    const { paymentMethod } = buyOrderPaymentDetails
    if (!Object.values(UnitType).includes(unitType) || numberOfUnits <= 0) {
      alert('please enter all details')
      return
    }
    const referenceCode = buyOrderData?.referenceCode
    if (!referenceCode) return
    let discount = {} as Discount
    setInitialReferenceCode(referenceCode)
    try {
      discount = (await getReferenceCodeData(referenceCode as string, user._id))
        .data.discount
      setIsDiscountApplied(true)
    } catch (error: any) {
      discount = { isPercent: false, amount: 0, discountValue: 0 } as Discount
      handleServerError(error as AxiosError<{ message: string }, any>)
      setDiscountError(error.response?.data.message)
      setIsDiscountApplied(false)
    }
    setValueBuyOrder('buy.discount', discount)
  }

  const fetchUserDetails = async (email: string, signal: AbortSignal) => {
    handleServerError(emptyAxiosError)
    try {
      const { userAnalytics, user } = (await getUserByEmail(email, { signal }))
        .data
      if (!userAnalytics) {
        setUserAnalytics(null)
        setPendingBuyLandByUnitType({} as ValueByUnitType)
        setPurchaseLimitInCents(0)
        return
      }

      const updatedPendingBuyLandByUnitType = userAnalytics.reduce(
        (acc, value) => ({ ...acc, [value.unitType]: value.pendingBuy }),
        {} as ValueByUnitType,
      )
      setUserAnalytics(userAnalytics)
      setPurchaseLimitInCents(user.purchaseLimitInCents)
      setValueBuyOrder('userId', user._id)
      setPendingBuyLandByUnitType(updatedPendingBuyLandByUnitType)
      setuserData(user)
    } catch (error: any) {
      handleServerError(error)
      setUserAnalytics(null)
      setPendingBuyLandByUnitType({} as ValueByUnitType)
      setPurchaseLimitInCents(0)
    }
  }
  // Account Type Options for React Select

  // Handle Account Type Change
  const handleAccountTypeChange = (
    selectedOption: SingleValue<{ value: UserType; label: string }>,
  ) => {
    if (!selectedOption) return
    if (selectedOption) {
      setUser(null)
      setUsers([])
      setUserType(selectedOption.value) // Update state
      setSearchTermBy({ name: '', email: '', businessName: '' })
    }
  }

  // 🔹 Fetch user list dynamically
  const fetchUserDetailsByNameOrEmail = async (
    searchTerm: string,
    signal: AbortSignal,
  ) => {
    handleServerError(emptyAxiosError)
    try {
      const { users } = (
        await getUserOrBusinessListByNameOrEmail(searchTerm, userType, false, {
          signal,
        })
      ).data
      setUsers(users)
    } catch (error: any) {
      handleServerError(error)
    }
  }

  // 🔹 Update userOptions dynamically when users change
  useEffect(() => {
    if (!users) {
      setUserOptions([])
      // setUser(null)
      return
    }

    const newOptions = users.map((user) => ({
      value: user._id,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      businessName: user.kycInfo?.businessInfo?.businessName || '',
    }))

    setUserOptions(newOptions)
  }, [users])

  useEffect(() => {
    if (!user) return
    const abortController = new AbortController()
    const signal = abortController.signal

    fetchUserDetails(user.email, signal)
    return () => abortController.abort()
  }, [user])

  // 🔹 Helper function to get user details
  const getName = (selectedUser: User) => ({
    userName: selectedUser
      ? `${selectedUser.firstName} ${selectedUser.lastName}`
      : '',
    companyName: selectedUser?.kycInfo?.businessInfo?.businessName || '',
    email: selectedUser?.email || '',
  })

  // 🔹 Handle input changes dynamically
  const handleInputChange = (
    field: keyof typeof searchTermBy,
    value: string,
  ) => {
    setUser(null)
    setSearchTermBy((prev) => ({ ...prev, [field]: value }))
    debouncedFetchUsers(value) // ✅ Call debounced function
  }

  const handleUserSelect = (
    field: keyof typeof searchTermBy,
    selectedOption: any,
  ) => {
    if (!selectedOption) {
      setUsers([])
      setUser(null)
      setSearchTermBy({
        name: '',
        email: '',
        businessName: '',
      })
      return
    }
    const selectedUser = users?.find((u) => u._id === selectedOption.value)
    if (selectedUser) {
      setUser(selectedUser)
      const { userName, companyName, email } = getName(selectedUser)
      // const userName = `${selectedUser.firstName} ${selectedUser.lastName}`
      // const email = selectedUser.email
      // const businessName =
      //   selectedUser.kycInfo?.businessInfo?.businessName || ''
      setSearchTermBy({
        name: userName,
        email: email,
        businessName: companyName,
      })
    }
  }

  const abortControllerRef = useRef<AbortController | null>(null) // ✅ Persistent ref

  const debouncedFetchUsers = useMemo(() => {
    return debounce(async (searchTerm: string) => {
      const searchTermAfterTrim = searchTerm.trim()
      if (searchTermAfterTrim.length < 2) {
        setUsers([])
        return
      }

      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }

      abortControllerRef.current = new AbortController()
      const signal = abortControllerRef.current.signal

      setLoading(true)
      await fetchUserDetailsByNameOrEmail(searchTerm, signal)
      setLoading(false)
    }, 300)
  }, [fetchUserDetailsByNameOrEmail])

  useEffect(() => {
    return () => {
      debouncedFetchUsers.cancel()
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    }
  }, [])

  const buyOrderPurchasePrice = buyOrderPaymentDetails.subTotal
  const buyOrderTotalPrice = buyOrderPaymentDetails.paymentAmount
  const buyOrderTransactionFee = buyOrderPaymentDetails.transactionFee
  const isBuyOrderFormValid = Object.keys(errorsBuyOrder).length === 0
  return (
    <div className="card-ui-pages-content-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Create Invoice</li>
            </ol>
            <div className="">
              <div className="">
                <div className="col-lg-12 new-tabs-system">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        href="#" // Add valid href to prevent empty selector
                        className={
                          transactionType === TransactionTabType.buy
                            ? 'active'
                            : ''
                        }
                        onClick={() =>
                          settransactionType(TransactionTabType.buy)
                        }
                      >
                        <span className="d-block-desktop-only">
                          Create Invoice
                        </span>
                        <span className="d-block-mobile-only">
                          Create Invoice
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={transactionType}>
                    <TabPane tabId={TransactionTabType.buy}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="inner-form-wrapper">
                            <form
                              className="row"
                              onSubmit={handleSubmitBuyOrder(handleBuyOrder)}
                            >
                              {/* Account Type Selection with react-select */}
                              <div className="form-group">
                                <HasToolTipLabel
                                  labelText="Account Type"
                                  htmlFor="accountType"
                                  id="account_type"
                                  txt="Select Account type from the drop down menu to create invoice"
                                />
                                <Select
                                  id="accountType"
                                  options={accountTypeOptions}
                                  value={accountTypeOptions.find(
                                    (option) => option.value === userType,
                                  )}
                                  onChange={handleAccountTypeChange}
                                  placeholder="Select Account Type"
                                />
                              </div>
                              <div className="form-group">
                                <HasToolTipLabel
                                  labelText="Name"
                                  htmlFor="user.name"
                                  id="user_name"
                                  txt="Enter First name or Last name"
                                />

                                <Select
                                  id="user_name"
                                  placeholder="Enter User Name"
                                  isLoading={loading}
                                  options={userOptions.map(
                                    ({ value, name }) => ({
                                      value,
                                      label: name,
                                    }),
                                  )}
                                  inputValue={searchTermBy.name}
                                  onInputChange={(value, actionMeta) => {
                                    if (
                                      actionMeta.action !== 'input-blur' &&
                                      actionMeta.action !== 'menu-close'
                                    ) {
                                      handleInputChange('name', value)
                                    }
                                  }}
                                  onChange={(option, action) => {
                                    handleUserSelect('name', option)
                                  }} // Removed field parameter
                                  isClearable
                                />
                              </div>
                              {userType === UserType.BUSINESS && (
                                <div className="form-group">
                                  <HasToolTipLabel
                                    labelText="Company Name"
                                    htmlFor="business.name"
                                    id="business_name"
                                    txt="Enter Business name that business has registered with"
                                  />
                                  {/* 🔹 Business Name Selection */}
                                  <Select
                                    id="business.name"
                                    placeholder="Enter Business Name"
                                    isLoading={loading}
                                    options={userOptions
                                      .filter(
                                        ({ businessName }) => businessName,
                                      ) // ✅ Filters only business names
                                      .map(({ value, businessName }) => ({
                                        value,
                                        label: businessName!,
                                      }))} // ✅ Extracts business names
                                    inputValue={searchTermBy.businessName}
                                    onInputChange={(value, actionMeta) => {
                                      if (
                                        actionMeta.action !== 'input-blur' &&
                                        actionMeta.action !== 'menu-close'
                                      ) {
                                        handleInputChange('businessName', value)
                                      }
                                    }}
                                    onChange={(option) =>
                                      handleUserSelect('businessName', option)
                                    }
                                    isClearable
                                  />
                                </div>
                              )}
                              <div className="form-group">
                                <HasToolTipLabel
                                  labelText="Email"
                                  htmlFor="user.email"
                                  id="user_email"
                                  txt="Enter Email address that User/Business has registered with"
                                />
                                <>
                                  <Select
                                    id="user.email"
                                    placeholder="Enter Email"
                                    isLoading={loading}
                                    options={userOptions.map(
                                      ({ value, email }) => ({
                                        value,
                                        label: email,
                                      }),
                                    )} // ✅ Only emails
                                    inputValue={searchTermBy.email}
                                    onInputChange={(value, actionMeta) => {
                                      if (
                                        actionMeta.action !== 'input-blur' &&
                                        actionMeta.action !== 'menu-close'
                                      ) {
                                        handleInputChange('email', value)
                                      }
                                    }}
                                    onChange={(option) =>
                                      handleUserSelect('email', option)
                                    }
                                    isClearable
                                  />
                                </>
                              </div>
                              <div className="form-group">
                                <HasToolTipLabel
                                  labelText="Units"
                                  htmlFor="currencyUnit.unitType"
                                  id="currencyUnit_unitType"
                                  txt="Select Unit type from the drop down menu to buy Units"
                                />
                                <>
                                  <select
                                    className="form-control"
                                    {...registerBuyOrder(
                                      'currencyUnit.unitType',
                                    )}
                                  >
                                    {Object.values(UnitType)

                                      .map((unitType) => (
                                        <option key={unitType} value={unitType}>
                                          {UnitDisplayNames[unitType]}
                                        </option>
                                      ))}
                                  </select>
                                  {errorsBuyOrder.currencyUnit?.unitType && (
                                    <FormErrorMessage>
                                      {
                                        errorsBuyOrder.currencyUnit.unitType
                                          .message
                                      }
                                    </FormErrorMessage>
                                  )}
                                </>
                              </div>

                              <div className="form-group">
                                <HasToolTipLabel
                                  labelText="Number of Units"
                                  htmlFor="buy_currencyUnit.numberOfUnits"
                                  id="buy_currencyUnit_numberOfUnits"
                                  txt="Enter here the number of units you wish to buy (whole number)"
                                />

                                <>
                                  <input
                                    id="buy_currencyUnit.numberOfUnits"
                                    min={0}
                                    type="number"
                                    className="form-control"
                                    placeholder="Number"
                                    {...registerBuyOrder(
                                      'currencyUnit.numberOfUnits',
                                      { valueAsNumber: true },
                                    )}
                                  />
                                  {errorsBuyOrder.currencyUnit
                                    ?.numberOfUnits && (
                                    <FormErrorMessage>
                                      {
                                        errorsBuyOrder.currencyUnit
                                          .numberOfUnits.message
                                      }
                                    </FormErrorMessage>
                                  )}
                                </>
                              </div>
                              <div className="form-group">
                                <HasToolTipLabel
                                  htmlFor="q2Area"
                                  labelText="Company Area"
                                  id="q2_Area"
                                  txt={''}
                                />
                                <>
                                  <select
                                    className="form-control"
                                    id="q2Area"
                                    {...registerBuyOrder('q2Area')}
                                  >
                                    <option>UK</option>
                                    <option>Europe</option>
                                    <option>US</option>
                                  </select>
                                </>
                              </div>
                              <div className="form-group">
                                <HasToolTipLabel
                                  htmlFor="q2BankOption"
                                  labelText="Bank account option"
                                  id="q2_Bank_Option"
                                  txt={''}
                                />
                                <>
                                  <select
                                    className="form-control"
                                    id="q2BankOption"
                                    {...registerBuyOrder('q2BankOption')}
                                  >
                                    <option>Wise Europe</option>
                                    <option>Wise UK</option>
                                  </select>
                                </>
                              </div>

                              <div className="form-group">
                                <HasToolTipLabel
                                  htmlFor="q2OfficeLocation"
                                  labelText="Q2 Office Location"
                                  id="q2_Office_Location"
                                  txt={'Q2 Office Location'}
                                />
                                <>
                                  <select
                                    className="form-control"
                                    id="q2OfficeLocation"
                                    {...registerBuyOrder('q2OfficeLocation')}
                                  >
                                    <option>
                                      Europe: Liimi 1, 10621 Tallinn, Estonia
                                    </option>
                                    <option>
                                      UK: 4500 Parkway, Fareham PO15 7AZ
                                    </option>
                                  </select>
                                </>
                              </div>

                              <div className="form-group">
                                <HasToolTipLabel
                                  labelText="Additional Information"
                                  htmlFor="additionalInfo"
                                  id="additional_Info"
                                  txt="Please add any additional information you wish to add here"
                                />
                                <>
                                  <input
                                    id="additionalInfo"
                                    {...registerBuyOrder('additionalInfo')}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Additional Information"
                                  />
                                </>
                              </div>

                              <div className="form-group">
                                <HasToolTipLabel
                                  htmlFor="buy.referenceCode"
                                  labelText="Your Reference Code (Optional)"
                                  id="buy_referenceCode"
                                  txt="If you wish to enter a specific referende code, please add it here"
                                />
                                <>
                                  <input
                                    id="buy.referenceCode"
                                    {...registerBuyOrder('buy.referenceCode')}
                                    type="text"
                                    className="form-control"
                                    placeholder="This entry is optional"
                                  />
                                  {errorsBuyOrder.buy?.referenceCode && (
                                    <FormErrorMessage>
                                      {
                                        errorsBuyOrder.buy?.referenceCode
                                          ?.message
                                      }
                                    </FormErrorMessage>
                                  )}
                                </>

                                <div className="ref-code-bottom">
                                  <div className="ref-code-buttons mb-4 mt-3">
                                    <button
                                      type="button"
                                      onClick={applyDiscount}
                                      disabled={
                                        buyOrderData?.referenceCode === '' ||
                                        initialReferenceCode ===
                                          buyOrderData?.referenceCode
                                      }
                                      className="btn btn-success me-3"
                                    >
                                      Apply Code
                                    </button>

                                    {(isDiscountApplied ||
                                      discountError ||
                                      discountPaymentError) && (
                                      <button
                                        type="button"
                                        onClick={removeDiscount}
                                        className="btn btn-danger "
                                      >
                                        Remove Code
                                      </button>
                                    )}
                                  </div>
                                  <div className="ref-code-text">
                                    {isDiscountApplied &&
                                      !discountError &&
                                      !discountPaymentError &&
                                      buyOrderCurrencyUnit.isDoubleLand && (
                                        <h5>
                                          Code accepted. <br />
                                          User will receive{' '}
                                          {formatNumberWithCommas(
                                            buyOrderCurrencyUnit.numberOfUnits *
                                              2,
                                          )}{' '}
                                          {
                                            UnitDisplayNames[
                                              buyOrderCurrencyUnit.unitType
                                            ]
                                          }{' '}
                                          when you click create invoice.
                                        </h5>
                                      )}
                                    {discountError && (
                                      <FormErrorMessage>
                                        {discountError}
                                      </FormErrorMessage>
                                    )}
                                    {discountPaymentError && (
                                      <FormErrorMessage>
                                        {discountPaymentError}
                                      </FormErrorMessage>
                                    )}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <HasToolTipLabel
                                    htmlFor="comments"
                                    labelText="Note regarding purchase (optional)"
                                    id="comments"
                                    txt="If you would like to include any optional purchase note for the user/business, please add it here"
                                  />
                                  <>
                                    <input
                                      id="comments"
                                      {...registerBuyOrder('buy.comments')}
                                      type="text"
                                      className="form-control"
                                      placeholder="This entry is optional"
                                      maxLength={200}
                                    />
                                    {errorsBuyOrder.buy?.comments && (
                                      <FormErrorMessage>
                                        {errorsBuyOrder.buy.comments.message}
                                      </FormErrorMessage>
                                    )}
                                  </>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="transaction-info">
                            <ul>
                              <li>
                                <span>Unit Price</span>
                                <span>
                                  {
                                    CurrencyDisplaySymbol[
                                      buyOrderPaymentDetails.currency
                                    ]
                                  }{' '}
                                  {roundToSixDecimals(
                                    pricePerUnitBuyOrder / ONE_AUD_TO_CENTS,
                                  )}
                                </span>
                              </li>
                              {errorsBuyOrder.currencyUnit?.numberOfUnits && (
                                <FormErrorMessage>
                                  {
                                    errorsBuyOrder.currencyUnit?.numberOfUnits
                                      .message
                                  }
                                </FormErrorMessage>
                              )}
                              <li
                                className={
                                  buyOrderCurrencyUnit.isDoubleLand
                                    ? 'text-decoration-line-through'
                                    : ''
                                }
                              >
                                <span>Number of Units</span>
                                <span>
                                  {formatNumberWithCommas(
                                    numberOfUnitsBuyOrder,
                                  )}
                                </span>
                              </li>
                              {buyOrderCurrencyUnit.isDoubleLand && (
                                <li>
                                  <span>LU2X Offer Units</span>
                                  <span>
                                    {formatNumberWithCommas(
                                      buyOrderCurrencyUnit.numberOfUnits * 2,
                                    )}
                                  </span>
                                </li>
                              )}
                              <li>
                                <span>Purchase Price</span>
                                <span>
                                  {
                                    CurrencyDisplaySymbol[
                                      buyOrderPaymentDetails.currency
                                    ]
                                  }{' '}
                                  {formatNumberWithCommasUptoTwoDecimalWithCeil(
                                    buyOrderPurchasePrice / ONE_AUD_TO_CENTS,
                                  )}
                                </span>
                              </li>
                              {errorsBuyOrder.paymentDetails
                                ?.transactionFee && (
                                <FormErrorMessage>
                                  {
                                    errorsBuyOrder.paymentDetails
                                      ?.transactionFee.message
                                  }
                                </FormErrorMessage>
                              )}

                              <li>
                                <span>Transaction Fee</span>
                                <span>
                                  {
                                    CurrencyDisplaySymbol[
                                      buyOrderPaymentDetails.currency
                                    ]
                                  }{' '}
                                  {formatNumberWithCommasUptoTwoDecimalWithCeil(
                                    buyOrderTransactionFee / ONE_AUD_TO_CENTS,
                                  )}
                                </span>
                              </li>
                              {errorsBuyOrder.paymentDetails?.paymentAmount && (
                                <FormErrorMessage>
                                  {
                                    errorsBuyOrder.paymentDetails?.paymentAmount
                                      .message
                                  }
                                </FormErrorMessage>
                              )}
                              {minPurchasePaymentError && (
                                <FormErrorMessage>
                                  {minPurchasePaymentError}
                                </FormErrorMessage>
                              )}
                              <li>
                                <span>Total Price</span>
                                <span>
                                  {
                                    CurrencyDisplaySymbol[
                                      buyOrderPaymentDetails.currency
                                    ]
                                  }{' '}
                                  {formatNumberWithCommasUptoTwoDecimalWithCeil(
                                    buyOrderTotalPrice / ONE_AUD_TO_CENTS,
                                  )}
                                </span>
                              </li>
                            </ul>

                            <div className="text-md-center">
                              <QButton
                                // className="btn_1 btn_yellow"
                                color="success"
                                round
                                type="button"
                                loading={isLoadingBuyOrder}
                                disabled={
                                  !user ||
                                  !isBuyOrderFormValid ||
                                  isLoadingBuyOrder ||
                                  !(numberOfUnitsBuyOrder > 0) ||
                                  buyOrderPaymentDetails.paymentMethod ===
                                    PaymentMethodEnum.Crypto ||
                                  !(
                                    buyOrderPaymentDetails.paymentMethod ===
                                    PaymentMethodEnum.Bank
                                  ) ||
                                  discountError !== '' ||
                                  discountPaymentError !== '' ||
                                  minPurchasePaymentError !== ''
                                }
                                onClick={async () => {
                                  // handleServerError(emptyAxiosError)
                                  // // No error in form then open modal
                                  // if (await triggerBuyOrder()) {
                                  //   setIsModalOpenBuyOrder(true)
                                  // }
                                  handleServerError(emptyAxiosError)
                                  previewEmailInModalIframe()
                                }}
                                fullWidth
                                size="lg"
                              >
                                Create Invoice
                              </QButton>
                            </div>
                            {successMessage && successOrder && (
                              <div className="alert alert-success mt-3">
                                Invoice created for{' '}
                                <strong>{user?.email}</strong> for{' '}
                                <strong>
                                  {successOrder.currencyUnit.numberOfUnits}
                                </strong>{' '}
                                {
                                  UnitDisplayNames[
                                    successOrder.currencyUnit.unitType
                                  ]
                                }
                                .
                                <br />
                                You can see this order in{' '}
                                <Link
                                  to={`/dashboard/order/bank-purchase`}
                                  className="alert-link"
                                >
                                  Order Management/Bank Orders for approval
                                </Link>
                                .
                                <br />
                                Please wait for the user to complete the
                                payment.
                              </div>
                            )}

                            {serverError && (
                              <FormErrorMessage>{serverError}</FormErrorMessage>
                            )}
                            <div className="mt-2">
                              {errorsBuyOrder.paymentDetails?.subTotal && (
                                <FormErrorMessage>
                                  {
                                    errorsBuyOrder.paymentDetails.subTotal
                                      .message
                                  }
                                </FormErrorMessage>
                              )}

                              {errorsBuyOrder.orderStatus && (
                                <FormErrorMessage>
                                  {errorsBuyOrder.orderStatus.message}
                                </FormErrorMessage>
                              )}
                              {errorsBuyOrder.currencyUnit?.pricePerUnit && (
                                <FormErrorMessage>
                                  {
                                    errorsBuyOrder.currencyUnit.pricePerUnit
                                      .message
                                  }
                                </FormErrorMessage>
                              )}
                            </div>
                          </div>
                          {/* <OrderModalPreConfirmation
                            referenceData={buyOrderData}
                            isOpen={isModalOpenBuyOrder}
                            transactionType={OrderType.BUY}
                            toggleModal={toggleModal}
                            currencyUnit={buyOrderCurrencyUnit}
                            paymentDetails={buyOrderPaymentDetails}
                            // @ts-ignore
                            onSubmit={handleSubmitBuyOrder(handleBuyOrder)}
                          /> */}
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                  <Modal
                    isOpen={previewModal}
                    toggle={togglePreviewModal}
                    className="modal-dialog-centered "
                    scrollable
                    // size="lg"
                    fullscreen
                    unmountOnClose={true}
                  >
                    <ModalHeader>Invoice Email Preview</ModalHeader>
                    <ModalBody>
                      {previewModalLoading ? (
                        <LoadingBlock />
                      ) : (
                        <iframe
                          srcDoc={renderedHTML?.html}
                          width="100%"
                          style={{
                            height: '100%',
                            // border: "none"
                            border: '1px solid #f0151f',
                          }}
                          title="Email Content Preview"
                        />
                      )}
                    </ModalBody>

                    <ModalFooter>
                      <QButton
                        round
                        className="me-3"
                        onClick={togglePreviewModal}
                      >
                        Cancel
                      </QButton>
                      <QButton
                        round
                        className="me-3"
                        onClick={handleBuyOrder}
                        loading={isLoadingBuyOrder}
                        color="success"
                      >
                        Confirm & Send Invoice
                      </QButton>
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
