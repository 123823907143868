import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { deleteUser, getUserListByNameOrEmailForDelete } from '../../api'
import DeleteIcon from '@mui/icons-material/Delete'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { FormGroup } from 'reactstrap'
import { User, UserOrBusinessProps, UserType } from '../../utils/types'
import { getDate } from '../../utils/utilities'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'

export default function DelteUserOrBusiness({ userType }: UserOrBusinessProps) {
  const [data, setData] = useState('')
  const [searchType, setSearchType] = useState('name') // Default search type is name
  const [searchTerm, setSearchTerm] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')

  const getUsers = () => {
    getUserListByNameOrEmailForDelete(searchType, searchTerm, userType)
      .then((res) => {
        setData(res.data.users)
      })
      .catch((err) => {
        console.log('error=============', err)
        toast.error(err.response.data.message)
      })
  }
  useEffect(() => {
    getUsers()
  }, [])
  const selectUser = (user: User) => {
    setSelectedUser(user)
    toggleModel()
  }

  const toggleModel = () => setIsModalOpen(!isModalOpen)
  const handleDeleteUser = (id: any) => {
    setIsLoading(true)

    deleteUser(id)
      .then(async (res) => {
        const message = res.data.message
        toast.success(message)
        setSaveMessage(message)
        // handleUserSelect(user)
        setTimeout(() => {
          setSaveMessage('')
          setIsLoading(false)
          setSelectedUser(null)
          toggleModel()
        }, 500)
        getUsers()
      })
      .catch((err) => {
        setIsLoading(false)
        toast.error(err.response.data.message, { position: 'bottom-center' })

        console.log('error', err)
      })
  }

  return (
    <>
      <div>
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">DELETE {userType}</li>
            </ol>

            <div className="card mb-3">
              <div className="card-body">
                <div className="table-responsive">
                  <FormGroup className="search-by-wrap">
                    <select
                      className="form-control"
                      value={searchType}
                      onChange={(e) => setSearchType(e.target.value)}
                    >
                      <option value="name">Search {userType} by Name</option>
                      <option value="email">Search {userType} by Email</option>
                    </select>

                    <SearchInput
                      onClick={getUsers}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      ButtonText="Search"
                      placeholder={'Search'}
                    // loading={userLoading}
                    />
                  </FormGroup>
                  <table
                    className="table table-bordered"

                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>
                          {userType === UserType.BUSINESS
                            ? 'Authorised Person '
                            : ''}
                          Name
                        </th>
                        {userType === UserType.BUSINESS && (
                          <th>Business Name</th>
                        )}
                        <th>Email</th>
                        <th>Date Joined</th>
                        <th>Phone Number</th>
                        <th>Delete {userType}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(data) &&
                        data.map((user: User, index: number) => {
                          return (
                            <tr>
                              <td>
                                <b>{index + 1}.</b>
                              </td>
                              <td>{user?.firstName + ' ' + user?.lastName}</td>
                              {userType === UserType.BUSINESS && (
                                <td>{user.kycInfo.businessInfo?.businessName}</td>
                              )}
                              <td>{user?.email}</td>
                              <td>{getDate(user.createdAt)}</td>
                              <td>
                                {user?.kycInfo.contactInfo.telephoneCountryCode +
                                  user?.kycInfo.contactInfo.phoneNumber}
                              </td>
                              {/* <td onClick={() => setId(item._id)}>
                              <button
                                className="btn btn-danger bg-danger btn-sm "
                                onClick={() => {
                                  // handleEnabledOrDisableUsers(item._id)
                                }}
                              >
                                Delete {item.firstName + ' ' + item.lastName}{' '}
                              </button>
                            </td> */}

                              <td
                                onClick={() => {
                                  selectUser(user)
                                }}
                                data-toggle="modal"
                                data-target="#userModalCenter"
                              >
                                <DeleteIcon style={{ padding: '0px' }} />
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>

                  </table>
                  <Modal
                    isOpen={isModalOpen}
                    toggle={toggleModel}
                    centered
                    size="lg"
                    scrollable
                    onClosed={() => {
                      setSelectedUser(null)
                    }}
                  >
                    <ModalHeader toggle={toggleModel}>
                      Delete {userType}
                    </ModalHeader>
                    <ModalBody>
                      {saveMessage && (
                        <div className="save-message">{saveMessage}</div>
                      )}

                      {selectedUser && (
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>First Name</label>

                              <p>{selectedUser.firstName}</p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Last Name</label>
                              <p>{selectedUser.lastName}</p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Email</label>
                              <div className="input-group mb-3">
                                <p>{selectedUser.email} </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Account Number</label>
                              <p>{selectedUser.accountNumber} </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Country Code</label>
                              <p>
                                {
                                  selectedUser.kycInfo.contactInfo
                                    .telephoneCountryCode
                                }
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Contact Number</label>
                              <p>
                                {selectedUser.kycInfo.contactInfo.phoneNumber}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Purchase Limit in Cents</label>
                              <p>{selectedUser.purchaseLimitInCents}</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>House Number</label>
                              <p>{selectedUser.kycInfo.address.houseNumber}</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Street Address</label>
                              <p>
                                {selectedUser.kycInfo.address.streetAddress}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Additional Address</label>
                              <p>
                                {selectedUser.kycInfo.address.additionalAddress}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Town/City</label>
                              <p>{selectedUser.kycInfo.address.townCity}</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Country</label>
                              <p>{selectedUser.kycInfo.address.country}</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Postal Code</label>
                              <p>{selectedUser.kycInfo.address.postcode}</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Passport Number</label>
                              <p>
                                {selectedUser.kycInfo.passport.passportNumber}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Issuing Country</label>
                              <p>
                                {selectedUser.kycInfo.passport.issuingCountry}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Passport Start Date</label>
                              <p>
                                {
                                  new Date(
                                    selectedUser.kycInfo.passport?.startDate,
                                  )
                                    .toISOString()
                                    .split('T')[0]
                                }
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Passport Expiry Date</label>
                              <p>
                                {
                                  new Date(
                                    selectedUser.kycInfo.passport?.expiryDate,
                                  )
                                    .toISOString()
                                    .split('T')[0]
                                }
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Bank Account Name</label>
                              <p>
                                {
                                  selectedUser.kycInfo.bankDetails
                                    .bankAccountName
                                }
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Bank Name</label>
                              <p>{selectedUser.kycInfo.bankDetails.bankName}</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>IBAN</label>
                              <p>{selectedUser.kycInfo.bankDetails.iban}</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Bank Account Number</label>
                              <p>
                                {
                                  selectedUser.kycInfo.bankDetails
                                    .bankAccountNumber
                                }
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Bank Address</label>
                              <p>
                                {selectedUser.kycInfo.bankDetails.bankAddress}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>Bank Country</label>
                              <p>
                                {selectedUser.kycInfo.bankDetails.bankCountry}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label>SWIFT/BIC</label>
                              <p>{selectedUser.kycInfo.bankDetails.swiftBic}</p>
                            </div>
                          </div>
                          {selectedUser.kycInfo.businessInfo &&
                            selectedUser.userType === UserType.BUSINESS && (
                              <>
                                <div className="col-md-12">
                                  <h4>Business Profile</h4>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group mb-3">
                                    <label>Business Name</label>
                                    <p>
                                      {
                                        selectedUser.kycInfo.businessInfo
                                          .businessName
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group mb-3">
                                    <label>Registration Number</label>
                                    <p>
                                      {
                                        selectedUser.kycInfo.businessInfo
                                          .registrationNumber
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group mb-3">
                                    <label>Vat Number</label>

                                    <p>
                                      {
                                        selectedUser.kycInfo.businessInfo
                                          .vatNumber
                                      }
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="col-md-4">
                                  <div className="form-group mb-3">
                                    <label>Business Address</label>
                                    <p>
                                      {
                                        selectedUser.kycInfo.businessInfo
                                          .businessAddress
                                      }
                                    </p>
                                  </div>
                                </div> */}
                              </>
                            )}
                        </div>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <QButton
                        color="primary"
                        onClick={() => {
                          toggleModel()
                          setTimeout(() => {
                            setSelectedUser(null)
                            setSelectedUser(null)
                          }, 300)
                        }}
                      >
                        Cancel
                      </QButton>
                      <QButton
                        color="primary"
                        loading={isLoading}
                        onClick={() => {
                          handleDeleteUser(selectedUser?._id)
                        }}
                      >
                        Delete
                      </QButton>{' '}
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
