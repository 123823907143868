import { revalidateNextJsPages } from '../api'
import { urlBasedOnEnv } from './constants'
import {
  PromoEmailDayType,
  PromotionEmailInterface,
  UnitDisplayNames,
  UnitType,
} from './types'
import { formatNumberWithCommas } from './utilities'

export const init_revalidate_next_pages = async () => {
  try {
    let res = await revalidateNextJsPages(
      //@ts-ignore
      `${urlBasedOnEnv[process.env.REACT_APP_NODE_ENV]}/api/revalidate?secret=${process.env.REACT_APP_ISR_TOKEN_NEXT}`,
    )
    console.log('res:', res)
  } catch (e) {
    console.log('e:', e)
  }
}

export const capitalizedOneWord = (str: string) => {
  return str.charAt(0).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase()
}
export const camelCaseToSpaced = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2')
}
export const qtScrollIntoView = (id: string, cb?: () => void) => {
  let stScrollIntoViewTimeOUtId
  clearTimeout(stScrollIntoViewTimeOUtId)

  stScrollIntoViewTimeOUtId = setTimeout(() => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
      if (typeof cb === 'function') {
        cb()
      }
    }
  }, 200)
}

export function stringToSlug(str: string) {
  return str
    .toLowerCase()
    .replace(/[^\w\s-]/g, '')
    .trim()
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
}

export const promoEmailConfigCreator = (
  data: PromotionEmailInterface,
): string => {
  if (data.dayType === PromoEmailDayType.ZERO) {
    return `${data.recipientsType}_${data.dayType}_${stringToSlug(data.templateName)}`
  } else {
    return `${data.recipientsType}_${data.dayType}`
  }
}

export const getPercentageToNumber = (
  baseValue: number,
  percentage: number,
) => {
  console.log('baseValue:', baseValue, percentage)
  return Math.round(baseValue * (percentage / 100))
}

export const viewLandUnitCountAndType = (
  numberOfUnits: number,
  unitType: UnitType,
) => {
  // return `There are ${numberOfUnits} ${unitType} units`;
  return `${formatNumberWithCommas(numberOfUnits)} Unit${numberOfUnits > 1 ? 's' : ''} (type: ${UnitDisplayNames[unitType].replace(/[()]/g, '')})`
}
