import { AxiosError } from 'axios'
import { useState } from 'react'

export const useServerError = () => {
  const [serverError, setServerError] = useState<string | null>(null)

  const handleServerError = (error: AxiosError<{ message: string | null }>) => {
    if (error.response?.data) {
      setServerError(error.response.data.message)
    } else {
      if (error.code === 'ERR_NETWORK') setServerError('Server down')
      else setServerError(error.message)
    }
  }

  return { serverError, handleServerError }
}
