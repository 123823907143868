import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import './index.scss'
import {
  ExternalCardCryptoFeeFormData,
  UnitPrices,
  UnitDisplayNames,
  PaymentMethodEnum,
  ProcessorFees,
  ExternalFeesUpdation,
} from '../../utils/types'
import {
  getCurrencyUnitsApi,
  getExternalfee,
  updateCurrencyUnitFeesApi,
  updateExternalFeesApi,
} from '../../api'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'

const FeeManagement = () => {
  const [unitPrices, setUnitPrices] = useState<UnitPrices[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [selectedUnitPricing, setSelectedUnitPricing] =
    useState<UnitPrices | null>(null)
  const [updatedUnitPricing, setUpdatedUnitPricing] =
    useState<UnitPrices | null>(null)
  const [isInternalFeeModalOpen, setInternalFeeModalOpen] = useState(false)
  const toggleInternalFee = () =>
    setInternalFeeModalOpen(!isInternalFeeModalOpen)

  const [isLoadingExternalFee, setIsLoadingExternalFee] = useState(false)

  const [isExternalFeeModalOpen, setExternalFeeModalOpen] = useState(false)
  const toggleExternalFee = () =>
    setExternalFeeModalOpen(!isExternalFeeModalOpen)

  const [externalFees, setExternalFees] = useState<[string, ProcessorFees][]>(
    [],
  )

  const [processorFee, setProcessorFee] = useState<ExternalFeesUpdation | null>(
    null,
  )

  const [updatedProcessorFee, setUpdatedProcessorFee] =
    useState<ExternalFeesUpdation | null>(null)

  const getExternalFeeDetails = async () => {
    try {
      const externalFees = (await getExternalfee()).data?.externalFees
      setExternalFees(Object.entries(externalFees))
    } catch (error: any) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  const getCurrencyUnits = async () => {
    getCurrencyUnitsApi()
      .then((res) => {
        setUnitPrices(res.data)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }

  const handleInternalFeeSubmit = async () => {
    if (!updatedUnitPricing) return
    const isUnitPricingChanges =
      JSON.stringify(selectedUnitPricing) !== JSON.stringify(updatedUnitPricing)
    if (!isUnitPricingChanges) return alert('data hasnot been changed yet')

    setIsLoading(true)
    try {
      const { data: unitPriceUpdated, message: successMessage } = (
        await updateCurrencyUnitFeesApi(updatedUnitPricing)
      ).data
      toast.success(successMessage)
      await getCurrencyUnits()

      setUpdatedUnitPricing(unitPriceUpdated)
      setSelectedUnitPricing(unitPriceUpdated)
      setIsLoading(false)
    } catch (error: any) {
      console.log('error', error)
      toast.error(error.response.data.message)
      setIsLoading(false)
    }
  }

  const handleInputInternalFeeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target
    setUpdatedUnitPricing((prev) => ({
      ...prev!,
      [name]: value === '' ? 0 : parseFloat(value), // Set to 0 if left empty
    }))
  }

  const handleInputExternalFeeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target
    setUpdatedProcessorFee((prev) => ({
      ...prev!,
      [name]: value === '' ? 0 : parseFloat(value), // Set to 0 if left empty
    }))
  }
  const handleExternalFeeSubmit = async () => {
    if (!updatedProcessorFee) return
    const isProcessorFeeChanged =
      JSON.stringify(processorFee) !== JSON.stringify(updatedProcessorFee)
    if (!isProcessorFeeChanged) return alert('data hasnot been changed yet')

    setIsLoadingExternalFee(true)
    try {
      const { processorFees: externalFeeProcessor, message: successMessage } = (
        await updateExternalFeesApi(updatedProcessorFee)
      ).data
      toast.success(successMessage)
      await getExternalFeeDetails()

      setProcessorFee(externalFeeProcessor)
      setUpdatedProcessorFee(externalFeeProcessor)
      setIsLoadingExternalFee(false)
    } catch (error: any) {
      console.log('error', error)
      toast.error(error.response.data.message)
      setIsLoadingExternalFee(false)
    }
  }

  // Open the modal with the selected unit's data
  const handleInternalFeeUpdateClick = (unit: UnitPrices) => {
    setSelectedUnitPricing(unit)
    setUpdatedUnitPricing(unit)
    toggleInternalFee()
  }

  const handleExternalFeesUpdateClick = (
    externalFeesUpdation: ExternalFeesUpdation,
  ) => {
    setProcessorFee(externalFeesUpdation)
    setUpdatedProcessorFee(externalFeesUpdation)
    toggleExternalFee()
  }

  useEffect(() => {
    getCurrencyUnits()
    getExternalFeeDetails()
  }, [])
  return (
    <div>
      <div className="content-wrapper ">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li
              className="breadcrumb-item"
              style={{
                paddingTop: '2rem',
                paddingBottom: '0.6rem',
              }}
            >
              <Link to="/">Dashboard</Link>
            </li>
            <li
              className="breadcrumb-item active"
              style={{
                paddingTop: '2rem',
                paddingBottom: '0.6rem',
              }}
            >
              Fees Management
            </li>
          </ol>

          <div className="card mb-3">
            <div className="card-body">
              <h3>Update Internal Fees</h3>
              <div className="table-responsive">
                <table
                  className="table table-bordered text-center"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>Unit Type</th>
                      <th>Buy Order Fee (in Cents)</th>
                      <th>Sell Order Fee (in Units)</th>
                      <th>Convert Order Fee (in Units)</th>
                      <th>Convert Order Lock Days</th>
                      <th>Secondhand Units sold as % of all Units sold</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unitPrices.map((unit: UnitPrices) => (
                      <tr key={unit.unitType}>
                        {/* @ts-ignore */}
                        <td>{UnitDisplayNames[unit.unitType]}</td>
                        <td>{unit.buyOrderTransactionFeeInCents}</td>
                        <td>{unit.sellOrderTransactionFeeInUnits}</td>
                        <td>{unit.convertOrderTransactionFeeInUnits}</td>
                        <td>{unit.convertOrderLockDays}</td>
                        <td>{unit.secondaryMarketTransactionPercentage}</td>
                        <td>
                          <button
                            onClick={() => handleInternalFeeUpdateClick(unit)}
                            className="btn btn-primary"
                          >
                            Update
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Modal
                  isOpen={isInternalFeeModalOpen}
                  toggle={toggleInternalFee}
                  centered
                  scrollable
                  onClosed={() => {
                    setSelectedUnitPricing(null)
                    setUpdatedUnitPricing(null)
                  }}
                >
                  <ModalHeader toggle={toggleInternalFee}>
                    Update{' '}
                    <b>
                      {/* @ts-ignore */}
                      {UnitDisplayNames[updatedUnitPricing?.unitType]}
                    </b>{' '}
                    Price details
                  </ModalHeader>
                  <ModalBody>
                    {updatedUnitPricing && (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label>Buy Order Fee (in Cents)</label>
                            <input
                              type="text"
                              className="form-control"
                              name="buyOrderTransactionFeeInCents"
                              value={
                                updatedUnitPricing.buyOrderTransactionFeeInCents
                              }
                              onChange={handleInputInternalFeeChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label>Sell Order Fee (in Units)</label>
                            <input
                              type="number"
                              min={1}
                              name="sellOrderTransactionFeeInUnits"
                              className="form-control"
                              value={
                                updatedUnitPricing.sellOrderTransactionFeeInUnits
                              }
                              onChange={handleInputInternalFeeChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label>Convert Order Fee (in Units)</label>
                            <input
                              type="number"
                              min={1}
                              name="convertOrderTransactionFeeInUnits"
                              className="form-control"
                              value={
                                updatedUnitPricing.convertOrderTransactionFeeInUnits
                              }
                              onChange={handleInputInternalFeeChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label>Convert order Lock days</label>
                            <input
                              type="number"
                              min={1}
                              name="convertOrderLockDays"
                              className="form-control"
                              value={updatedUnitPricing.convertOrderLockDays}
                              onChange={handleInputInternalFeeChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label>
                              Second hand Units sold as (%) from buyer
                            </label>
                            <div className="input-group mb-3">
                              <input
                                type="number"
                                name="secondaryMarketTransactionPercentage"
                                className="form-control"
                                min={0}
                                max={100}
                                value={
                                  updatedUnitPricing.secondaryMarketTransactionPercentage
                                }
                                onChange={handleInputInternalFeeChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={() => {
                        toggleInternalFee()
                        setSelectedUnitPricing(null)
                        setUpdatedUnitPricing(null)
                      }}
                      disabled={isLoading}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      disabled={isLoading}
                      onClick={handleInternalFeeSubmit}
                    >
                      Update{' '}
                      {isLoading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-body">
              <h3>Update External Fees</h3>
              <div className="table-responsive">
                <table
                  className="table table-bordered text-center"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>Payment Method</th>
                      <th>Fee Percentage</th>
                      <th>Fee Fixed Cents</th>
                      <th>Extra Fee</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {externalFees &&
                      externalFees.map(([method, fees], index) => (
                        <tr key={index}>
                          <td>{method}</td>
                          <td>{fees.fixedPercentageFees} %</td>
                          <td>{fees.fixedCentsFees}</td>
                          <td>{fees.extraFees}</td>
                          <td>
                            <button
                              onClick={() =>
                                handleExternalFeesUpdateClick({
                                  ...fees,
                                  paymentMethod: method as PaymentMethodEnum,
                                })
                              }
                              className="btn btn-primary"
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <Modal
                  isOpen={isExternalFeeModalOpen}
                  toggle={toggleExternalFee}
                  centered
                  scrollable
                  onClosed={() => {
                    setProcessorFee(null)
                    setUpdatedProcessorFee(null)
                  }}
                >
                  <ModalHeader toggle={toggleExternalFee}>
                    Update <b>{updatedProcessorFee?.paymentMethod}</b> Payment
                    Processor
                  </ModalHeader>
                  <ModalBody>
                    {updatedProcessorFee && (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label>Fee Percentage</label>
                            <input
                              type="text"
                              className="form-control"
                              name="fixedPercentageFees"
                              value={updatedProcessorFee.fixedPercentageFees}
                              onChange={handleInputExternalFeeChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label>Fixed Fee (in Cents)</label>
                            <input
                              type="number"
                              min={1}
                              name="fixedCentsFees"
                              className="form-control"
                              value={updatedProcessorFee.fixedCentsFees}
                              onChange={handleInputExternalFeeChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label>Extra fees</label>
                            <input
                              type="number"
                              min={1}
                              name="extraFees"
                              className="form-control"
                              value={updatedProcessorFee.extraFees}
                              onChange={handleInputExternalFeeChange}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={() => {
                        toggleExternalFee()
                        setProcessorFee(null)
                        setUpdatedProcessorFee(null)
                      }}
                      disabled={isLoadingExternalFee}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      disabled={isLoadingExternalFee}
                      onClick={handleExternalFeeSubmit}
                    >
                      Update{' '}
                      {isLoadingExternalFee && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
          {/* <h4 style={{ color: "red" }}>{msg}</h4> */}
        </div>
      </div>
    </div>
  )
}
export default FeeManagement
