import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import AdminDashboard from './pages/adminDashboard' // Corrected import
import AdminViewTransaction from './pages/AdminViewTransaction' // Corrected import
import Login from './pages/login'
import ResetPasswordRequestPage from './pages/ResetPasswordRequestPage'
import ResetPasswordPage from './pages/ResetPasswordPage'
import ViewUnitsBank from './pages/viewOrEditFiat'
import AddQuestion from './pages/governancePage/addQuestion'
import EditQuestion from './pages/governancePage/editQuestion'
import ViewQuestion from './pages/governancePage/viewQuestion'
import DeleteQuestion from './pages/governancePage/deleteQuestion'
import NotFound from './pages/notFoundPage'
import FeeManagement from './pages/setting/index'
import AssignOrRemoveAdminRole from './pages/assignOrRemoveAdminRole'
import ReferenceCodeManagement from './pages/refereneceCode'
import ViewAllSuperAdmin from './pages/viewAllSuperAdmin'
import './assets/scss/bootstrap-custom.scss'
import 'react-quill-new/dist/quill.snow.css'
import 'react-toastify/dist/ReactToastify.css'
// import './assets/css/font-awesome.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

// import './bootstrap5-utils.scss'

// plugins csss
// import './assets/scss/dataTables.bootstrap4.css'
// import './assets/scss/dropzone.css'

// theme css
// import './assets/scss/admin.css'
// import "./assets/scss/bootstrap.min.css";
// import './assets/scss/summernote-bs4.css'

import './assets/scss/vendors.css'

import './assets/scss/QDesignSystem/scss/common.scss'
import './assets/scss/custom.css'
import './assets/scss/style.scss'
import './assets/scss/color-red.css'
import './new-overrides.scss'

import { ToastContainer } from 'react-toastify'
// import "./index.css";
import QDesignSystem from './pages/QDesignSystem'
import { getUserOrSuperAdmin } from './api'
import {
  AdminRoles,
  EmailDeliveryTypeEnum,
  PEcomponentAction,
  RouteType,
} from './utils/types'
import Sidebar from './component/Sidbar'
import Footer from './component/Footer'
import AddProjects from './pages/projectsPage/AddProjects'
import EditProjects from './pages/projectsPage/EditProjects'
import ViewProjects from './pages/projectsPage/ViewProjects'
import ViewCommunityIdeas from './pages/projectsPage/ViewCommunityIdeas'
import AddNews from './pages/addNews'
import DeleteUpdateViewNews from './pages/DeleteUpdateViewNews'
import EditLandProjects from './pages/landProjectsPage/EditLandProject'
import AddLandProject from './pages/landProjectsPage/AddLandProject'
import ViewLandProjects from './pages/landProjectsPage/ViewLandProjects'

import EditNewsCategory from './pages/newsCategory/EditNewsCategory'
import AddNewsCategory from './pages/newsCategory/AddNewsCategory'
import ViewNewsCategory from './pages/newsCategory/ViewNewsCategory'
import { AppContextProvider } from './context/AppContext'
import Header from './component/common/header/Header'
import {
  ApproveBusiness,
  DeleteBusiness,
  DisableEnableBusiness,
  EditBusiness,
  ViewApprovedBusiness,
} from './pages/BusinessManagement'
import {
  ApproveCommunityAgent,
  ApproveUser,
  DeleteUser,
  DisableEnableUser,
  EditUser,
  ViewApprovedCommunityAgent,
  ViewApprovedUsers,
  ViewSubscribers,
} from './pages/UserManagement'

import {
  BuyAndSellApprovedOrCompleted,
  BuyBankPurchase,
  BuyCardStripePurchase,
  BuyCardSquarePurchase,
  BuyOtherPurchase,
  SellOrderApproval,
  InCompletedBuyOrder,
  SellOrderView,
} from './pages/OrderManagement'
import { ViewAllLand, EditLand, AddNewLand } from './pages/LandManagement'
import ViewOrEditFiat from './pages/viewOrEditFiat'
import PromotionEmailAddAndEdit from './pages/PromotionEmail/PromotionEmailAddAndEdit'
import ViewPromotionEmails from './pages/PromotionEmail/ViewPromotionEmail'
import SetStates from './pages/setStates/setStates'
import EmailTemplateList from './pages/email-templates/EmailTemplateList'
import EmailTemplateEdit from './pages/email-templates/EmailTemplateEdit'
import CreateInvoice from './pages/OrderManagement/createInvoice'
function App() {
  const [authUserOrAdmin, setAuthUserOrAdmin] = useState<string | null>(null) // Corrected type
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false) // Corrected type
  const [loading, setLoading] = useState(true)
  const [userRoles, setUserRoles] = useState<AdminRoles[]>([])

  useEffect(() => {
    const getSuperAdmin = () => {
      getUserOrSuperAdmin()
        .then((res) => {
          const user = res.data.user
          const isSuperAdmin = user.isSuperAdmin ? true : false
          setIsSuperAdmin(isSuperAdmin)
          const userRoles = user.roles ? user.roles : []
          setUserRoles(userRoles)
          setAuthUserOrAdmin(res.data.user)
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    }
    getSuperAdmin()
  }, [])

  const renderRoute = ({ path, element, requiredRoles = [] }: RouteType) => {
    if (
      isSuperAdmin ||
      (requiredRoles.length > 0 &&
        requiredRoles.some((role) => userRoles.includes(role))) ||
      (requiredRoles.length === 0 && (isSuperAdmin || userRoles.length > 0))
    ) {
      return <Route path={path} element={element} />
    }
    if (path === '/' && userRoles.length === 0)
      return <Route path="/" element={<Login />} />

    return null
  }

  return loading ? (
    <p>Loading please wait....</p>
  ) : (
    <BrowserRouter>
      <AppContextProvider user={authUserOrAdmin}>
        <>
          <ToastContainer />
          {/* <Sidebar /> */}
          <Header />
          <Routes>
            {authUserOrAdmin ? (
              <>
                {renderRoute({
                  path: '/',
                  element: <Navigate to="/dashboard" />,
                })}
                {renderRoute({
                  path: '/dashboard',
                  element: <AdminDashboard />,
                })}
                {renderRoute({
                  path: '/dashboard/user/approve',
                  element: <ApproveUser />,
                  requiredRoles: [AdminRoles.UserAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/user/approved-list',
                  element: <ViewApprovedUsers />,
                  requiredRoles: [AdminRoles.UserAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/user/remove',
                  element: <DeleteUser />,
                  requiredRoles: [AdminRoles.SuperAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/user/manage',
                  element: <EditUser />,
                  requiredRoles: [AdminRoles.SuperAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/user/enable-disable',
                  element: <DisableEnableUser />,
                  requiredRoles: [AdminRoles.SuperAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/view-subscribers',
                  element: <ViewSubscribers />,
                  requiredRoles: [AdminRoles.UserAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/user/approve-community-agents',
                  element: <ApproveCommunityAgent />,
                  requiredRoles: [AdminRoles.UserAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/user/view-approved-agent',
                  element: <ViewApprovedCommunityAgent />,
                  requiredRoles: [AdminRoles.UserAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/business/approve',
                  element: <ApproveBusiness />,
                  requiredRoles: [AdminRoles.UserAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/business/approved-list',
                  element: <ViewApprovedBusiness />,
                  requiredRoles: [AdminRoles.UserAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/business/remove',
                  element: <DeleteBusiness />,
                  requiredRoles: [AdminRoles.SuperAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/business/manage',
                  element: <EditBusiness />,
                  requiredRoles: [AdminRoles.SuperAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/business/enable-disable',
                  element: <DisableEnableBusiness />,
                  requiredRoles: [AdminRoles.SuperAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/admin/edit-admin-role',
                  element: <AssignOrRemoveAdminRole />,
                  requiredRoles: [AdminRoles.SuperAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/admin/view-super-admin',
                  element: <ViewAllSuperAdmin />,
                  requiredRoles: [AdminRoles.SuperAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/land/add',
                  element: <AddNewLand />,
                  requiredRoles: [AdminRoles.LandAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/land/view',
                  element: <ViewAllLand />,
                  requiredRoles: [AdminRoles.LandAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/land/update',
                  element: <EditLand />,
                  requiredRoles: [AdminRoles.SuperAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/fiat/manage',
                  element: <ViewOrEditFiat />,
                  requiredRoles: [
                    AdminRoles.BankAdmin,
                    AdminRoles.FinanceAdmin,
                  ],
                })}
                {renderRoute({
                  path: '/dashboard/order/stripe-purchase',
                  element: <BuyCardStripePurchase />,
                  requiredRoles: [
                    AdminRoles.BankAdmin,
                    AdminRoles.FinanceAdmin,
                  ],
                })}
                {renderRoute({
                  path: '/dashboard/order/square-purchase',
                  element: <BuyCardSquarePurchase />,
                  requiredRoles: [
                    AdminRoles.BankAdmin,
                    AdminRoles.FinanceAdmin,
                  ],
                })}
                {renderRoute({
                  path: '/dashboard/order/bank-purchase',
                  element: <BuyBankPurchase />,
                  requiredRoles: [AdminRoles.BankAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/order/other-purchase',
                  element: <BuyOtherPurchase />,
                  requiredRoles: [
                    AdminRoles.BankAdmin,
                    AdminRoles.FinanceAdmin,
                  ],
                })}
                {renderRoute({
                  path: '/dashboard/order/incomplete-purchase',
                  element: <InCompletedBuyOrder />,
                  requiredRoles: [
                    AdminRoles.BankAdmin,
                    AdminRoles.FinanceAdmin,
                  ],
                })}

                {renderRoute({
                  path: '/dashboard/order/view-sell',
                  element: <SellOrderView />,
                  requiredRoles: [
                    AdminRoles.BankAdmin,
                    AdminRoles.FinanceAdmin,
                  ],
                })}

                {renderRoute({
                  path: '/dashboard/order/sold',
                  element: <SellOrderApproval />,
                  requiredRoles: [
                    AdminRoles.BankAdmin,
                    AdminRoles.FinanceAdmin,
                  ],
                })}
                {renderRoute({
                  path: '/dashboard/order/approve-completed',
                  element: <BuyAndSellApprovedOrCompleted />,
                  requiredRoles: [
                    AdminRoles.BankAdmin,
                    AdminRoles.FinanceAdmin,
                  ],
                })}
                {renderRoute({
                  path: '/dashboard/order/create-invoice',
                  element: <CreateInvoice />,
                  requiredRoles: [
                    AdminRoles.SalesAdmin,
                    AdminRoles.FinanceAdmin,
                  ],
                })}
                {renderRoute({
                  path: '/dashboard/governance/add-question',
                  element: <AddQuestion />,
                  requiredRoles: [AdminRoles.VotingAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/governance/view-question',
                  element: <ViewQuestion />,
                  requiredRoles: [AdminRoles.VotingAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/addProject',
                  element: <AddProjects />,
                  requiredRoles: [AdminRoles.VotingAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/editProject/:projectID',
                  element: <EditProjects />,
                  requiredRoles: [AdminRoles.VotingAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/viewProjects/',
                  element: <ViewProjects />,
                  requiredRoles: [AdminRoles.VotingAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/addLandProject',
                  element: <AddLandProject />,
                  requiredRoles: [AdminRoles.VotingAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/editLandProject/:id',
                  element: <EditLandProjects />,
                  requiredRoles: [AdminRoles.VotingAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/viewLandProjects/',
                  element: <ViewLandProjects />,
                  requiredRoles: [AdminRoles.VotingAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/set-states',
                  element: <SetStates />,
                  requiredRoles: [AdminRoles.SuperAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/add-promotion-email',
                  element: (
                    <PromotionEmailAddAndEdit
                      ActionType={PEcomponentAction.ADD}
                    />
                  ),
                  requiredRoles: [AdminRoles.NewsAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/edit-promotion-email/:id',
                  element: (
                    <PromotionEmailAddAndEdit
                      ActionType={PEcomponentAction.EDIT}
                    />
                  ),
                  requiredRoles: [AdminRoles.NewsAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/manual-promotion-email-list',
                  element: (
                    <ViewPromotionEmails
                      emailDeliveryType={EmailDeliveryTypeEnum.MANUAL}
                    />
                  ),
                  requiredRoles: [AdminRoles.NewsAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/auto-promotion-email-list',
                  element: (
                    <ViewPromotionEmails
                      emailDeliveryType={EmailDeliveryTypeEnum.AUTO}
                    />
                  ),
                  requiredRoles: [AdminRoles.NewsAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/email-templates-list',
                  element: <EmailTemplateList />,
                  requiredRoles: [AdminRoles.NewsAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/email-template-edit/:emailTemplateName',
                  element: <EmailTemplateEdit />,
                  requiredRoles: [AdminRoles.NewsAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/governance/community-ideas',
                  element: <ViewCommunityIdeas />,
                  requiredRoles: [AdminRoles.VotingAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/editQuestion/:voteTopicID',
                  element: <EditQuestion />,
                  requiredRoles: [AdminRoles.VotingAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/deleteQuestion',
                  element: <DeleteQuestion />,
                  requiredRoles: [AdminRoles.VotingAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/setting/fees-management',
                  element: <FeeManagement />,
                  requiredRoles: [AdminRoles.SuperAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/setting/reference-code-management',
                  element: <ReferenceCodeManagement />,
                  requiredRoles: [AdminRoles.SuperAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/news/create',
                  element: <AddNews />,
                  requiredRoles: [AdminRoles.NewsAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/news/operation',
                  element: <DeleteUpdateViewNews />,
                  requiredRoles: [AdminRoles.NewsAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/news/addNewsCategory',
                  element: <AddNewsCategory />,
                  requiredRoles: [AdminRoles.NewsAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/news/editNewsCategory/:id',
                  element: <EditNewsCategory />,
                  requiredRoles: [AdminRoles.NewsAdmin],
                })}
                {renderRoute({
                  path: '/dashboard/news/viewNewsCategory/',
                  element: <ViewNewsCategory />,
                  requiredRoles: [AdminRoles.NewsAdmin],
                })}

                {renderRoute({
                  path: '/dashboard/view-transactions',
                  element: <AdminViewTransaction />,
                  requiredRoles: [
                    AdminRoles.BankAdmin,
                    AdminRoles.FinanceAdmin,
                  ],
                })}
              </>
            ) : (
              <>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/login/forgot/"
                  element={<ResetPasswordRequestPage />}
                />
                <Route
                  path="/login/reset/:token"
                  element={<ResetPasswordPage />}
                />
              </>
            )}
            <Route path="/design/" element={<QDesignSystem />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </>
      </AppContextProvider>
    </BrowserRouter>
  )
}

export default App
