import React, { useEffect, useState } from 'react'
import Header from '../../component/Sidbar'
import Footer from '../../component/Footer'
import { Link, useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import axios from 'axios'
import moment from 'moment'
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Button,
  ModalFooter,
} from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { toast } from 'react-toastify'


import { capitalizeFirstLetter } from '../../utils/utilities'
import { EmailTemplateRenderedData } from '../../utils/types'
import { getAllEmailTemplates, getDynamicEmailRenderedHtml } from '../../api'
import { text } from 'stream/consumers'
import LoadingBlock from '../../component/misc/smallComponents'
import { DynamicEmailRequestObjectInterface, EmailTemplateDataInterface, EmailTemplateFullDataType, } from '../../utils/email-misc/frontend-email-template-specific-data'


export default function EmailTemplateList() {


  const [previewModalLoading, setPreviewModalLoading] = useState(false)
  const [previewModal, setPreviewModal] = useState(false)



  const togglePreviewModal = () => {
    setPreviewModal(!previewModal)
  }


  const [dataList, setDataList] = useState<EmailTemplateDataInterface[]>([])

  const renderDynamicEmail = async (requestObject: DynamicEmailRequestObjectInterface) => {
    setPreviewModalLoading(true)
    try {
      let res = await getDynamicEmailRenderedHtml(requestObject)
      if (res) {

        console.log('res.data:', res.data)
        setPreviewModalLoading(false)
        setrenderedHTML(res.data)
      }
    } catch (error) {
      console.error('Error rendering dynamic email:', error)
      setPreviewModalLoading(false)
      return

    }

  }

  const [renderedHTML, setrenderedHTML] =
    useState<EmailTemplateRenderedData>({
      html: "",
      text: ""
    })

  const navigate = useNavigate()



  const getAllData = async () => {
    getAllEmailTemplates().then((res) => {
      console.log('res:', res)
      //@ts-ignore
      setDataList(Object.values(res.data))
    })
  }
  useEffect(() => {
    getAllData()
  }, [])
  const previewEmailInModalIframe = (emailTemplateName: EmailTemplateFullDataType) => {
    togglePreviewModal()
    renderDynamicEmail({ emailTemplateName })
  }



  return (
    <>
      {/* <div>{showChildComponent && <EditQuestions data={id} />}</div> */}
      <div className="container">
        <ol
          className="breadcrumb"
          style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
        >
          <li className="breadcrumb-item">
            <Link to="/">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">Email Templates</li>


        </ol>
        <table
          className="table table-bordered table-striped table-hover "

          width="100%"
          cellSpacing="0"
        >
          <thead className='thead-dark'>
            <tr>

              <th>S.No.</th>
              <th>Template ID</th>
              <th>Title/Desc</th>
              <th>Template Origin</th>

              <th

              >Action</th>
            </tr>
          </thead>
          <tbody>
            {dataList.map(
              (emailTemplate: EmailTemplateDataInterface, index: number) => {
                return (
                  <tr key={emailTemplate.emailTemplateName}>
                    <td>
                      <b>{index + 1}.</b>
                    </td>

                    <td >
                      <b>{emailTemplate.emailTemplateName}</b>
                    </td>
                    <td>
                      <b>{emailTemplate.title}.</b>
                    </td>
                    <td>
                      <b>{emailTemplate.isFromUser ? <span style={{ color: "black" }}>User</span> : <span style={{ color: "red" }}>Admin</span>}</b>
                    </td>


                    <td>
                      <QButton
                        size="sm"
                        onClick={() => {

                          previewEmailInModalIframe(emailTemplate.emailTemplateName)
                        }}
                        round
                        className="me-2"
                      >
                        Preview
                      </QButton>
                      {/* <QButton
                        size="sm"
                        onClick={() => {
                          navigate('/dashboard/email-template-edit/' + emailTemplate.emailTemplateName)

                        }}
                        round
                        className="me-2"
                      >
                        Edit
                      </QButton> */}
                      <Link
                        className="me-2 btn btn-primary btn-round"
                        to={'/dashboard/email-template-edit/' + emailTemplate.emailTemplateName}
                      >
                        Edit
                      </Link>




                    </td>


                  </tr>
                )
              },
            )}
          </tbody>
        </table>


        <Modal
          isOpen={previewModal}
          toggle={togglePreviewModal}
          className="modal-dialog-centered "
          scrollable
          // size="lg"
          fullscreen
          unmountOnClose={true}
        >
          <ModalHeader>Email Content Preview</ModalHeader>
          <ModalBody>
            {previewModalLoading ? <LoadingBlock /> : <iframe srcDoc={renderedHTML?.html} width="100%" style={{
              height: "100%",
              border: "none"
            }} title="Email Content Preview" />}



          </ModalBody>

          <ModalFooter>
            <QButton round className="me-3" onClick={togglePreviewModal}>
              Close
            </QButton>
          </ModalFooter>
        </Modal>





      </div>



    </>
  )
}
