import React, { useEffect, useState } from 'react'
import Header from '../../component/Sidbar'
import Footer from '../../component/Footer'
import { Link, useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import axios from 'axios'
import moment from 'moment'
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Button,
  ModalFooter,
} from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { toast } from 'react-toastify'
import { getAllLandProjects } from '../../api'
import { testAxios } from '../../utils/constants'
import { LandProjectObjectInteface } from '../../utils/types'
export default function ViewLandProjects() {
  const [deleteModal, setdeleteModal] = useState(false)
  const [viewModal, setviewModal] = useState(false)
  const [deleteLoading, setdeleteLoading] = useState(false)
  const toggleDeleteModal = () => {
    setdeleteModal(!deleteModal)
  }
  const toggleViewModal = () => {
    setviewModal(!viewModal)
  }
  const toggleEditModal = () => {
    setdeleteModal(!deleteModal)
  }
  const [data, setData] = useState<LandProjectObjectInteface[]>([])
  const [questionId, setQuestionId] = useState<any>('')
  const [id, setId] = useState<any>()
  const [activeData, setactiveData] =
    useState<LandProjectObjectInteface | null>(null)

  const navigate = useNavigate()

  const DeleteProject = async () => {
    try {
      setdeleteLoading(true)
      const url =
        process.env.REACT_APP_SERVER_URI + `/land-project/${activeData?._id}`

      console.log('url:', url)
      const response = await testAxios.delete(url)
      setdeleteLoading(false)
      toggleDeleteModal()
      toast.success(response.data.message, { position: 'bottom-center' })
      getAllData()
    } catch (e) {
      console.log('e:', e)
    }
  }

  const getAllData = async () => {
    getAllLandProjects().then((res) => {
      console.log('res:', res)
      //@ts-ignore
      setData([...(res.data as LandProjectObjectInteface[])]) // <--
    })
  }
  useEffect(() => {
    getAllData()
  }, [])

  return (
    <>
      {/* <div>{showChildComponent && <EditQuestions data={id} />}</div> */}
      <div>
        

        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">View Land Projects</li>
              <li>
                <QButton
                  onClick={() => navigate('/addLandProject')}
                  round
                  size="sm"
                  className="ms-3"
                >
                  Add Land Project
                </QButton>
              </li>
            </ol>
            <table
              className="table table-bordered"
              
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Land Project Name</th>
                  <th>Type</th>
                  <th>Location</th>
                  {/* <th>Project Details</th> */}

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(
                  (project: LandProjectObjectInteface, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <b>{index + 1}.</b>
                        </td>
                        <td>
                          <b>{project.projectName}.</b>
                        </td>
                        <td>
                          <b>{project.type}.</b>
                        </td>
                        <td>
                          <b>{project.location}</b>
                        </td>

                        {/* <td>{project.landProjectPrimaryImage}</td> */}

                        <td>
                          <QButton
                            size="sm"
                            onClick={() => {
                              // navigate(`/editQuestion?id=${project._id}`)
                              // navigate('/editProject/' + project._id)
                              // navigate(`/feemanagement`)
                              if (project._id) {
                                setactiveData(project)
                                toggleViewModal()
                              }
                            }}
                            round
                            className="me-2"
                          >
                            View
                          </QButton>
                          <QButton
                            size="sm"
                            onClick={() => {
                              // navigate(`/editQuestion?id=${project._id}`)
                              navigate('/editLandProject/' + project._id)
                              // navigate(`/feemanagement`)
                            }}
                            round
                            className="me-2"
                          >
                            Edit
                          </QButton>
                          <QButton
                            size="sm"
                            onClick={() => {
                              if (project._id) {
                                setactiveData(project)
                                toggleDeleteModal()
                              }
                            }}
                            round
                          >
                            Delete
                          </QButton>
                          {/* <EditIcon /> */}
                        </td>
                        {/* <td
                    
                      >
                        <DeleteOutlineIcon />
                      </td> */}
                      </tr>
                    )
                  },
                )}
              </tbody>
            </table>
            <Modal
              isOpen={viewModal}
              toggle={toggleViewModal}
              className="modal-dialog-centered "
              scrollable
              size="lg"
              unmountOnClose={true}
            >
              <ModalHeader>Land Projec Details</ModalHeader>
              <ModalBody>
                {activeData && (
                  <div className="details-in-modal">
                    <div className="static-text-content-page">
                      <div className="image">
                        <img
                          src={activeData.landProjectPrimaryImage}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="data-block-list">
                        <div className="data-block">
                          <h5>Land Project Name:</h5>
                          <p>
                            <b>{activeData.projectName}.</b>
                          </p>
                        </div>
                        <div className="data-block">
                          <h5>Land Project type:</h5>
                          <p>
                            <b>{activeData.type}.</b>
                          </p>
                        </div>
                        <div className="data-block">
                          <h5>Land Project location:</h5>
                          <p>
                            <b>{activeData.location}.</b>
                          </p>
                        </div>
                        <div className="data-block">
                          <h5>Land Project key Details And Statistics:</h5>
                          <div
                            className="dd"
                            dangerouslySetInnerHTML={{
                              __html: activeData.keyDetailsAndStatistics,
                            }}
                          ></div>
                          
                          
                        </div>
                        <div className="data-block">
                          <h5>Land Project Summary:</h5>
                          <div
                            className="dd"
                            dangerouslySetInnerHTML={{
                              __html: activeData.projectSummary,
                            }}
                          ></div>
                          
                          
                        </div>
                        <div className="data-block">
                          <h5>Land Project details:</h5>
                          <div
                            className="dd"
                            dangerouslySetInnerHTML={{
                              __html: activeData.projectDetails,
                            }}
                          ></div>
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </ModalBody>

              <ModalFooter>
                <QButton round className="me-3" onClick={toggleViewModal}>
                  Close
                </QButton>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={deleteModal}
              toggle={toggleDeleteModal}
              className="modal-dialog-centered "
              scrollable
              // size="lg"
              unmountOnClose={true}
            >
              <ModalHeader>Confirm.</ModalHeader>
              <ModalBody>Do you want to delete this Project ?</ModalBody>

              <ModalFooter>
                <QButton round className="me-3" onClick={toggleDeleteModal}>
                  Close
                </QButton>
                <QButton round loading={deleteLoading} onClick={DeleteProject}>
                  Delete
                </QButton>
              </ModalFooter>
            </Modal>
          </div>
        </div>

        
      </div>
    </>
  )
}
