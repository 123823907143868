/* eslint-disable no-lone-blocks */
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  ONE_USD_TO_CENTS,
  OrderStatus,
  SortDirection,
  SortFieldsEnumForTransactionHistoryQuery,
} from '../../utils/types'
import {
  formatNumberWithCommasUptoTwoDecimalWithFloor,
  formatUnixTimestamp,
} from '../../utils/utilities'
import {
  getUsersForSquarePayouts,
  postApproveBuySquareOrder,
  postApproveSquarePayout,
  postDisApproveBuyOrder,
} from '../../api'
import { PayoutInfo } from '../../utils/types'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
} from 'reactstrap'
import { TableTopFilters } from '../../component/misc/smallComponents'
import { toast } from 'react-toastify'
import SinglePayoutComponent from '../../component/orders/SinglePayoutComponent'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'

const SquarePayoutsPendingPurchase = () => {
  const [state, setState] = useState<any>({
    sortDirection: SortDirection.desc,
    isExportDownloadLoading: false,
    dataList: [],
    searchTerm: '',
    currentPage: 1,
    pageSize: 50,
    searchType: 'name',
    unitType: '',
    paymentMethod: '',
    fromDate: '',
    toDate: '',
    sortBy: SortFieldsEnumForTransactionHistoryQuery.CreatedAt,
    userType: '',
  })
  const [modal, setModal] = useState(false)
  const [approveORdisApproveLoading, setapproveORdisApproveLoading] =
    useState<boolean>(false)

  const toggle = () => setModal(!modal)
  const [orderId, setOrderId] = useState<string>('')
  const [loadingInitialData, setLoadingInitialData] = useState<boolean>(false)

  const getData = async () => {
    setLoadingInitialData(true)
    const {
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
      userType,
    } = state
    getUsersForSquarePayouts(
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
      userType,
    )
      .then((res) => {
        setState((prevState: any) => ({
          ...prevState,
          dataList: res.data,
        }))
      })
      .catch((error) => {
        toast.error(error.response.data.message)
      })
      .finally(() => {
        setLoadingInitialData(false)
      })
  }

  const handleApprove = () => {
    setapproveORdisApproveLoading(true)
    setModal(false)
    const id = toast.loading('Please wait...')

    const orderIdData = {
      orderId: orderId,
    }

    postApproveBuySquareOrder(orderIdData)
      .then(({ data }) => {
        setapproveORdisApproveLoading(false)
        const { orderId: orderIdSuccess, orderStatus } = data.order
        setState((prevState: any) => {
          const updatedDataList = prevState.dataList.map((data: any) => ({
            ...data,
            userInfo: data.userInfo.map((user: any) =>
              user.orderId === orderIdSuccess
                ? { ...user, orderStatus: orderStatus }
                : user,
            ),
          }))
          return {
            ...prevState,
            dataList: updatedDataList,
          }
        })
        toast.update(id, {
          render: data.message,
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        })
      })
      .catch((error) => {
        toast.update(id, {
          render: error.response.data.message,
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        })
        setapproveORdisApproveLoading(false)
      })
  }
  const handleDisApprove = () => {
    const id = toast.loading('Please wait...')
    setapproveORdisApproveLoading(true)
    setModal(false)

    const orderIdData = {
      orderId: orderId,
    }

    postDisApproveBuyOrder(orderIdData)
      .then(({ data }) => {
        setapproveORdisApproveLoading(false)
        const { orderId: orderIdSuccess, orderStatus } = data.order
        setState((prevState: any) => {
          const updatedDataList = prevState.dataList.map((data: any) => ({
            ...data,
            userInfo: data.userInfo.map((user: any) =>
              user.orderId === orderIdSuccess
                ? { ...user, orderStatus: orderStatus }
                : user,
            ),
          }))
          return {
            ...prevState,
            dataList: updatedDataList,
          }
        })
        toast.update(id, {
          render: data.message,
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        })
      })
      .catch((err) => {
        toast.update(id, {
          render: err.response.data.message,
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        })
        setapproveORdisApproveLoading(false)
      })
  }
  const handleApprovePayout = async (payout: PayoutInfo) => {
    const isValid = payout.userInfo.every(
      (user) =>
        user.orderStatus === OrderStatus.Completed ||
        user.orderStatus === OrderStatus.Approved ||
        user.orderStatus === OrderStatus.Cancelled,
    )

    // If any of the orders is not of the above status, show an error
    if (!isValid) {
      alert(
        "Cannot approve payout. Some orders are not in the 'PaymentSent' or 'Approved' or 'Cancelled' status.",
      )
      return
    }
    const data = {
      payoutId: payout.payoutId,
    }

    const id = toast.loading('Please wait...')
    setapproveORdisApproveLoading(true)
    setModal(false)
    postApproveSquarePayout(data)
      .then(({ data }) => {
        const { payoutId, isProcessed } = data.payout
        setState((prevState: any) => {
          const newDataList = prevState.dataList.filter(
            (payout: PayoutInfo) => payout.payoutId !== payoutId && isProcessed,
          )
          if (newDataList.length === 0) {
            getData()
          }

          return { ...prevState, dataList: newDataList }
        })
        setapproveORdisApproveLoading(false)

        toast.update(id, {
          render: data.message,
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        })
      })
      .catch((err) => {
        toast.update(id, {
          render: err.response.data.message,
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        })
        setapproveORdisApproveLoading(false)
      })
  }

  useEffect(() => {
    getData()
  }, [state.currentPage, state.fromDate, state.toDate, state.userType])

  const resetAllFiltersAndSort = () => {
    setState({
      sortDirection: SortDirection.desc,
      dataList: [],
      searchTerm: '',
      searchType: 'name',
      unitType: '',
      orderType: '',
      paymentMethod: '',
      startDate: null,
      endDate: null,
      sortBy: SortFieldsEnumForTransactionHistoryQuery.CreatedAt,
    })
    getData()
  }

  const handleExportOrders = async () => {
    setState((prevState: any) => ({
      ...prevState,
      isExportDownloadLoading: true,
    }))
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        userType,
      } = state
      const response = await getUsersForSquarePayouts(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        userType,
        true,
      )

      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'orders.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    }
  }

  return (
    <div>
      <div className="content-wrapper ">
        <div className="container-fluid">
          <ol
            className="breadcrumb"
            style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
          >
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              Square Orders for approval
            </li>
          </ol>
          <TableTopFilters
            state={state}
            setState={setState}
            getData={getData}
            handleExportOrders={handleExportOrders}
          />
          {loadingInitialData && <h3 className="text-center">Loading...</h3>}

          {!loadingInitialData && state?.dataList.length === 0 && (
            <h1 className=" text-info text-center">No Square Payout Found</h1>
          )}

          {!loadingInitialData && state.dataList.length !== 0 && (
            <div className="card mb-3">
              <div className="card-body">
                {state?.dataList &&
                  state.dataList.map((payout: PayoutInfo, index: number) => (
                    <div key={index} className="row payout-row">
                      <div className="col-md-3">
                        <p>
                          Arrival Date:{' '}
                          <strong>
                            {formatUnixTimestamp(payout.arrival_date)}
                          </strong>
                        </p>
                      </div>
                      <div className="col-md-3">
                        <p>
                          Payout ID: <strong>{payout.payoutId}</strong>
                        </p>
                      </div>

                      <div className="col-md-2">
                        <p>
                          Quantum2 calculation:{' '}
                          <strong>
                            {formatNumberWithCommasUptoTwoDecimalWithFloor(
                              payout.totalPaymentAmountInCentsWithoutCardFees /
                                ONE_USD_TO_CENTS,
                            )}{' '}
                            {payout.currency}
                          </strong>
                        </p>
                      </div>
                      <div className="col-md-2">
                        <p>
                          Square Sum Paid:{' '}
                          <strong>
                            {Math.abs(payout.totalPayoutAmount) /
                              ONE_USD_TO_CENTS}{' '}
                            {payout.currency}
                          </strong>
                        </p>
                      </div>
                      <div className="col-md-2">
                        <QButton
                          className="btn btn-primary"
                          disabled={approveORdisApproveLoading}
                          onClick={(e) => handleApprovePayout(payout)}
                        >
                          Approve Payout
                        </QButton>
                      </div>
                      <SinglePayoutComponent
                        payout={payout}
                        index={index}
                        setOrderId={setOrderId}
                        toggle={toggle}
                        approveORdisApproveLoading={approveORdisApproveLoading}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}
          <Modal
            isOpen={modal}
            toggle={toggle}
            centered
            scrollable
            onClosed={() => {}}
          >
            <ModalHeader>Approve User Purchase Order</ModalHeader>
            <ModalBody>
              <div className="d-flex justify-content-between">
                <Button
                  color="success"
                  onClick={() => {
                    handleApprove()
                  }}
                >
                  Approve
                </Button>

                <Button
                  color="primary"
                  onClick={() => {
                    handleDisApprove()
                  }}
                >
                  Dis-Approve
                </Button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Close
              </Button>{' '}
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default SquarePayoutsPendingPurchase
