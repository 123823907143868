import { EmailTemplateFullData } from "./frontend-email-template-specific-data";





const CurrentValueStatementPDF = ``;
const CancelBankInvoiceEmail = `
<p>
  Dear
  <b> @name,</b>
</p>

<p>
  Your bank payment order with ID <b>@orderId</b> for <b>@BuyLandUnitCountAndType</b> has been cancelled.
</p>

<p>
  Payment Sum: <b>@PayoutAmountWithCurrencySymbol</b> (including @TransactionFee transaction fee).
</p>

<p>
  Payment reference: <b>@orderId</b>
</p>

@Add-Gray-Block-Section-Start
<h4>Payment Details</h4>

<p>
  Payment Sum: <b>@PayoutAmountWithCurrencySymbol</b>
</p>

<p>
  Transaction Fee: <b>@TransactionFee</b>
</p>

<p>
  Payment reference: <b>@orderId</b>
</p>
@Add-Gray-Block-Section-End

<p>If you have any questions, email us at <a href="mailto:service@Quantum2.io" target="_blank">service@Quantum2.io</a>.</p>

<p>Best regards,</p>

<p><b>Quantum2 Team</b></p>

`;
const CreateBankInvoiceEmail = `
<p><b>Dear @name,</b></p>

<p>You recently made an order for @SaleLandUnitCountAndType</p>

<p>Order ID: <b>@orderId</b></p>

<p>Payment Sum: <b>@PayoutAmountWithCurrencySymbol (including @TransactionFee transaction fee)</b></p>

<p>Payment Reference Number: <b>@PaymentReference</b></p>

<p>Please transfer the payment sum of <b>@PayoutAmountWithCurrencySymbol</b> within the next 3 hours to complete your purchase using the following bank details:</p>

@Add-Gray-Block-Section-Start
<h4>Bank Details</h4>

<p><b>US Payment:</b> Quantum2 OU, Community Federal Savings Bank, ACH and Wire Routing Number: 026073150, Account Number: 8310082679, Account Type: Checking, United States</p>

<p><b>UK Payment:</b> Quantum2 OU, Sort Code: 23-14-70, Account Number: 49441061, IBAN: GB13 TRWI 2314 7049 4410 61, United Kingdom</p>

<p><b>European Payment:</b> Quantum2 OU, IBAN: BE61 9670 1989 9817, BIC: TRWIBEB1XXX, Belgium</p>

<p><b>Australian Payment:</b> Quantum2 OU, BSB Code: 774001, Account Number: 218240900, Australia</p>
@Add-Gray-Block-Section-End

<p>In your transfer, select all bank fees to be paid by you, or the bank may deduct a fee from your transfer sum.</p>

<p>Be sure to use the exact bank details above.</p>

<p>Be sure to include the exact reference number <b>@PaymentReference</b> in the transfer.</p>

<p>Please do not make any errors in the details, otherwise, the transfer may be incorrect and we will be unable to complete the transaction.</p>

<p>Once your deposit has been received, the balance in your Dashboard will update the next time you log in. <a href="https://quantum2.io/dashboard" target="_blank">View Dashboard</a></p>

<p>After 7 days, if the sum is not received, the order will be cancelled.</p>

<p>If we must return any funds due to an error, we must deduct bank and admin fees.</p>

<p>If you have any questions, email us at <a href="mailto:service@Quantum2.io" target="_blank">service@Quantum2.io</a>.</p>

<p>Best regards,</p>

<p><b>Quantum2 Team</b></p>

`;
const CreateConfirmPurchaseEmail = ``;
const CreateContactFormEmail = ``;
const CreateConvertOrderEmail = ``;
const CreateCryptoOrderExpiredEmail = ``;
const CreateCryptoOrderInvalidEmail = ``;
const CreateCryptoOrderPaidEmail = ``;
const CreatePasswordChangeEmail = ``;
const CreateResetPasswordEmail = ``;
const CreateVerificationEmail = ``;
const CreateVerificationEmailForSubscribe = ``;


const CreateNewsEmail = `

  <p>Here is the latest article from Quantum2</p>
  <p>@newsTitle</p>
  <p>
    Click here to read more: 
    <br />
    <a href="@newsLink" className="text-red-600">@newsLink</a>
  </p>
  <p>Best regards,</p>
  <p><b>Quantum2 Team</b></p>

`;


const CreateSellOrderEmail = `<div>
              <p>
                Dear
                <b> @name,</b>
              </p>

              <p>
                This email confirms you sold 
                @SaleLandUnitCountAndType
              </p>
              <p>
                Your Order ID:
                @orderId
              </p>
              <p>
                Expected Payout Amount:
                @PayoutAmountWithCurrencySymbol
              </p>
              <p>
                Transaction Fee:
                @TransactionFee
              </p>
              @Add-Gray-Block-Section-Start
                <h4>
                  Payment Details
                </h4>
                <p>
                  Order ID:
                  <b>@orderId</b>
                </p>
                <p>
                  Expected Payout Amount:
                  
                  <b>@PayoutAmountWithCurrencySymbol</b>
                </p>
                <p>
                  Transaction Fee:
                  <b>@TransactionFee</b>
                </p>
              @Add-Gray-Block-Section-End

              <p>If you have any questions, email us at <a href="mailto:service@Quantum2.io" target="_blank">service@Quantum2.io</a>.</p>
              
              <p>
                Best regards,
              </p>
              <p>
                <b> Quantum2 Team</b>
              </p>
            </div>
`;
const CreateTransferOrderEmail = `
<div>
  <p>
    Dear <b>@name</b>,
  </p>
  
  <p>
    This email confirms you transferred @LandUnitCountAndType to @recipientEmail (@recipientName).
  </p>

  <p>
    If you have any questions, email us at 
    <a href="mailto:service@Quantum2.io" className="text-red-600">service@Quantum2.io</a>.
  </p>

  <p>Best regards,</p>

  <p><b>Quantum2 Team</b></p>
</div>
`;





export const dynamicEmailTemplateInitialTexts: {
  [key in keyof typeof EmailTemplateFullData]: string;
} = {
  CreateNewsEmail,
  CreateTransferOrderEmail,
  CreateSellOrderEmail,
  CurrentValueStatementPDF,
  CancelBankInvoiceEmail,
  CreateBankInvoiceEmail,
  CreateConfirmPurchaseEmail,
  CreateContactFormEmail,
  CreateConvertOrderEmail,
  CreateCryptoOrderExpiredEmail,
  CreateCryptoOrderInvalidEmail,
  CreateCryptoOrderPaidEmail,
  CreatePasswordChangeEmail,
  CreateResetPasswordEmail,
  CreateVerificationEmail,
  CreateVerificationEmailForSubscribe,

}

