import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getDynamicEmailRenderedHtml, getDynamicEmailText, updateDynamicEmailText } from '../../api';
import {  EmailTemplateRenderedData } from '../../utils/types';
import { toast } from 'react-toastify'


import { CustomReactQuillEmailTemplateEditor } from '../../component/misc/CustomReactQuilComponentsArchive';
import { DynamicEmailRequestObjectInterface, EmailTemplateFullData, EmailTemplateFullDataType } from '../../utils/email-misc/frontend-email-template-specific-data';
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoadingBlock from '../../component/misc/smallComponents';




export default function EmailTemplateEdit() {
  const [renderedHTML, setrenderedHTML] =
    useState<EmailTemplateRenderedData>({
      html: "",
      text: ""
    })

  const { emailTemplateName } = useParams<{ emailTemplateName: EmailTemplateFullDataType }>();
  const [DynamicText, setDynamicText] = useState<string>("");


  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [updateLoading, setupdateLoading] = React.useState(false)

  const getSingleData = async (emailTemplateName: string) => {
    setLoading(true)
    getDynamicEmailText(emailTemplateName).then((res) => {
      setLoading(false)
      console.log('res:', res)
      //@ts-ignore
      setDynamicText(res.data.DynamicText)
    }).catch((error) => {
      goBack();
    })
    setLoading(false)
  }
  useEffect(() => {
    if (emailTemplateName) {
      getSingleData(emailTemplateName)
    }
  }, [emailTemplateName]);



  const [previewModalLoading, setPreviewModalLoading] = useState(false)
  const [previewModal, setPreviewModal] = useState(false)



  const togglePreviewModal = () => {
    setPreviewModal(!previewModal)
  }

  const renderDynamicEmail = async (requestObject: DynamicEmailRequestObjectInterface) => {
    setPreviewModalLoading(true)
    try {
      let res = await getDynamicEmailRenderedHtml(requestObject)
      if (res) {

        console.log('res.data:', res.data)
        setPreviewModalLoading(false)
        setrenderedHTML(res.data)
      }
    } catch (error) {
      console.error('Error rendering dynamic email:', error)
      setPreviewModalLoading(false)
      return

    }

  }
  const saveDynamicEmailText = async () => {
    setupdateLoading(true)
    try {
      if (emailTemplateName) {
        let res = await updateDynamicEmailText({
          emailTemplateName,
          DynamicText
        })
        if (res) {
          setupdateLoading(false)
          toast.success('Email Template Updated Successfully')
          console.log('res.data:', res.data)


        }
      }
    } catch (error) {
      console.error('Error rendering dynamic email:', error)
      toast.error('Email Template Update failed')
      setupdateLoading(false)
      return

    }

  }

  const previewEmailInModalIframe = () => {
    if (emailTemplateName) {
      togglePreviewModal()
      renderDynamicEmail({ emailTemplateName, DynamicText })
    }
  }
  const goBack = () => {
    navigate("/dashboard/email-templates-list")
  }
  return (
    <div>
      <div className="">
        <div className="container ">
          <div className="row">
            <div className="col-md-12">
              <ol
                className="breadcrumb"
                style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
              >
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/dashboard/email-templates-list">Email Templates</Link>
                </li>
                <li className="breadcrumb-item active">Email Template : {emailTemplateName ? EmailTemplateFullData[emailTemplateName].title : ""}</li>


              </ol>
            </div>

          </div>
          <div className="col-md-12 ">

            {emailTemplateName && <>
              <CustomReactQuillEmailTemplateEditor
                emailTemplateName={emailTemplateName}
                className={'email-template-editor'}
                theme="snow"
                value={DynamicText}
                onChange={(v: string) => setDynamicText(v)}
                style={{
                  height: "50vh",
                  marginBottom: 60,
                }}

              />
              <div className="d-flex justify-content-between">
                <div>

                  <QButton
                    loading={loading }

                    onClick={() => {
                      previewEmailInModalIframe()
                    }
                    }
                  >Preview Changes</QButton>
                  <QButton
                    onClick={saveDynamicEmailText}

                    color='success' className='ms-4'
                    loading={ updateLoading}
                  >Update</QButton>
                </div>
                <div>
                  <QButton

                    color='danger' onClick={() => goBack()}>Go Back</QButton>
                </div>
              </div>
            </>
            }
          </div>
        </div>
      </div>

      <Modal
        isOpen={previewModal}
        toggle={togglePreviewModal}
        className="modal-dialog-centered "
        scrollable
        // size="lg"
        fullscreen
        unmountOnClose={true}
      >
        <ModalHeader>Email Content Preview</ModalHeader>
        <ModalBody>
          {previewModalLoading ? <LoadingBlock /> : <iframe srcDoc={renderedHTML?.html} width="100%" style={{
            height: "100%",
            border: "none"
          }} title="Email Content Preview" />}



        </ModalBody>

        <ModalFooter>
          <QButton round className="me-3" onClick={togglePreviewModal}>
            Close
          </QButton>
        </ModalFooter>
      </Modal>

    </div>
  )
}
