import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  UnitType,
  OrderType,
  PaymentMethodEnum,
  Transaction,
  UserType,
  UnitDisplayNames,
  CurrencyDisplaySymbol,
  SortFieldsEnumForTransactionHistoryQuery,
  SortDirection,
} from '../../utils/types'
//@ts-ignore

import {
  ONE_AUD_TO_CENTS,
  displayPaymentAmount,
  formatDate,
  formatNumberWithCommas,
  formatNumberWithCommasUptoTwoDecimalWithFloor,
  getEnteredByName,
} from '../../utils/utilities'
import { OrderStatus } from '../../utils/types'
import './index.scss'
import { getUsersTransactions } from '../../api'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import SingleDatePickerComponent from '../../component/SingleDatePickerComponent'
import moment from 'moment'
import { toast } from 'react-toastify'
import {
  SelectComponent,
  SelectComponentValue,
  TRInfo,
} from '../../component/misc/smallComponents'
import { SvgIcons } from '../../component/misc/SvgIcons'
import { camelCaseToSpaced } from '../../utils/common'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { transactionOrderStatusColorMap } from '../../utils/constants'
export default function AdminViewTransaction() {
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchType, setSearchType] = useState('name') // Default search type is name
  const [orderStatus, setOrderStatus] = useState<string | OrderStatus>('')
  const [paymentMethod, setPaymentMethod] = useState<
    string | PaymentMethodEnum
  >('')
  const [orderType, setorderType] = useState<string | OrderType>('')
  const [userType, setUserType] = useState<string | UserType>('')
  const [unitType, setUnitType] = useState<string | UnitType>('')

  const [sortBy, setSortBy] =
    useState<SortFieldsEnumForTransactionHistoryQuery>(
      SortFieldsEnumForTransactionHistoryQuery.UpdatedAt,
    )
  const [fromDate, setFromDate] = useState<string>('') // Initialize with an empty string
  const [toDate, setToDate] = useState<string>('') // Initialize with an empty string
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.desc,
  )
  useEffect(() => {
    getAllTransation()
  }, [
    currentPage,
    fromDate,
    toDate,
    orderType,
    orderStatus,
    unitType,
    paymentMethod,
    userType,
    sortBy,
    sortDirection,
  ])

  const getAllTransation = async () => {
    await getUsersTransactions(
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
      orderStatus,
      paymentMethod,
      orderType,
      userType,
      unitType,
      sortBy,
      sortDirection,
    )
      .then((response) => {
        setTransactions(response.data.orders)
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        })
        console.error('Error fetching combined data:', error)
      })
  }

  const handleSort = (
    property: SortFieldsEnumForTransactionHistoryQuery,
    curSortDirection: SortDirection = sortDirection,
  ) => {
    setSortBy(property)

    setSortDirection(curSortDirection)
  }
  const handleExportOrders = async () => {
    try {
      const response = await getUsersTransactions(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        orderStatus,
        paymentMethod,
        orderType,
        userType,
        unitType,
        sortBy,
        sortDirection,
        true,
      )
      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'orders.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
    }
  }

  return (
    <>
      <div className="card-ui-pages-content-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ol
                className="breadcrumb"
                style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
              >
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  View Transaction History
                </li>
              </ol>
              <div className="row mb-3 mt-3">
                <div className="col-lg-12">
                  <div className="tr-filter-wrapper">
                    <div className="row">
                      <div className="col-lg-3 mb-3">
                        <SelectComponent
                          placeholder="Order Type"
                          onChange={(value) => setorderType(value as OrderType)}
                          options={[
                            { value: '', label: 'ALL' },
                            ...Object.values(OrderType).map((txType) => ({
                              value: txType,
                              label: txType,
                            })),
                          ]}
                        />
                      </div>
                      <div className="col-lg-3 mb-3">
                        <SelectComponent
                          placeholder="Unit Type"
                          onChange={(value) => setUnitType(value as string)}
                          options={[
                            { value: '', label: 'ALL' },
                            ...Object.values(UnitType)
                              // .filter(
                              //   (currency) => currency !== UnitType.initial,
                              // )
                              .map((unitType) => ({
                                value: unitType,
                                label: UnitDisplayNames[unitType],
                              })),
                          ]}
                        />
                      </div>
                      <div className="col-lg-3 mb-3">
                        <SelectComponent
                          placeholder="Method"
                          onChange={(value) =>
                            setPaymentMethod(value as string)
                          }
                          options={[
                            { value: '', label: 'ALL' },
                            ...Object.values(PaymentMethodEnum).map(
                              (method) => ({
                                value: method,
                                label: method,
                              }),
                            ),
                          ]}
                        />
                      </div>
                      <div className="col-lg-3 mb-3">
                        <SelectComponent
                          placeholder="Status"
                          onChange={(value) => setOrderStatus(value as string)}
                          options={[
                            { value: '', label: 'ALL' },
                            ...Object.values(OrderStatus).map((status) => ({
                              value: status,
                              label: camelCaseToSpaced(status),
                            })),
                          ]}
                        />
                      </div>
                      <div className="col-lg-3 mb-3">
                        <SelectComponent
                          placeholder="Account Type"
                          onChange={(value) => setUserType(value as string)}
                          options={[
                            { value: '', label: 'ALL' },
                            ...Object.values(UserType).map((status) => ({
                              value: status,
                              label: camelCaseToSpaced(status),
                            })),
                          ]}
                        />
                      </div>

                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <SelectComponentValue
                              placeholder="Search By"
                              value={searchType}
                              onChange={(value) =>
                                setSearchType(value as string)
                              }
                              options={[
                                { value: 'name', label: 'Search By - Name' },
                                { value: 'email', label: 'Search By - Email' },
                              ]}
                            />
                          </div>
                          <div className="col-lg-6 mb-3">
                            <SearchInput
                              onClick={getAllTransation}
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 mb-3">
                        <div className="double-datepicker">
                          <SingleDatePickerComponent
                            setDate={(d) => setFromDate(d.format('YYYY-MM-DD'))}
                            date={fromDate ? moment(fromDate) : null}
                            id={'fromDate'}
                            placeholder="Start Date"
                            isBefore={false}
                          />
                          <SingleDatePickerComponent
                            setDate={(d) => setToDate(d.format('YYYY-MM-DD'))}
                            date={toDate ? moment(toDate) : null}
                            id={'toDate'}
                            isBefore={false}
                            placeholder="End Date"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 mb-3">
                        {/* <Label className="fz14 fw-semibold mb-0">Sort Order</Label> */}
                        <SelectComponentValue
                          value={sortDirection}
                          placeholder="Sort Order"
                          onChange={(sortDirection) =>
                            // setFilterPaymentMethod(value)
                            // @ts-ignore
                            handleSort(sortBy, sortDirection)
                          }
                          options={[
                            { value: SortDirection.asc, label: 'Ascending' },
                            { value: SortDirection.desc, label: 'Descending' },
                          ]}
                        />
                      </div>
                      <div className="col-lg-3 mb-3">
                        {/* <Label className="fz14 fw-semibold mb-0">Sory By</Label> */}
                        <SelectComponentValue
                          value={sortBy}
                          placeholder="Sory By"
                          onChange={(sortBy) => {
                            // @ts-ignore
                            handleSort(sortBy, sortDirection)
                          }}
                          options={[
                            {
                              label: 'Number of Units',
                              value: 'currencyUnit.numberOfUnits',
                            },
                            {
                              label: 'Value',
                              value: 'paymentDetails.paymentAmount',
                            },
                            { label: 'Date', value: 'createdAt' },
                          ]}
                        />
                      </div>
                      <div className="col-lg-3 mb-3">
                        <div className="d-flex align-items-center justify-content-between ">
                          <button
                            className="btn btn-primary ps-3 pe-3"
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(currentPage - 1)}
                          >
                            Prev
                          </button>
                          <p className="current-page-number ms-2 me-2 mb-0">
                            <strong>Page {currentPage}</strong>
                          </p>
                          <button
                            className="btn btn-primary ps-3 pe-3"
                            disabled={
                              transactions ? transactions.length === 0 : true
                            }
                            onClick={() => setCurrentPage(currentPage + 1)}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-3 mb-3">
                        <QButton
                          fullWidth
                          onClick={handleExportOrders}
                          className="ms-auto d-block"
                          size="ls"
                        >
                          Download Orders
                        </QButton>
                      </div>
                    </div>
                  </div>

                  <div className="tr-card-list">
                    {transactions?.map(
                      (transaction) =>
                        transaction && ( // Check if transaction is not null
                          <div className="tr-card">
                            <div className="info-icon-wrap">
                              <div className="ic-label-wrap">
                                <div
                                  className="ic-label withbg"
                                  style={{
                                    backgroundColor:
                                      transactionOrderStatusColorMap[
                                        transaction.orderStatus
                                      ],
                                  }}
                                >
                                  {SvgIcons.MoneyCash}
                                  <span>
                                    {camelCaseToSpaced(transaction.orderStatus)}
                                  </span>
                                </div>
                              </div>
                              <div className="ic-label-wrap">
                                <div className="ic-label withbg">
                                  {transaction.orderType ===
                                  OrderType.CONVERT ? (
                                    <strong className="ms-1 me-1"> From</strong>
                                  ) : (
                                    ''
                                  )}
                                  {transaction.orderType === OrderType.CONVERT
                                    ? SvgIcons.ChevronLeft
                                    : SvgIcons.CheckCircle}
                                  <span>
                                    {
                                      UnitDisplayNames[
                                        transaction.currencyUnit.unitType
                                      ]
                                    }
                                    {/* (
                                    {formatNumberWithCommas(
                                      transaction.currencyUnit.numberOfUnits,
                                    )}
                                    ) - {displayPaymentAmount(transaction)}{' '}
                                    {
                                      CurrencyDisplaySymbol[
                                        transaction.paymentDetails.currency
                                      ]
                                    }
                                     */}
                                  </span>
                                </div>
                              </div>
                              {transaction.orderType === OrderType.CONVERT &&
                                transaction.convert && (
                                  <div className="ic-label-wrap">
                                    <div className="ic-label withbg">
                                      <strong className="ms-1 me-1"> To</strong>

                                      {SvgIcons.ChevronRightRound}
                                      <span>
                                        {
                                          UnitDisplayNames[
                                            transaction.convert.unitType
                                          ]
                                        }
                                        {/* (
                                        {formatNumberWithCommas(
                                          transaction.convert.numberOfUnits,
                                        )}
                                        ) -{' '}
                                        {formatNumberWithCommasUptoTwoDecimalWithFloor(
                                          transaction.convert.paymentDetails
                                            .paymentAmount / ONE_AUD_TO_CENTS,
                                        )}{' '}
                                        {
                                          CurrencyDisplaySymbol[
                                            transaction.convert.paymentDetails
                                              .currency
                                          ]
                                        } */}
                                      </span>
                                    </div>
                                  </div>
                                )}

                              <div
                                className={
                                  'ic-label-wrap ' + transaction.orderType
                                }
                              >
                                <div
                                  className={
                                    'ic-label ' + transaction.orderType
                                  }
                                >
                                  {SvgIcons.ArrowDown}
                                  <span>{transaction.orderType}</span>
                                </div>
                              </div>

                              <div className="tr-info-wrap">
                                <TRInfo
                                  value={
                                    transaction.orderType === OrderType.BUY
                                      ? `+${formatNumberWithCommas(transaction.currencyUnit.numberOfUnits)}`
                                      : transaction.orderType ===
                                            OrderType.SELL ||
                                          transaction.orderType ===
                                            OrderType.CONVERT
                                        ? `-${formatNumberWithCommas(transaction.currencyUnit.numberOfUnits)}`
                                        : ''
                                  }
                                  label={`Number of ${transaction.orderType === OrderType.CONVERT ? `${UnitDisplayNames[transaction.currencyUnit.unitType]}` : 'Unit'}`}
                                />
                              </div>
                              {transaction.orderType === OrderType.CONVERT && (
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={`+${formatNumberWithCommas(transaction.convert?.numberOfUnits!)}`}
                                    label={`Number of ${UnitDisplayNames[transaction.convert!.unitType]}`}
                                  />
                                </div>
                              )}

                              <div className="tr-info-wrap">
                                <TRInfo
                                  value={
                                    <>
                                      {
                                        CurrencyDisplaySymbol[
                                          transaction.paymentDetails.currency
                                        ]
                                      }{' '}
                                      {displayPaymentAmount(transaction)}
                                    </>
                                  }
                                  label={`${transaction.orderType === OrderType.CONVERT ? 'Original Value' : 'Value'}`}
                                />
                              </div>
                              {transaction.orderType === OrderType.CONVERT && (
                                <TRInfo
                                  value={
                                    <>
                                      {
                                        CurrencyDisplaySymbol[
                                          transaction.convert!.paymentDetails
                                            .currency
                                        ]
                                      }{' '}
                                      {formatNumberWithCommasUptoTwoDecimalWithFloor(
                                        transaction.convert!.paymentDetails
                                          .paymentAmount / ONE_AUD_TO_CENTS,
                                      )}
                                      <br />
                                    </>
                                  }
                                  label={`New Value`}
                                />
                              )}
                            </div>

                            <div className="info-text">
                              <div className="info-text-col-wrap">
                                <div className="info-col">
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={
                                        transaction.user.firstName +
                                        ' ' +
                                        transaction.user.lastName
                                      }
                                      label={'Name'}
                                    />
                                  </div>
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={
                                        transaction &&
                                        formatDate(
                                          transaction.updatedAt?.toString(),
                                        )
                                      }
                                      label={'Date'}
                                    />
                                  </div>
                                </div>
                                <div className="info-col">
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={
                                        transaction.transfer
                                          ? transaction.user.email
                                          : '--'
                                      }
                                      label={'Sent From'}
                                    />
                                  </div>
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={
                                        transaction.transfer
                                          ? transaction.transfer.recipientEmail
                                          : '--'
                                      }
                                      label={'Sent To'}
                                    />
                                  </div>
                                </div>
                                <div className="info-col">
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={
                                        transaction.paymentDetails
                                          ?.paymentMethod
                                          ? transaction.paymentDetails
                                              ?.paymentMethod
                                          : '--'
                                      }
                                      label={'Payment Method'}
                                    />
                                  </div>
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={
                                        getEnteredByName(transaction.approvedBy)
                                          ? getEnteredByName(
                                              transaction.approvedBy,
                                            )
                                          : '--'
                                      }
                                      label={'Approved by'}
                                    />
                                  </div>
                                </div>
                                <div className="info-col">
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={transaction.orderId}
                                      label={'Quantum2 Order Id'}
                                    />
                                  </div>
                                  <div className="tr-info-wrap">
                                    <TRInfo
                                      value={
                                        transaction.buy?.paymentId
                                          ? transaction.buy.paymentId
                                          : '--'
                                      }
                                      label={'Stripe Payment Id/Bank Refrence'}
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* <p className='comments'>Note(Optional) : Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti, nostrum?</p> */}
                              {transaction.buy?.comments && (
                                <p className="comments">
                                  <b> Note:</b> {transaction.buy?.comments}
                                </p>
                              )}
                            </div>
                          </div>
                        ),
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
