import { AxiosError } from 'axios'
import {
  CurrencyDisplaySymbol,
  CurrencyType,
  CurrencyUnitStatistics,
  EnteredBy,
  News,
  OrderStatus,
  OrderType,
  PaymentMethodEnum,
  Transaction,
  UnitDisplayNames,
  UnitDisplaySymbol,
  UnitType,
  UserType,
  User_Basic_Info,
  VoteTopicObjectInteface,
  unitTypeToCurrencyMap,
} from './types'
import * as Yup from 'yup'
import { defaultDataConfig } from './constants'

export const USER_URI = process.env.REACT_APP_USER_URI
export const formatUnixTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000) // Convert Unix timestamp to milliseconds
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }
  return date.toLocaleString('en-US', options)
}
export const ONE_EURO_TO_CENTS = 100
export const ONE_AUD_TO_CENTS = 100
export const ONE_PERCENT = 100
export const sortByTransactionsDateAndValue = (
  property: string,
  direction: string,
  data: Transaction[],
) => {
  const sorted = [...data]

  const multiplier = direction === 'asc' ? 1 : -1
  sorted.sort((a, b) => {
    const aValue = getProperty(a, property)
    const bValue = getProperty(b, property)

    if (aValue < bValue) return -1 * multiplier
    if (aValue > bValue) return 1 * multiplier
    return 0
  })
  return sorted
}
const getProperty = (obj: any, path: string) => {
  const parts = path.split('.')
  let value = obj
  for (const part of parts) {
    value = value[part]
  }
  return value
}

export const formatDate = (dateString: string) => {
  const options = {
    year: 'numeric' as const, // Ensure 'year' property is of type 'numeric'
    month: 'short' as const,
    day: 'numeric' as const,
    hour: 'numeric' as const,
    minute: 'numeric' as const,
    hour12: true,
  }

  return new Date(dateString).toLocaleDateString('en-US', options)
}

export const getDate = (dateString: string) => {
  const options = {
    year: 'numeric' as const,
    month: 'short' as const,
    day: 'numeric' as const,
  }

  return new Date(dateString).toLocaleDateString('en-US', options)
}

export const formatFormDate = (dateString: Date | string) => {
  const date = new Date(dateString)

  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Note: Months are zero-based, so we add 1
  const year = date.getFullYear()

  const formattedDate = `${year}-${month}-${day}`

  return formattedDate
}

export const debounce = (func: Function, delay: number) => {
  let timer: NodeJS.Timeout // Adjust the type according to your environment

  return function (this: any, ...args: any[]) {
    // Explicitly specify the type of 'this'
    clearTimeout(timer)
    timer = setTimeout(() => func.apply(this, args), delay)
  }
}

export const validateAdminVotetopicData = (data: VoteTopicObjectInteface) => {
  let validData = true

  Object.keys(data).forEach((k: string) => {
    if (k === 'voteQuestions') {
      data.voteQuestions.forEach((q) => {
        if (q.questionTitle === '') {
          validData = false
        }
        Object.keys(q.answerOptions).forEach((k) => {
          if (q.answerOptions[k as 'A' | 'B' | 'C' | 'D'] === '') {
            validData = false
          }
        })
      })
    } else {
      if (data[k as keyof VoteTopicObjectInteface] === '') {
        validData = false
      }
    }
  })

  console.log('validData:', validData)
  return validData
}
export const validateProjectData = (data: any) => {
  let validData = true

  console.log('validData:', validData)
  return validData
}

// import moment from 'moment-timezone';

// // Assuming startDate and endDate are selected by the user
// const startDateEET = moment(startDate).tz('Europe/Istanbul').format();
// const endDateEET = moment(endDate).tz('Europe/Istanbul').format();

export const getReferenceCodeSchema = () => {
  return Yup.object().shape({
    code: Yup.string().required('Code is required'),
    isPercent: Yup.boolean().required('Is Percent is required'),
    amount: Yup.number()
      .required('Amount is required')
      .min(0, 'Amount must be at least 0')
      .when('isPercent', {
        is: true,
        then: Yup.number().max(100, 'Amount cannot be more than 100'),
      }),
    isActive: Yup.boolean().required('Is Active is required'),
    minPaymentAmountInCents: Yup.number().required('minimum payment required'),
  })
}

export const createFormDataForAddingNews = (news: Partial<News>) => {
  const formData = new FormData()
  const {
    slug,
    metaDescription,
    title,
    metaTitle,
    article,
    img,
    id,
    status,
    newsCategoryId,
  } = news
  console.log('img:', img)
  if (id) {
    formData.append('id', id)
  }
  if (slug) {
    formData.append('slug', slug)
  }
  if (status) {
    formData.append('status', status)
  }
  if (metaDescription) {
    formData.append('metaDescription', metaDescription)
  }
  if (title) {
    formData.append('title', title)
  }
  if (metaTitle) {
    formData.append('metaTitle', metaTitle)
  }
  if (article) {
    formData.append('article', article)
  }
  if (newsCategoryId) {
    formData.append('newsCategoryId', newsCategoryId)
  }

  // @ts-ignore
  if (typeof img !== 'string' && img.length !== 0) {
    formData.append(
      'newsImage',
      // @ts-ignore
      img[0],
    )
  }
  return formData
}

export const getEnteredByName = (enteredBy: EnteredBy): string => {
  const { byUserAdmin, bySuperAdmin } = enteredBy

  // Check byUserAdmin first
  if (byUserAdmin && byUserAdmin.email) {
    const fullName =
      `${byUserAdmin.firstName} ${byUserAdmin.lastName || ''}`.trim()
    return fullName
  }

  // Check bySuperAdmin if byUserAdmin is not available
  if (bySuperAdmin && bySuperAdmin.email) {
    const fullName =
      `${bySuperAdmin.firstName} ${bySuperAdmin.lastName || ''}`.trim()
    return fullName
  }

  return '' // Return an empty string if neither has firstName
}

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const formatNumberWithCommas = (number: number) => {
  return new Intl.NumberFormat('en-US').format(number) // Use 'en-IN' for Indian-style formatting (1,00,000)
}

export const formatNumberWithCommasUptoTwoDecimal = (number: number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number)
}

export const formatNumberWithCommasUptoTwoDecimalWithCeil = (
  number: number,
) => {
  const ceilValue = Math.ceil(number * 100) / 100 // Always round up to 2 decimal places
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(ceilValue)
}

export const formatNumberWithCommasUptoTwoDecimalWithFloor = (
  number: number,
) => {
  const ceilValue = Math.floor(number * 100) / 100 // Always round up to 2 decimal places
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(ceilValue)
}

export const displayPaymentAmount = (transaction: Transaction) => {
  const {
    orderType,
    paymentDetails: { paymentAmount },
  } = transaction
  if (orderType === OrderType.BUY) {
    return formatNumberWithCommasUptoTwoDecimalWithCeil(
      paymentAmount / ONE_AUD_TO_CENTS,
    )
  } else if (orderType === OrderType.SELL) {
    return formatNumberWithCommasUptoTwoDecimalWithFloor(
      paymentAmount / ONE_AUD_TO_CENTS,
    )
  } else if (orderType === OrderType.TRANSFER) {
    return formatNumberWithCommasUptoTwoDecimal(
      paymentAmount / ONE_AUD_TO_CENTS,
    )
  } else if (orderType === OrderType.CONVERT) {
    return formatNumberWithCommasUptoTwoDecimalWithCeil(
      paymentAmount / ONE_AUD_TO_CENTS,
    )
  }
}

export function formatUpToFloor(amount: number) {
  return Math.floor(amount)
}

export function formatToTwoDecimals(amount: number): number {
  return parseFloat(amount.toFixed(2))
}

export const checkLandAvailableUnits = (
  unitType: UnitType,
  isDoubleLand: boolean,
  numberOfUnits: number,
  pricePerUnit: number,
  landStatistics: CurrencyUnitStatistics,
  userPurchaseLimitInCents: number,
  pendingBuyLand: number,
  currency: CurrencyType,
) => {
  // Checking Max Land Unit avaliable to buy

  const {
    totalPurchase,
    totalPendingPurchase,
    totalPendingSell,
    totalProjectLandValueInCents,
    maxDoubleLandUnitsBoughtValueInCents,
    totalDoubleLandUnitsBoughtValueInCents,
  } = landStatistics
  // Available to buy LandUnits

  const availableUnitsToBuy =
    (totalProjectLandValueInCents +
      totalPendingSell * pricePerUnit -
      totalPurchase * pricePerUnit -
      totalPendingPurchase * pricePerUnit) /
    pricePerUnit

  if (availableUnitsToBuy < numberOfUnits) {
    return {
      message: `You can only buy up to ${availableUnitsToBuy < 0 ? 0 : formatUpToFloor(availableUnitsToBuy)} ${UnitDisplayNames[unitType]}`,
    }
  }

  const pendingPurchaseLimit = pendingBuyLand * pricePerUnit
  const availableLimitToBuy = userPurchaseLimitInCents - pendingPurchaseLimit
  const currentPurchasingLandValue = numberOfUnits * pricePerUnit
  if (availableLimitToBuy < currentPurchasingLandValue) {
    const message = `Your current pending purchases is ${formatToTwoDecimals(pendingPurchaseLimit / ONE_AUD_TO_CENTS)} ${UnitDisplaySymbol[unitType]}. You can only buy up to ${formatToTwoDecimals(availableLimitToBuy / ONE_AUD_TO_CENTS)}  ${CurrencyDisplaySymbol[currency]} once your current transactions has been completed.`
    return { message }
  }
  if (isDoubleLand) {
    // Check if totalDoubleLandUnitValue must be less than 2milllion
    const availableDoubleLandUnit =
      (maxDoubleLandUnitsBoughtValueInCents -
        totalDoubleLandUnitsBoughtValueInCents +
        currentPurchasingLandValue) /
      pricePerUnit
    if (availableDoubleLandUnit < numberOfUnits) {
      const message = `Maximum remaining ${UnitDisplayNames[unitType]} in the LU2X offer: ${availableDoubleLandUnit < 0 ? 0 : Math.floor(availableDoubleLandUnit)}`
      return { message }
    }
  }

  return { message: '' }
}

export const getInitialBuyOrSellOrTransferOrderValues = (
  orderType: OrderType,
  userType: UserType,
) => {
  // @ts-ignore
  let initialValues: OrderCreate = {}
  if (orderType === OrderType.BUY) {
    initialValues = {
      paymentDetails: {
        paymentAmount: 0,
        paymentMethodFees: {
          fixedPercentageFees: 0,
          fixedCentsFees: 0,
        },
        paymentMethod: PaymentMethodEnum.Bank,
        // @ts-ignore
        currency: '',
        subTotal: 0,
        transactionFee: 0,
      },
      buy: {
        referenceCode: '',
        discount: {
          isPercent: false,
          amount: 0,
          discountValue: 0,
          minPaymentAmountInCents: 0,
        },
        comments: '',
      },
      orderStatus: OrderStatus.Initiated,
      currencyUnit: {
        numberOfUnits: 0,
        unitType: defaultDataConfig.transaction[orderType].defaultUnitType,
        pricePerUnit: 0,
        buyOrderTransactionFeeInCents: 0,
        isDoubleLand: false,
      },
      orderType: OrderType.BUY,
    }
  } else if (orderType === OrderType.SELL) {
    initialValues = {
      // @ts-ignore
      paymentDetails: {
        paymentAmount: 0,
        currency: unitTypeToCurrencyMap[UnitType.LAND_AUD],
        subTotal: 0,
        transactionFee: 0,
      },
      orderStatus: OrderStatus.Initiated,
      currencyUnit: {
        numberOfUnits: 0,
        unitType: UnitType.LAND_AUD,
        pricePerUnit: 0,
        sellOrderTransactionFeeInUnits: 0,
      },
      orderType: OrderType.SELL,
    }
  } else if (orderType === OrderType.TRANSFER) {
    initialValues = {
      // @ts-ignore
      paymentDetails: {
        paymentAmount: 0,
        // @ts-ignore
        currency: '',
        subTotal: 0,
        transactionFee: 0,
      },
      orderStatus: OrderStatus.Initiated,
      currencyUnit: {
        numberOfUnits: 0,
        unitType: UnitType.LAND_AUD,
        pricePerUnit: 0,
      },
      orderType: OrderType.TRANSFER,
      transfer: {
        recipientEmail: '',
      },
    }
  } else if (orderType === OrderType.CONVERT) {
    initialValues = {
      // @ts-ignore
      paymentDetails: {
        paymentAmount: 0,
        // @ts-ignore
        currency: unitTypeToCurrencyMap[UnitType.LAND_AUD],
        subTotal: 0,
        transactionFee: 0,
      },
      orderStatus: OrderStatus.Initiated,
      currencyUnit: {
        numberOfUnits: 0,
        unitType: UnitType.LAND_AUD,
        pricePerUnit: 0,
        convertOrderTransactionFeeInUnits: 0,
      },
      orderType: OrderType.CONVERT,
      convert: {
        numberOfUnits: 0,
        unitType: UnitType.LAND_USD,
        pricePerUnit: 0,
        exchangeRate: 0,
        // @ts-ignore
        paymentDetails: {
          paymentAmount: 0,
          // @ts-ignore
          currency: unitTypeToCurrencyMap[UnitType.LAND_USD],
          subTotal: 0,
          transactionFee: 0,
        },
      },
    }
  }

  return initialValues
}

export const roundToSixDecimals = (value: number) => {
  return parseFloat(value.toFixed(6))
}
export const DEFAULT_MINIMUM_SUBTOTAL_IN_CENTS = 5000 * 100 //5,000 Fiat in cents

export function formatUpToCeil(amount: number) {
  return Math.ceil(amount)
}
export const emptyAxiosError: AxiosError<{ message: string | null }, any> = {
  // @ts-ignore
  response: {
    data: {
      message: null,
    },
  },
}
