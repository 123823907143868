import React, { useEffect, useState } from 'react'
import Header from '../../component/Sidbar'
import Footer from '../../component/Footer'
import { Link, useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import axios from 'axios'
import moment from 'moment'
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Button,
  ModalFooter,
} from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { toast } from 'react-toastify'
import { getAllLandProjects, getAllNewsCategory } from '../../api'
import { testAxios } from '../../utils/constants'
import { NewsCategoryInterface } from '../../utils/types'
import { getEnteredByName } from '../../utils/utilities'
import { init_revalidate_next_pages } from '../../utils/common'
export default function ViewNewsCategory() {
  const [deleteModal, setdeleteModal] = useState(false)
  const [viewModal, setviewModal] = useState(false)
  const [deleteLoading, setdeleteLoading] = useState(false)
  const toggleDeleteModal = () => {
    setdeleteModal(!deleteModal)
  }
  const toggleViewModal = () => {
    setviewModal(!viewModal)
  }


  const [data, setData] = useState<NewsCategoryInterface[]>([])
  const [activeData, setactiveData] =
    useState<NewsCategoryInterface | null>(null)

  const navigate = useNavigate()

  const DeleteData = async () => {
    try {
      setdeleteLoading(true)
      const url =
        process.env.REACT_APP_SERVER_URI + `/news/news-category/${activeData?._id}`

      console.log('url:', url)
      const response = await testAxios.delete(url)
      setdeleteLoading(false)
      toggleDeleteModal()
      toast.success(response.data.message, { position: 'bottom-center' })
      init_revalidate_next_pages()
      getAllData()
    } catch (e) {
      console.log('e:', e)
    }
  }

  const getAllData = async () => {
    getAllNewsCategory().then((res) => {
      console.log('res:', res)
      //@ts-ignore
      setData([...(res.data as NewsCategoryInterface[])]) // <--
    })
  }
  useEffect(() => {
    getAllData()
  }, [])

  return (
    <>
      {/* <div>{showChildComponent && <EditQuestions data={id} />}</div> */}
      <div>
        

        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">View News Categorys</li>
              <li>
                <QButton
                  onClick={() => navigate('/news/addNewsCategory')}
                  round
                  size="sm"
                  className="ms-3"
                >
                  Add News Category
                </QButton>
              </li>
            </ol>
            <table
              className="table table-bordered"
              
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>News Category Name</th>
                  <th>Slug</th>
                  <th>Entered by</th>
                  
                  

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(
                  (data: NewsCategoryInterface, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <b>{index + 1}.</b>
                        </td>
                        <td>
                          <b>{data.name}</b>
                        </td>
                        <td>
                          <b>{data.slug}</b>
                        </td>
                        <td>{getEnteredByName(data.newsEnteredBy)}</td>




                        <td>
                          {/* <QButton
                            size="sm"
                            onClick={() => {


                              if (data._id) {
                                setactiveData(data)
                                toggleViewModal()
                              }
                            }}
                            round
                            className="me-2"
                          >
                            View
                          </QButton> */}
                          <QButton
                            size="sm"
                            onClick={() => {


                              navigate('/news/editNewsCategory/' + data._id)

                            }}
                            round
                            className="me-2"
                          >
                            Edit
                          </QButton>
                          <QButton
                            size="sm"
                            onClick={() => {
                              if (data._id) {
                                setactiveData(data)
                                toggleDeleteModal()
                              }
                            }}
                            round
                          >
                            Delete
                          </QButton>
                          {/* <EditIcon /> */}
                        </td>


                      </tr>
                    )
                  },
                )}
              </tbody>
            </table>
            <Modal
              isOpen={viewModal}
              toggle={toggleViewModal}
              className="modal-dialog-centered "
              scrollable
              size="lg"
              unmountOnClose={true}
            >
              <ModalHeader>News Category Details</ModalHeader>
              <ModalBody>
                {activeData && (
                  <div className="details-in-modal">
                    <div className="static-text-content-page">


                      <div className="data-block-list">
                        <div className="data-block">
                          <h5>News Category Name:</h5>
                          <p>
                            <b>{activeData.name}.</b>
                          </p>
                        </div>
                        <div className="data-block">
                          <h5>News Category slug:</h5>
                          <p>
                            <b>{activeData.slug}.</b>
                          </p>
                        </div>


                      </div>
                    </div>
                  </div>
                )}
              </ModalBody>

              <ModalFooter>
                <QButton round className="me-3" onClick={toggleViewModal}>
                  Close
                </QButton>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={deleteModal}
              toggle={toggleDeleteModal}
              className="modal-dialog-centered "
              scrollable
              // size="lg"
              unmountOnClose={true}
            >
              <ModalHeader>Confirm.</ModalHeader>
              <ModalBody>Do you want to delete this Category ?</ModalBody>

              <ModalFooter>
                <QButton round className="me-3" onClick={toggleDeleteModal}>
                  Close
                </QButton>
                <QButton round loading={deleteLoading} onClick={DeleteData}>
                  Delete
                </QButton>
              </ModalFooter>
            </Modal>
          </div>
        </div>

        
      </div>
    </>
  )
}
