import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { SuperAdmin } from '../../utils/types'
import { getSuperAdminByNameForView } from '../../api'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { FormGroup } from 'reactstrap'
import { toast } from 'react-toastify'

export default function ViewAllSuperAdmin() {
  const [superAdmins, setSuperAdmins] = useState<SuperAdmin[]>([])

  const [searchType, setSearchType] = useState('name') // Default search type is name
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    getSuperAdminByName()
  }, [])

  const getSuperAdminByName = async () => {
    getSuperAdminByNameForView(searchType, searchTerm)
      .then((res) => {
        setSuperAdmins(res.data.superAdmins)
      })
      .catch((err) => {
        console.log('apis error====', err)
        toast.error(err.response.data.message)
      })
  }

  return (
    <>
      <div>
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">View Super Admin</li>
            </ol>

            <div className="card mb-3">
              {/* <div className="card-header">
                                <i className="fa fa-list-ul me-2"></i> List of All Transactions
                            </div> */}
              <div className="card-body">
                <div className="table-responsive">
                  <FormGroup className="search-by-wrap">
                    <select
                      className="form-control"
                      value={searchType}
                      onChange={(e) => setSearchType(e.target.value)}
                    >
                      <option value="name">Search Super Admin by Name</option>
                      <option value="email">Search Super Admin by Email</option>
                    </select>
                    <SearchInput
                      onClick={getSuperAdminByName}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      // loading={userLoading}
                    />
                  </FormGroup>
                  <table
                    className="table table-bordered text-nowrap text-center"
                    
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {superAdmins?.map((superAdmin, index: number) =>
                        superAdmin.email ? (
                          <tr key={superAdmin.email}>
                            <td>
                              <b>{index + 1}.</b>
                            </td>
                            <td>
                              {superAdmin.firstName + ' ' + superAdmin.lastName}
                            </td>

                            <td>{superAdmin.email}</td>
                          </tr>
                        ) : null,
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
