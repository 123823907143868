import { Spinner, SpinnerProps } from "reactstrap";

export default function LoadingBlock({ ...props }: SpinnerProps) {
    return (
        <div
            className="LoadingBlock"
            style={{
                height: "30vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spinner color="primary" {...props}>
                Loading...
            </Spinner>
        </div>
    );
}
